import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { environment } from '@environments/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { CookieService } from 'ngx-cookie-service';
import { catchError, of, switchMap, throwError } from 'rxjs';
import { DummyService } from '../services';

@Injectable()
export class AuthResolverService implements Resolve<any> {
  isLoggedUser: boolean;
  constructor(
    private dummyService: DummyService,
    private cookieService: CookieService,
    private oidcSecurityService: OidcSecurityService
  ) {}

  resolve(): Promise<any> {
    return this.dummyService
      .getLoggedInUser()
      .then((value: any) => {
        if (
          !value &&
          (this.cookieService.get('isRefreshToken') === '' ||
            this.cookieService.get('isRefreshToken') === 'false')
        ) {
          this.cookieService.set('isRefreshToken', 'true');
          const refreshToken = this.cookieService.get('refreshToken')
            ? this.cookieService.get('refreshToken')
            : this.oidcSecurityService.getRefreshToken();

          return this.dummyService
            .getTokenUsingRefreshToken(refreshToken, environment.clientId)
            .pipe(
              switchMap((response) => {
                this.cookieService.set('isRefreshToken', 'false');
                this.cookieService.set('newToken', response.access_token);
                this.cookieService.set('refreshToken', response.refresh_token);
                this.isLoggedUser = true;
                return of(response.access_token);
              }),

              catchError((error) => {
                this.cookieService.deleteAll();
                this.oidcSecurityService.logoff();
                return throwError('Error');
              })
            )
            .toPromise()
            .then((accessToken) => {
              this.isLoggedUser = true;
              return accessToken;
            });
        } else if (!value) {
          this.cookieService.deleteAll();
          this.oidcSecurityService.logoff();
        }

        this.isLoggedUser = value;
        return value ? true : false;
      })
      .catch((error: any) => {
        if (
          this.cookieService.get('isRefreshToken') === '' ||
          this.cookieService.get('isRefreshToken') === 'false'
        ) {
          this.cookieService.set('isRefreshToken', 'true');
          const refreshToken = this.cookieService.get('refreshToken')
            ? this.cookieService.get('refreshToken')
            : this.oidcSecurityService.getRefreshToken();

          return this.dummyService
            .getTokenUsingRefreshToken(refreshToken, environment.clientId)
            .pipe(
              switchMap((response) => {
                this.cookieService.set('isRefreshToken', 'false');
                this.cookieService.set('newToken', response.access_token);
                this.cookieService.set('refreshToken', response.refresh_token);
                this.isLoggedUser = true;
                return of(response.access_token);
              }),

              catchError((error) => {
                this.cookieService.deleteAll();
                this.oidcSecurityService.logoff();
                return throwError('Error');
              })
            )
            .toPromise()
            .then((accessToken) => {
              this.isLoggedUser = true;
              return accessToken;
            });
        }
      });
  }
}
