import { Guid } from 'guid-typescript';

export class NonTradingLoanLosses {
  returnFormId?: Guid;
  incomeArisingBX170: number;
  nonTrdDeficitsArisingBX795: number;
  nonTrdDeficitsUtilisedPrd: number;
  nonTrdSurrenderGrpRelief: number;
  nonTrdDeficitsBFW: number;
  nonTrdDeficitsBFWBefore: number;
  nonTrdDeficitsBFWAfter: number;
  nonTrdDeficitsBroughtBackAP: number;
  nonTrdDeficitsCFW: number;
  nonTrdDeficitsCFWBefore: number;
  nonTrdDeficitsCFWAfter: number;
  nonTrdDeficitsCarriedAP: number;
  nonTrdProfitBX230: number;
  nonTrdTotalProfitBX263: number;
  nonTrdSurrenderGrpRelief2: number;
  nonTrdIncomeArisingAPLRDCBX170: number;
  nonTrdIncomeArisingAPLRDCBX175: number;
  nonTrdBX170IncLossCBkFromAPBX172: boolean;
}
