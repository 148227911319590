import { Injectable } from '@angular/core';
import { SideListModel, TPRequestItem } from '@app/core/models';
import { TradingPPCalculatorService } from '@app/core/services';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
import {
  CreateTradingPPCalculator,
  CreateTradingPPCalculatorTags,
  GetDataForTradingPPCalculator,
  GetDataForTradingPPCalculatorTags,
  GetTradingProfitsTagsList,
} from './trading-pp-calculator.action';

export class TradingPPCalculatorStateInfo {
  id?: number;
  tagData?: TPRequestItem[];
  tradingPPData?: any;
  tagList?: SideListModel[];
  exported?: boolean;
  totalRecord?: number;
  message: string;
}

@State<TradingPPCalculatorStateInfo>({
  name: 'tradingPPCalculator',
  defaults: {
    id: 0,
    exported: false,
    message: '',
  },
})
@Injectable()
export class TradingPPCalculatorState {
  constructor(private tradingPPCalculatorService: TradingPPCalculatorService) {}

  @Action(CreateTradingPPCalculator)
  createTradingPPCalculator(
    { patchState }: StateContext<TradingPPCalculatorStateInfo>,
    action: CreateTradingPPCalculator
  ) {
    return this.tradingPPCalculatorService
      .createTradingPPCalculator(action.tradingPPCalculationData)
      .pipe(
        tap((res) => {
          patchState({
            id: res.tradingProfitId,
            message: res.tradingProfitMessage,
          });
        })
      );
  }

  @Action(CreateTradingPPCalculatorTags)
  createTradingPPCalculatorTags(
    { patchState }: StateContext<TradingPPCalculatorStateInfo>,
    action: CreateTradingPPCalculatorTags
  ) {
    return this.tradingPPCalculatorService
      .createTradingPPCalculatorTags(action.tradingProfitTagsData)
      .pipe(
        tap((res) => {
          patchState({
            id: res.accountingPeriodId,
            message: res.accountingPeriodMessage,
          });
        })
      );
  }

  @Action(GetDataForTradingPPCalculatorTags)
  getDataForTradingPPCalculatorTags(
    { patchState }: StateContext<TradingPPCalculatorStateInfo>,
    action: GetDataForTradingPPCalculatorTags
  ) {
    return this.tradingPPCalculatorService
      .getDataForTradingPPCalculatorTags(action.returnFormId, action.type)
      .pipe(
        tap((res) => {
          patchState({
            tagData: res,
          });
        })
      );
  }

  @Action(GetDataForTradingPPCalculator)
  getDataForTradingPPCalculator(
    { patchState }: StateContext<TradingPPCalculatorStateInfo>,
    action: GetDataForTradingPPCalculator
  ) {
    return this.tradingPPCalculatorService
      .getDataForTradingPPCalculator(action.returnFormId)
      .pipe(
        tap((res) => {
          patchState({
            tradingPPData: res,
          });
        })
      );
  }

  @Action(GetTradingProfitsTagsList)
  getTradingProfitsTagsList(
    { patchState }: StateContext<TradingPPCalculatorStateInfo>,
    action: GetTradingProfitsTagsList
  ) {
    return this.tradingPPCalculatorService
      .getTradingProfitsTagsList(action.caltype)
      .pipe(
        tap((res) => {
          patchState({
            tagList: res,
          });
        })
      );
  }
}
