import { Guid } from 'guid-typescript';

export class CreateUser {
  static readonly type = '[USER] Create User';
  constructor(public user: any) {}
}
export class GetDataByUserId {
  static readonly type = '[USER] Get User Data By User Id';
  constructor(public userId: Guid) {}
}
export class DeleteUsers {
  static readonly type = '[USER] Delete Selected Users';

  constructor(public ids: Array<Guid>) {}
}
export class SendInvite {
  static readonly type = '[USER] Send Invite';
  constructor(public userData: any) {}
}
export class GetUserPermissionsList {
  static readonly type = '[USER] Get User Permissions List';
}
