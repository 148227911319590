import { Guid } from 'guid-typescript';

export class TradingPPCalculationModel {
  turnover: number;
  accountProfit: number;
  addBack: number;
  adjustments: number;
  diallowableExpenditure: number;
  rndExpenditure: number;
  incomeNotCredited: number;
  balancingCharge: number;
  expenditureNotInAccounts: number;
  rndEnhancedExpenditure: number;
  creativeEnhancedExpenditure: number;
  capitalAllowance: number;
  pLbeforetaxcredit: number;
  lSTaxRDTaxCredit: number;
  lSCreativeTaxCredit: number;
  pLaftertaxcredit: number;
  tradingLossesSummary: number;
  returnFormId: Guid;
  isAutoSave:boolean;
}
