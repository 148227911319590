import { Guid } from 'guid-typescript';

export class TradingProfitLosses {
  returnFormId?: Guid;
  incomeArisingBX155?: number;
  lossutilizedBX275?: number;
  lossArisingBX780?: number;
  lossSurrenderedGroupRelief?: number;
  lossSurrenderedForRnD?: number;
  lossBroughtForward?: number;
  lossBFWBefore?: number;
  lossBFWAfter?: number;
  lossBroughtBackFromFutureAP?: number;
  lossCarriedForward?: number;
  lossCFBefore?: number;
  lossCFWAfter?: number;
  lossCarriedBackToPreviousAP?: number;
  lossBFWSet454BX160?: number;
  investIncomeBX225?: number;
  lossCFWClaimedBX285?: number;
  surrenderGroupRelief?: number;
  thisAPLaterAPBX275?: number;
  lossBBackFromLaterAP?: number;
  lossesRemAvailable?: number;
  lossSurrenderedForLandRemTax?: number;
  lossUtilisedBX275IncAPBX280?: boolean;
  isAutoSave: boolean;
}
