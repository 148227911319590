<div class="d-flex gap-15">
  <div class="w-150">
    <ng-select
      #select
      [(ngModel)]="selectedOption"
      (change)="onDropdownChange($event)"
      panelClass="myPanelClass view-list"
      [disableOptionCentering]="true"
    >
      <ng-option *ngFor="let year of yearsListPair" [value]="year.key">
        {{ year.value }}
      </ng-option>
    </ng-select>
  </div>
  <form *ngIf="selectedOption === 7" [formGroup]="rangeFormGroup">
    <mat-form-field (click)="picker.open()" class="w-250">
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate matInput readonly #start formControlName="start" />
        <input
          matEndDate
          matInput
          readonly
          #end
          formControlName="end"
          (dateChange)="dateRangeChange(start.value, end.value)"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
      <div class="mar-daterange-arrow">
        <button (click)="lessWeek($event)" type="button" disableRipple>
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <button (click)="addWeek($event)" type="button" disableRipple>
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
      </div>
    </mat-form-field>
  </form>
</div>
