import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
})
export class ActivityComponent implements OnInit {
  selectedTab = 0;

  constructor() {
    // do nothing.
  }

  ngOnInit(): void {
    // do nothing.
  }

  onTabClick(event: any): void {
    this.selectedTab = event;
  }
}
