import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/core/guards/auth.guard';
import { AuthResolverService } from '@app/core/guards/auth.resolver';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { ActivityComponent } from './activity/activity.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GeneralDashboardComponent } from './general-dashboard/general-dashboard.component';
import { DocumentTemplatesComponent } from './general-settings/document-templates/document-templates.component';
import { EmailTemplatesComponent } from './general-settings/email-templates/email-templates.component';
import { TaxDocumentComponent } from './general-settings/tax-document/tax-document.component';
import { HomeComponent } from './home.component';
import { TicketComponent } from './ticket/ticket.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    resolve: {
      result: AuthResolverService,
    },
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        component: DashboardComponent,
        resolve: {
          result: AuthResolverService,
        },
      },
      {
        path: 'general-dashboard',
        component: GeneralDashboardComponent,
        resolve: {
          result: AuthResolverService,
        },
      },
      {
        path: 'submissions',
        loadChildren: () =>
          import('./client/client.module').then((m) => m.ClientModule),
        resolve: {
          result: AuthResolverService,
        },
      },
      {
        path: 'task',
        loadChildren: () =>
          import('./task/task.module').then((m) => m.TaskModule),
        //data: { preload: true },
        resolve: {
          result: AuthResolverService,
        },
      },
      {
        path: 'manage',
        loadChildren: () =>
          import('./settings/settings.module').then((m) => m.SettingsModule),
        resolve: {
          result: AuthResolverService,
        },
      },
      {
        path: 'logs',
        loadChildren: () =>
          import('./activitylogs/logs.module').then((m) => m.LogsModule),
        resolve: {
          result: AuthResolverService,
        },
      },
      {
        path: 'email-templates',
        component: EmailTemplatesComponent,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'document-templates',
        component: DocumentTemplatesComponent,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'tax-document',
        component: TaxDocumentComponent,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'ticket',
        component: TicketComponent,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'activity',
        component: ActivityComponent,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'access-denied',
        component: AccessDeniedComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
