import { DetailRoutingType } from '@app/core/enums';
import {
  PropertyBusinessCalculationModel,
  TradingProfitTagsModel,
} from '@app/core/models';
import { Guid } from 'guid-typescript';

export class CreatePropertyBCalculator {
  static readonly type =
    '[PropertyBusinessCalculator] Create PropertyB Calculator';
  constructor(
    public propertyBCalculationData: PropertyBusinessCalculationModel
  ) {}
}

// export class CreateTradingPPCalculatorTags {
//   static readonly type =
//     '[TradingPPCalculator] Create Trading PP Calculator Tag';
//   constructor(public tradingProfitTagsData: TradingProfitTagsModel) {}
// }

// export class GetDataForTradingPPCalculatorTags {
//   static readonly type =
//     '[TradingPPCalculator] Get Data For Trading PP Calculator Tags';
//   constructor(public returnFormId: Guid, public type: DetailRoutingType) {}
// }

export class GetDataForPropertBCalculator {
  static readonly type =
    '[PropertyBusinessCalculator] Get Data For Property Business Calculator';
  constructor(public returnFormId: Guid) {}
}

// export class GetTradingProfitsTagsList {
//   static readonly type = '[TradingPPCalculator] GetTradingProfitsTagsList';
//   constructor() {
//     // do nothing.
//   }
//}
