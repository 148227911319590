<div class="sidenav-table sidenav-table-input wp-100">
  <div class="content-body">
    <form>
      <div class="form-field">
        <mat-checkbox>Enable/Disable Report Logo</mat-checkbox>
      </div>
    </form>
  </div>
</div>
<div class="action-wrapper button-action-wrapper wp-100">
  <div class="content-body">
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
    >
      Save
    </button>
  </div>
</div>
