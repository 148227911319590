import { ExportFormat, FilteredStatus } from '@app/core/enums';

import { Guid } from 'guid-typescript';

export class QueryParams {
  pageNumber?: number;
  pageSize?: number;
  filter?: number;
  sortBy?: string;
  sortByNewlyAdded?: string;
  maxPageSize?: number;
  sortOrder?: boolean;
  search?: string;
  startDate?: string;
  endDate?: string;
  isRecurring?: boolean;
  moduleId?: number;
}

export class ExportParams extends QueryParams {
  format?: ExportFormat;
  fileName?: string;
  isPrint? = false;
  ids?: Array<Guid>;
}

export class MainListParameters {
  pageNumber = 1;
  pageSize = 20;
  filterId?: number;
  statusType?: number;
  payStatus?: number;
  dueDays?: number;
  sortBy? = '';
  clientId?: Guid;
  sortOrder? = true;
  search? = '';
  ids?: Array<Guid>;
  startDate?: string;
  endDate?: string;
  format?: ExportFormat;
  isPrint?: boolean;
  isRowHighlighted?: false;
  returnFormId?: number;
  userId?: Guid;
  filter? = FilteredStatus['All Status'];
}

export class CompanyParameters extends QueryParams {
  companyName: string = '';
}
