import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  CommonNotificationText,
  Modules,
  NotificationDetails,
  NotificationHeader,
  RoutingPath,
} from '@app/core/enums';

@Component({
  selector: 'app-add-close-popup',
  templateUrl: './add-close-popup.component.html',
  styleUrls: ['./add-close-popup.component.scss'],
})
export class AddClosePopupComponent {
  headerText: string = CommonNotificationText.terminateProcessHeaderText;
  bodyText: string = CommonNotificationText.terminateProcessBodyText;
  constructor(
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddClosePopupComponent>
  ) {
    if (
      this.data !== null &&
      this.data !== undefined &&
      this.data.moduleId === Modules.Import
    ) {
      this.headerText = NotificationHeader.importHeaderText;
      this.bodyText = NotificationDetails.importBodyText;
    }

    if (
      this.data !== null &&
      this.data !== undefined &&
      this.data.moduleId === Modules.Accounts
    ) {
      this.headerText = NotificationHeader.importHeaderText;
      this.bodyText = NotificationDetails.reportBodyText;
    }

  }

  onYesClick(): void {
    if (this.data.isToggle || this.data.moduleId === Modules.Import) {
      this.dialogRef.close(true);
    } else {
      if (this.data.isAdd) {
        history.back();
      } else {
        switch (this.data.moduleId) {
          case Modules.Users:
            this.router.navigate([RoutingPath.User]);
            break;
          case Modules.Contact:
            this.router.navigate([RoutingPath.AddManageClient]);
            break;
          case Modules.Clients:
            this.router.navigate([RoutingPath.Client]);
            break;
          case Modules.CT600Return:
            this.router.navigate([RoutingPath.Ct600return]);
            break;
        }
      }
    }
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
}
