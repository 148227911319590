import { GetImportReturns, SaveImportBfwdData } from './importbfwd.action';
import { Action, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { ImportBfwdService } from '@app/core/services';

export class ImportBfwdStateInfo {
  message: string;
  returnList?: any;
}

@State<ImportBfwdStateInfo>({
  name: 'importBfwd',
  defaults: {
    message: '',
  },
})
@Injectable()
export class ImportBfwdState {
  constructor(private importbfwdservice: ImportBfwdService) {}

  @Action(GetImportReturns)
  getImportReturnList(
    { patchState }: StateContext<ImportBfwdStateInfo>,
    action: GetImportReturns
  ) {
    return this.importbfwdservice
      .getImportReturnList(action.clientId, action.periodFrom)
      .pipe(
        tap((res) => {
          patchState({
            returnList: res,
          });
        })
      );
  }

  @Action(SaveImportBfwdData)
  saveImportBfwdData(
    { patchState }: StateContext<ImportBfwdStateInfo>,
    action: SaveImportBfwdData
  ) {
    return this.importbfwdservice
      .saveImportBfwdData(action.importBfwdModel)
      .pipe(
        tap((res) => {
          patchState({
            message: res,
          });
        })
      );
  }
}
