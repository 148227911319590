import { FileImportRequestModel } from '@app/core/models';
import { Guid } from 'guid-typescript';

export class GetImportTemplate {
  static readonly type = '[IMPORT] Get Import Template By Module ID';

  constructor(public moduleId: number) {}
}

export class ImportModules {
  static readonly type = '[IMPORT] Import Modules';

  constructor(
    public fileImportRequestModel: FileImportRequestModel,
    public moduleId: number
  ) {}
}

export class SaveImport {
  static readonly type = '[IMPORT] Save Import';

  constructor(public param: any, public moduleId: number) {}
}

export class GetImportHistory {
  static readonly type = '[IMPORT] Get Import History';
}

export class DownloadImportedFile {
  static readonly type = '[IMPORT] Download Imported File';

  constructor(public param: any) {}
}

export class GetCapiumImportSetting {
  static readonly type = '[IMPORT] Get Capium Import Setting';

  constructor(public moduleId: number) {}
}

export class SaveCapiumImportSetting {
  static readonly type = '[IMPORT] Save Capium Import Setting';

  constructor(public param: any) {}
}
