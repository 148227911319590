import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ChargeableDisposal,
  ChargeableDisposalDataList,
  ChargeableGainsLosses,
  ChargeableGainsSummary,
  ManagementExpensesLosses,
  NonTradingGainsLosses,
  NonTradingLoanLosses,
  OverseasIncomeLosses,
  PropertyIncomeCalculatorLosses,
  PropertyIncomeLosses,
  TradingProfitLosses,
} from '@app/core/models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LossesNTRLService {
  constructor(private http: HttpClient, public datePipe: DatePipe) {}

  getTradingProfitLossesData(
    returnFormId: Guid
  ): Observable<TradingProfitLosses> {
    return this.http.get<TradingProfitLosses>(
      `${environment.apiVersionUrl}LossesNTLR/getTradingProfitLossList/${returnFormId}`
    );
  }

  saveTradingProfitLosses(
    tradingProfitLossesData: TradingProfitLosses
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}LossesNTLR/saveTradingProfitLoss`,
      JSON.stringify(tradingProfitLossesData),
      headers
    );
  }

  getNonTradingLoanLossesData(
    returnFormId: Guid
  ): Observable<NonTradingLoanLosses> {
    return this.http.get<NonTradingLoanLosses>(
      `${environment.apiVersionUrl}LossesNTLR/getNonTradingLoanLossesList/${returnFormId}`
    );
  }

  saveNonTradingLoanLosses(
    nonTradingLoanLossesData: NonTradingLoanLosses
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}LossesNTLR/SaveNonTradingLoanLosses`,
      JSON.stringify(nonTradingLoanLossesData),
      headers
    );
  }

  getPropertyIncomeLosses(
    returnFormId: Guid
  ): Observable<PropertyIncomeLosses> {
    return this.http.get<PropertyIncomeLosses>(
      `${environment.apiVersionUrl}LossesNTLR/getPropertyIncomeLossList/${returnFormId}`
    );
  }

  savePropertyIncomeLosses(
    propertyIncomeLossesData: PropertyIncomeLosses
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}LossesNTLR/savePropertyIncomeLoss`,
      JSON.stringify(propertyIncomeLossesData),
      headers
    );
  }

  getPropertyIncomeCalculatorLosses(
    returnFormId: Guid
  ): Observable<PropertyIncomeCalculatorLosses> {
    return this.http.get<PropertyIncomeCalculatorLosses>(
      `${environment.apiVersionUrl}LossesNTLR/getPropertyIncomeCalculatorList/${returnFormId}`
    );
  }

  savePropertyIncomeCalculatorLosses(
    propertyIncomeCalculatorLossesData: PropertyIncomeCalculatorLosses
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}LossesNTLR/savePropertyIncomeCalculator`,
      JSON.stringify(propertyIncomeCalculatorLossesData),
      headers
    );
  }

  getOverseasIncomeLosses(
    returnFormId: Guid
  ): Observable<OverseasIncomeLosses> {
    return this.http.get<OverseasIncomeLosses>(
      `${environment.apiVersionUrl}LossesNTLR/getOverseasIncomeLossList/${returnFormId}`
    );
  }

  saveOverseasIncomeLosses(
    overseasIncomeLossesData: OverseasIncomeLosses
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}LossesNTLR/saveOverseasIncomeLoss`,
      JSON.stringify(overseasIncomeLossesData),
      headers
    );
  }

  getNonTradingGainsLosses(
    returnFormId: Guid
  ): Observable<NonTradingGainsLosses> {
    return this.http.get<NonTradingGainsLosses>(
      `${environment.apiVersionUrl}LossesNTLR/getNonTradingGainsLossesList/${returnFormId}`
    );
  }

  saveNonTradingGainsLosses(
    nonTradingGainsLossesData: NonTradingGainsLosses
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}LossesNTLR/saveNonTradingGainsLosses`,
      JSON.stringify(nonTradingGainsLossesData),
      headers
    );
  }

  getCharageableGainsLosses(
    returnFormId: Guid
  ): Observable<ChargeableGainsLosses> {
    return this.http.get<ChargeableGainsLosses>(
      `${environment.apiVersionUrl}LossesNTLR/getChargeableGainsLossesList/${returnFormId}`
    );
  }

  saveCharageableGainsLosses(
    chargeableGainsLossesData: ChargeableGainsLosses
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}LossesNTLR/saveChargeableGainsLosses`,
      JSON.stringify(chargeableGainsLossesData),
      headers
    );
  }

  getChargeableGainsSummary(
    returnFormId: Guid
  ): Observable<ChargeableGainsSummary> {
    return this.http.get<ChargeableGainsSummary>(
      `${environment.apiVersionUrl}LossesNTLR/getChargeableGainsSummaryList/${returnFormId}`
    );
  }

  saveChargeableGainsSummary(
    chargeableGainsSummaryData: ChargeableGainsSummary
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}LossesNTLR/saveChargeableGainsSummary`,
      JSON.stringify(chargeableGainsSummaryData),
      headers
    );
  }

  getChargeableDisposal(
    disposalDetailId: Guid,
    returnFormId: Guid
  ): Observable<ChargeableDisposalDataList> {
    return this.http.get<ChargeableDisposalDataList>(
      `${environment.apiVersionUrl}LossesNTLR/getChargeableDisposalData?id=${disposalDetailId}&returnFormId=${returnFormId}`
    );
  }

  deleteChargeableDisposal(
    disposalDetailId: Guid,
    returnFormId: Guid
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}LossesNTLR/deleteChargeDisposal?universalId=${disposalDetailId}&returnFormId=${returnFormId}`
    );
  }

  saveChargeableDisposal(
    chargeableDisposalData: ChargeableDisposal
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}LossesNTLR/saveChargeableDisposal`,
      JSON.stringify(chargeableDisposalData),
      headers
    );
  }

  getManagementExpensesLosses(
    returnFormId: Guid
  ): Observable<ManagementExpensesLosses> {
    return this.http.get<ManagementExpensesLosses>(
      `${environment.apiVersionUrl}LossesNTLR/getManagementExpensesList/${returnFormId}`
    );
  }

  saveManagementExpensesLosses(
    managementExpensesLossesData: ManagementExpensesLosses
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}LossesNTLR/saveManagementExpenses`,
      JSON.stringify(managementExpensesLossesData),
      headers
    );
  }
}
