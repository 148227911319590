import { MenuModel } from '@app/core/models';

export class GetMenus {
  static readonly type = '[MENU] Get Menus';
}

export class SetSubMenuData {
  static readonly type = '[MENU] Set Sub Menu Data';

  constructor(public subMenu: MenuModel[], public selectedMenu?: MenuModel) {}
}

export class GetProfileInfo {
  static readonly type = '[MENU] Get Profile Info';
}
