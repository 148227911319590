export enum NotificationHeader {
  deleteConfirmation = 'Confirm delete',
  archiveConfirmation = 'Confirm archive',
  restoreConfirmation = 'Confirm restore',
  success = 'Success!',
  error = 'Error!',
  importHeaderText = 'Confirmation',
}

export enum NotificationDetails {
  archiveDetailText = 'Are you sure you want to archive the data? ',
  restoreDetailText = 'Are you sure you want to restore the data? ',
  deleteAllDetailText = 'Please confirm you want to delete these things. Deleting cannot be undone.',
  questionMark = ' </strong> ?',
  archiveAllDetailText = 'Please confirm you want to archive these things.',
  restoreAllDetailText = 'Please confirm you want to restore these things.',
  deleteDetailText = 'Are you sure you want to delete <strong> ',
  importBodyText = 'Sure, you want to ignore and continue.',
  reportBodyText = 'Sure, you want to delete accounts report.',
}

export enum NotificationTextMessage {
  autoPeriodGenerationMessage = 'Auto Period Generated Sucessfully',
  lockPeriodMessage = 'Autounting Period Locked Sucessfully',
  unlockPeriodMessage = 'Autounting Period Unlocked Sucessfully',
  ceasedMessage = 'Autounting Period Ceased Sucessfully',
  continueMessage = 'Autounting Period continue Sucessfully',
  markComparativeMessage = 'Autounting Period Mark Comparative Sucessfully',
  undoComparativeMessage = 'Autounting Period undo Comparative Sucessfully',
  qtyErrorMessage = 'Quantity should be greater than 0',
  amtErrorMessage = 'Amount should be greater than 0',
  successMessage = 'The operation completed Successfully!',
  errorMessage = 'There might be some error occured!',
  bankAccountAlreadyExits = 'Bank account already exits!',
  recordCopySuccessfully = 'Record copied successfully',
  recordArchivedSuccessfully = 'Record archived successfully',
  recordRestoredSuccessfully = 'Record restored successfully',
  recordDeletedSuccessfully = 'Record deleted successfully',
  noRecordFoundText = 'No record found.',
  dateRangeTo = 'To date should be greater than 1 year from period From',
  dateRangeAccountsDue = 'Accounts Due Date should be greater than 9 months from To Date',
  dateRangeCT600DueDate = 'CT600 Due Date should be greater than 1 year from To date',
  uploadfileError = 'Please upload valid file',
  htmlfiletypeError = 'Please upload .html files only',
  pdffiletypeError = 'Please upload .pdf files only',
  nofileError = 'Please upload file',
  supplementaryFormsError = 'oops! few fields have invalid data please correct!.',
  FormsError = 'oops! few fields have invalid data please correct!.',
  emailMsg = 'Email sent successfully',
  clientAssignError = 'Please select atleast one client!',
  clientAssignSuccess = 'Client assigned Successfully!',
  L35fieldError = 'Box L35 must be less than or equal to Box 515',
  InvalidDate = 'Please enter valid date',
  SuperDeductions = 'Super deduction allowance claimed value should be less then 130% of Additions value',
  autoPeriodGenerationErrorMessage = 'You have not created any accounting period yet.So please create atleast one accounting period for auto period generation.',
  fileSize2mb = 'File should be less than 2 MB',
  fileJpgPng = 'File should be .png or .jpg extension',
  validationMessage = 'Please fill all the mandatory fields.',
  clientExist = 'Client is already exist.',
  alreadyArchive = 'Selected record has already archived record',
  alreadyRestore = 'Selected record has already active record',
  selectOneOrMoreRecordToDelete = 'Select one or more records to delete',
  restrictClientActionOnRunningTimer = 'There is a timer running on this client. To proceed with this action, please stop the timer running on it.',
  fileSize5mb = 'File should be less than 5 MB',
  permissionChangeAndNotSave = 'Please save your permission changes to edit user permissions.',
  successList = 'No records are updated',
  emailSentMessage = 'Your request sent successfully',
  userExist = 'User is already exist.',
  moveToSuccessList = 'Record moved to success list',
  fileUploaded = 'File uploaded successfully',
  selectModule = 'Select module to download template',
  filenameWithSpecialCharacter = 'File name should not contain special character',
  fileSizeExceeds = 'File should be less than 10 MB',
  noDataFoundDateRange = 'No data found for this date range',
  fileFormateNotSelected = 'Please select file format',
  selectModuletoExportData = 'Select one or more module to export',
}
export enum CommonNotificationText {
  NoRecordsFound = 'No records found',
  NoRecordsFoundForFilter = 'No records found for the given search/filter values',
  Active = 'Active',
  Archived = 'Archived',
  terminateProcessHeaderText = 'Terminate Process',
  terminateProcessBodyText = 'Sure, you want to terminate process.',
  ViewOnlyAccessMessage = 'You have view only access for manage section. Please contact your manager for edit access.',
  NoExportAccesssMessage = 'You do not have access for export this module.',
}
