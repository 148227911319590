<div
  class="d-flex align-items-center grid-actions"
  [ngClass]="selectedRowIndex === 4 ? 'show-button' : ''"
>
  <ng-container *ngFor="let item of actions; let permissionsIndex = index">
    <button
      *ngIf="permissionsIndex < 4"
      (click)="actionClick(item.actionType)"
      mat-button
      disableRipple
      title="{{ item.name }}"
      [disabled]="isViewPermission && item.actionType !== actionType.Edit"
    >
      <mat-icon fontSet="material-icons-outlined">{{ item.icon }}</mat-icon>
    </button>
    <button
      mat-button
      (click)="$event.stopPropagation()"
      (menuClosed)="allowCloseOnClickOut()"
      (click)="onButtonClick(permissionsIndex)"
      [matMenuTriggerFor]="menu"
      disableRipple
    >
      <mat-icon *ngIf="permissionsIndex === 4">more_vert</mat-icon>
    </button>
  </ng-container>
  <mat-menu #menu="matMenu" xPosition="before" class="more-button-menu">
    <ng-container *ngFor="let item of actions; let permissionsIndex = index">
      <div *ngIf="permissionsIndex > 2">
        <button
          mat-menu-item
          disableRipple
          title="{{ item.name }}"
          (click)="actionClick(item.actionType)"
        >
          <mat-icon fontSet="material-icons-outlined">{{ item.icon }}</mat-icon>
          <span>{{ item.name }} </span>
        </button>
      </div>
    </ng-container>
  </mat-menu>
</div>
