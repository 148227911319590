import { ImportBfwdModel } from '@app/core/models';
import { Guid } from 'guid-typescript';

export class GetImportReturns {
  static readonly type = '[IMPORTLIST] Import Return List';
  constructor(public clientId: Guid, public periodFrom: string) {}
}

export class SaveImportBfwdData {
  static readonly type = '[IMPORTDATA] Save Import BfwData';
  constructor(public importBfwdModel: ImportBfwdModel) {}
}
