import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { TicketService } from '@app/core/services';
import { GetAllSupportTickets, SaveSupportTicket } from './ticket.action';

export class TicketStateInfo {
  supportTicketList: any;
  isSuccess?: boolean;
  id?: number;
  response?: any;
}

@State<TicketStateInfo>({
  name: 'ticket',
  defaults: {
    supportTicketList: [],
  },
})
@Injectable()
export class TicketState {
  constructor(private ticketService: TicketService) {}

  @Selector()
  static getAllSupportTickets(state: TicketStateInfo): any {
    return state.supportTicketList ?? [];
  }

  @Action(GetAllSupportTickets, { cancelUncompleted: true })
  GetAllSupportTickets(
    { patchState }: StateContext<TicketStateInfo>,
    action: GetAllSupportTickets
  ) {
    return this.ticketService
      .getAllSupportTickets(action.externalUserGUID)
      .pipe(
        tap((res) => {
          patchState({
            supportTicketList: res.valueResult.aaData,
          });
        })
      );
  }

  @Action(SaveSupportTicket)
  saveSupportTicket(
    { patchState }: StateContext<TicketStateInfo>,
    action: SaveSupportTicket
  ) {
    return this.ticketService
      .saveSupportTicket(action.letsConnectRequestModel)
      .pipe(
        tap((res) => {
          patchState({
            isSuccess: res.isSuccess,
            id: res.valueResult.id,
            response: res,
          });
        })
      );
  }
}
