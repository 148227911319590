import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalComponent, MenuModel, ProfileInfoModel } from '@app/core/models';
import { CommonService, HighlightRow } from '@app/core/services';
import {
  GetLastUsedClient,
  GetMenus,
  GetProfileInfo,
  MenuState,
  RetrieveSubscription,
  UpdateThemeColor,
} from '@app/core/store';

import { environment } from '@environments/environment';
import { Select, Store } from '@ngxs/store';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Guid } from 'guid-typescript';
import { CookieService } from 'ngx-cookie-service';
import { Observable, tap } from 'rxjs';
import * as theme from 'src/assets/json/themes.json';

declare const tinycolor: any;
export interface Color {
  name: string;
  hex: string;
  darkContrast: boolean;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isSearchboxShow = false;
  scrollDirection = '';
  userName: string = '';
  userPhoto: string;
  userId?: Guid;
  userEmail?: string;
  menuData: Array<MenuModel>;
  environment = environment;
  profileInfo: ProfileInfoModel;
  isOwnerUser: boolean = true;
  // Multicolor theme
  colorSelected: any;
  singletheme: any;
  themes = JSON.parse(JSON.stringify(theme));
  colors: string[] = [
    '#6d41a1',
    '#6661b8',
    '#4461d7',
    '#a0144f',
    '#045b62',
    '#14539a',
    '#a76f05',
    '#000000',
  ];

  primaryColor: string;
  primaryColorPalette: Color[] = [];
  commonService: CommonService;
  addMenuList: Array<MenuModel>;
  searchText: string;
  helpDeskUrl = environment.helpDeskUrl;

  @Select(MenuState.getMenu)
  menuList$: Observable<Array<MenuModel>>;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;
  subscriptionModuleList: any = [];
  appUrl = '';
  myAdminUrl: string;
  profileUrl: string;
  subscriptionPortalAppUrl = '';
  isSubscriptionPortalEnabled: boolean = false;
  subscriptionData: any;
  constructor(
    private router: Router,
    private globalComponent: GlobalComponent,
    private store: Store,
    public oidcSecurityService: OidcSecurityService,
    private cookieService: CookieService,
    private highlightRow: HighlightRow,
    private injector: Injector
  ) {
    this.commonService = this.injector.get<CommonService>(CommonService);
    this.onSelectionChange(this.globalComponent.themecolor(), false);
  }

  ngOnInit(): void {
    this.bindMenu();
    if(!this.cookieService.get('clientId')){
      this.getLastUsedClient();
    }
    this.getUserProfileInfo();
    this.retrieveSubscription();
  }

  getLastUsedClient(): void {
    this.store.dispatch(new GetLastUsedClient()).subscribe((res) => {
      if (res.common?.lastUsedClientDetails) {
        this.commonService.setClientId(
          res.common.lastUsedClientDetails?.id,
          res.common.lastUsedClientDetails?.name
        );
      }
    });
  }

  goToProfile(): void {
    window.location.href = 'https://identity.dev.capium.co.uk/Manage';
  }

  onSearchClick(): void {
    this.isSearchboxShow = true;
    this.getUserProfileInfo();
  }

  signOutUser(): void {
    this.cookieService.deleteAll();
    this.oidcSecurityService.logoff();
  }

  getUserProfileInfo(): void {
    this.store
      .dispatch(new GetProfileInfo())
      .pipe(
        tap((res) => {
          this.userId = res.menu.profileInfo?.id;
          this.userName = res.menu.profileInfo?.name;
          this.userEmail = res.menu.profileInfo?.email;
          this.userPhoto = res.menu.profileInfo?.imagePath;
        })
      )
      .subscribe();
  }

  onSearchModule(event: any): void {
    const selectedArray: MenuModel[] = [];

    this.menuData.forEach((item) => {
      if (item.url.includes(event.currentTarget.value)) {
        selectedArray.push(item);
      }
    });

    if (event.currentTarget.value) {
      this.menuData = selectedArray;
    }
  }

  isActive(val: string): string {
    const currentUrl = this.router.url.split('/')[1];
    const newValue = val?.split('/')[1];
    if (currentUrl === newValue) {
      return 'active';
    }
    return '';
  }

  onSearchModuleSelection(event: any): void {
    this.searchText = '';
    this.bindMenu();
    this.router.navigate([`${event.option.value}`]);
  }

  bindMenu(): void {
    this.store.dispatch(new GetMenus()).subscribe((data) => {
      this.menuData = data.menu.menus;
    });
  }

  onCloseClick(): void {
    this.isSearchboxShow = false;
  }

  onMenuClick(menu: any): void {}

  onSelectionChange(event: any, isThemeColor: boolean) {
    this.colorSelected = event;
    this.primaryColor = event;
    this.computeColors(this.primaryColor);

    if (isThemeColor) {
      this.store.dispatch(new UpdateThemeColor(this.primaryColor)).subscribe();

      this.highlightRow.defaultColor.next(this.primaryColor);
    }
  }

  computeColors(hex: string): void {
    for (let index = 0; index < Object.keys(this.themes).length - 2; index++) {
      if (this.themes[index].id === hex) {
        const selectedThemeArray = this.themes[index];

        this.primaryColorPalette = [
          this.getColorObject(selectedThemeArray?.id, '500'),
          this.getColorObject(selectedThemeArray?.color50, '50'),
          this.getColorObject(selectedThemeArray?.color100, '100'),
          this.getColorObject(selectedThemeArray?.color200, '200'),
          this.getColorObject(selectedThemeArray?.color300, '300'),
        ];
      }
    }

    for (const color of this.primaryColorPalette) {
      const key = `--theme-primary-${color.name}`;
      const value = color.hex;
      document.documentElement.style.setProperty(key, value);
    }
  }

  getColorObject(value, name): Color {
    const c = tinycolor(value);
    return {
      name: name,
      hex: c.toHexString(),
      darkContrast: c.isLight(),
    };
  }

  addActive(val1: string, val2: string): string {
    if (
      this.router.url.indexOf(val1) > -1 ||
      this.router.url.indexOf(val2) > -1
    ) {
      return 'active';
    }
    return '';
  }

  redirectToHome(): void {
    window.location.replace(environment.stsServerUrl);
  }

  myAdminClick() {
    window.location.replace(this.myAdminUrl);
  }
  produdctSubscriptionClick(): void {
    if (this.isSubscriptionPortalEnabled)
      window.location.replace(this.subscriptionPortalAppUrl);
  }

  redirectToModule(subscriptionModule, event: Event): void {
    if (subscriptionModule.isDisabled) {
      this.appUrl = '';
      event.preventDefault();
    } else {
      this.appUrl = subscriptionModule.appUrl;
    }
  }
  retrieveSubscription(): void {
    this.store.dispatch(new RetrieveSubscription()).subscribe((res) => {
      if (res.subscription.retrieveSubscriptionList.isSuccess) {
        this.subscriptionData = res.subscription.retrieveSubscriptionList.data;
        this.subscriptionModuleList =
          res.subscription.retrieveSubscriptionList.data.listOfAddons ?? [];
        this.profileUrl =
          res.subscription.retrieveSubscriptionList.data.picture;
        this.isSubscriptionPortalEnabled =
          res.subscription.retrieveSubscriptionList.data.isSubscriptionPortalEnabled;
        this.subscriptionPortalAppUrl = this.isSubscriptionPortalEnabled
          ? res.subscription.retrieveSubscriptionList.data
              .subscriptionPortalAppUrl
          : '';
        this.subscriptionModuleList.forEach((element) => {
          if (element.externalName === 'My Admin') {
            this.myAdminUrl = element.appUrl;
          }
        });
      } else {
        this.subscriptionModuleList = [];
        this.profileUrl = '';
      }
    });
  }
  onHelpClick(): void {
    window.open(this.helpDeskUrl, '_blank');
  }
}
