<div id="chart">
  <apx-chart
    [series]="chartOptions.series!"
    [chart]="chartOptions.chart!"
    [labels]="chartOptions.labels!"
    [fill]="chartOptions.fill!"
    [legend]="chartOptions.legend!"
    [dataLabels]="chartOptions.dataLabels!"
    [tooltip]="chartOptions.tooltip!"
    [responsive]="chartOptions.responsive!"
  ></apx-chart>
</div>
