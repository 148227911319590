<div class="content-header">
  <div class="content-header-left">
    <mat-form-field appearance="fill" class="search-field">
      <input
        matInput
        placeholder="Search here..."
        autocomplete="off"
        (keyup)="onSearch($event)"
      />
      <mat-icon>search</mat-icon>
    </mat-form-field>
    <div class="d-flex align-items-center gap-10">
      <mat-form-field>
        <mat-select
          panelClass="mat-select-position"
          [disableOptionCentering]="true"
          [(ngModel)]="dueDays"
          (selectionChange)="onDropdownChange($event)"
        >
          <mat-option *ngFor="let cl of dueDaysList" [value]="cl.id">
            <span title="{{ cl.name }}">{{ cl.name }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="content-header-right">
    <mat-form-field>
      <mat-select
        panelClass="mat-select-position"
        [disableOptionCentering]="true"
        [(ngModel)]="statusType"
        (selectionChange)="onDropdownChange($event)"
      >
        <mat-option value="-1">User-All</mat-option>
        <mat-option *ngFor="let cl of ct600StatusList" [value]="cl.id">
          <span title="{{ cl.name }}">{{ cl.name }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-select
        panelClass="mat-select-position"
        [disableOptionCentering]="true"
        [(ngModel)]="statusType"
        (selectionChange)="onDropdownChange($event)"
      >
        <mat-option value="-1">Action-All</mat-option>
        <mat-option *ngFor="let cl of ct600StatusList" [value]="cl.id">
          <span title="{{ cl.name }}">{{ cl.name }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-select
        panelClass="mat-select-position"
        [(ngModel)]="payStatus"
        [disableOptionCentering]="true"
        (selectionChange)="onDropdownChange($event)"
      >
        <mat-option value="-1">Module_Time & Fee</mat-option>
        <mat-option *ngFor="let cl of ct600StatusList" [value]="cl.id">
          <span title="{{ cl.name }}">{{ cl.name }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
