import { Injectable } from '@angular/core';
import {
  ChargeableDisposalDataList,
  ChargeableGainsLosses,
  ChargeableGainsSummary,
  ManagementExpensesLosses,
  NonTradingGainsLosses,
  NonTradingLoanLosses,
  OverseasIncomeLosses,
  PropertyIncomeCalculatorLosses,
  PropertyIncomeLosses,
  TradingProfitLosses,
} from '@app/core/models';
import { LossesNTRLService } from '@app/core/services';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
import {
  DeleteChargeableDisposal,
  GetCharageableGainsLosses,
  GetChargeableDisposal,
  GetChargeableGainsSummary,
  GetManagementExpensesLosses,
  GetNonTradingGainsLosses,
  GetNonTradingLoanLossesData,
  GetOverseasIncomeLosses,
  GetPropertyIncomeCalculatorLosses,
  GetPropertyIncomeLosses,
  GetTradingProfitLossesData,
  SaveCharageableGainsLosses,
  SaveChargeableDisposal,
  SaveChargeableGainsSummary,
  SaveManagementExpensesLosses,
  SaveNonTradingGainsLosses,
  SaveNonTradingLoanLosses,
  SaveOverseasIncomeLosses,
  SavePropertyIncomeCalculatorLosses,
  SavePropertyIncomeLosses,
  SaveTradingProfitLosses,
} from './losses-NTRL.action';

export class LossesNTRStateInfo {
  id?: number;
  tradingProfitLossesData?: TradingProfitLosses;
  nonTradingLoanLossesData?: NonTradingLoanLosses;
  propertyIncomeLossesData?: PropertyIncomeLosses;
  propertyIncomeCalculatorLossesData?: PropertyIncomeCalculatorLosses;
  overseasIncomeLossesData?: OverseasIncomeLosses;
  nonTradingGainsLossesData?: NonTradingGainsLosses;
  chargeableGainsLossesData?: ChargeableGainsLosses;
  chargeableGainsSummaryData?: ChargeableGainsSummary;
  chargeableDisposalData?: ChargeableDisposalDataList;
  managementExpensesLossesData?: ManagementExpensesLosses;
  message: string;
}

@State<LossesNTRStateInfo>({
  name: 'lossesNTR',
  defaults: {
    id: 0,
    message: '',
  },
})
@Injectable()
export class LossesNTRState {
  constructor(private lossesNTRLService: LossesNTRLService) {}

  @Action(SaveTradingProfitLosses)
  saveTradingProfitLosses(
    { patchState }: StateContext<LossesNTRStateInfo>,
    action: SaveTradingProfitLosses
  ) {
    return this.lossesNTRLService
      .saveTradingProfitLosses(action.tradingProfitLossesData)
      .pipe(
        tap((res) => {
          patchState({
            id: res.universalId,
            message: res.tradingProfitLossMessage,
          });
        })
      );
  }

  @Action(GetTradingProfitLossesData)
  getTradingProfitLossesData(
    { patchState }: StateContext<LossesNTRStateInfo>,
    action: GetTradingProfitLossesData
  ) {
    return this.lossesNTRLService
      .getTradingProfitLossesData(action.returnFormId)
      .pipe(
        tap((res) => {
          patchState({
            tradingProfitLossesData: res,
          });
        })
      );
  }

  @Action(SaveNonTradingLoanLosses)
  saveNonTradingLoanLosses(
    { patchState }: StateContext<LossesNTRStateInfo>,
    action: SaveNonTradingLoanLosses
  ) {
    return this.lossesNTRLService
      .saveNonTradingLoanLosses(action.nonTradingLoanLossesData)
      .pipe(
        tap((res) => {
          patchState({
            id: res.universalId,
            message: res.nonTradingProfitLossMessage,
          });
        })
      );
  }

  @Action(GetNonTradingLoanLossesData)
  getNonTradingLoanLossesData(
    { patchState }: StateContext<LossesNTRStateInfo>,
    action: GetNonTradingLoanLossesData
  ) {
    return this.lossesNTRLService
      .getNonTradingLoanLossesData(action.returnFormId)
      .pipe(
        tap((res) => {
          patchState({
            nonTradingLoanLossesData: res,
          });
        })
      );
  }

  @Action(SavePropertyIncomeLosses)
  savePropertyIncomeLosses(
    { patchState }: StateContext<LossesNTRStateInfo>,
    action: SavePropertyIncomeLosses
  ) {
    return this.lossesNTRLService
      .savePropertyIncomeLosses(action.propertyIncomeLossesData)
      .pipe(
        tap((res) => {
          patchState({
            id: res.universalId,
            message: res.propertyIncomeLossMessage,
          });
        })
      );
  }

  @Action(GetPropertyIncomeLosses)
  getPropertyIncomeLosses(
    { patchState }: StateContext<LossesNTRStateInfo>,
    action: GetPropertyIncomeLosses
  ) {
    return this.lossesNTRLService
      .getPropertyIncomeLosses(action.returnFormId)
      .pipe(
        tap((res) => {
          patchState({
            propertyIncomeLossesData: res,
          });
        })
      );
  }

  @Action(SavePropertyIncomeCalculatorLosses)
  savePropertyIncomeCalculatorLosses(
    { patchState }: StateContext<LossesNTRStateInfo>,
    action: SavePropertyIncomeCalculatorLosses
  ) {
    return this.lossesNTRLService
      .savePropertyIncomeCalculatorLosses(
        action.propertyIncomeCalculatorLossesData
      )
      .pipe(
        tap((res) => {
          patchState({
            id: res.universalId,
            message: res.propertyIncomeCalculatorMessage,
          });
        })
      );
  }

  @Action(GetPropertyIncomeCalculatorLosses)
  getPropertyIncomeCalculatorLosses(
    { patchState }: StateContext<LossesNTRStateInfo>,
    action: GetPropertyIncomeCalculatorLosses
  ) {
    return this.lossesNTRLService
      .getPropertyIncomeCalculatorLosses(action.returnFormId)
      .pipe(
        tap((res) => {
          patchState({
            propertyIncomeCalculatorLossesData: res,
          });
        })
      );
  }

  @Action(SaveOverseasIncomeLosses)
  saveOverseasIncomeLosses(
    { patchState }: StateContext<LossesNTRStateInfo>,
    action: SaveOverseasIncomeLosses
  ) {
    return this.lossesNTRLService
      .saveOverseasIncomeLosses(action.overseasIncomeLossesData)
      .pipe(
        tap((res) => {
          patchState({
            id: res.universalId,
            message: res.overseasIncomeLossMessage,
          });
        })
      );
  }

  @Action(GetOverseasIncomeLosses)
  getOverseasIncomeLosses(
    { patchState }: StateContext<LossesNTRStateInfo>,
    action: GetOverseasIncomeLosses
  ) {
    return this.lossesNTRLService
      .getOverseasIncomeLosses(action.returnFormId)
      .pipe(
        tap((res) => {
          patchState({
            overseasIncomeLossesData: res,
          });
        })
      );
  }

  @Action(SaveNonTradingGainsLosses)
  saveNonTradingGainsLosses(
    { patchState }: StateContext<LossesNTRStateInfo>,
    action: SaveNonTradingGainsLosses
  ) {
    return this.lossesNTRLService
      .saveNonTradingGainsLosses(action.overseasIncomeLossesData)
      .pipe(
        tap((res) => {
          patchState({
            id: res.universalId,
            message: res.nonTradingProfitGainsMessage,
          });
        })
      );
  }

  @Action(GetNonTradingGainsLosses)
  getNonTradingGainsLosses(
    { patchState }: StateContext<LossesNTRStateInfo>,
    action: GetNonTradingGainsLosses
  ) {
    return this.lossesNTRLService
      .getNonTradingGainsLosses(action.returnFormId)
      .pipe(
        tap((res) => {
          patchState({
            nonTradingGainsLossesData: res,
          });
        })
      );
  }

  @Action(SaveCharageableGainsLosses)
  saveCharageableGainsLosses(
    { patchState }: StateContext<LossesNTRStateInfo>,
    action: SaveCharageableGainsLosses
  ) {
    return this.lossesNTRLService
      .saveCharageableGainsLosses(action.chargeableGainsLossesData)
      .pipe(
        tap((res) => {
          patchState({
            id: res.universalId,
            message: res.chargeableGainsLossesMessage,
          });
        })
      );
  }

  @Action(GetCharageableGainsLosses)
  getCharageableGainsLosses(
    { patchState }: StateContext<LossesNTRStateInfo>,
    action: GetCharageableGainsLosses
  ) {
    return this.lossesNTRLService
      .getCharageableGainsLosses(action.returnFormId)
      .pipe(
        tap((res) => {
          patchState({
            chargeableGainsLossesData: res,
          });
        })
      );
  }

  @Action(SaveChargeableGainsSummary)
  saveChargeableGainsSummary(
    { patchState }: StateContext<LossesNTRStateInfo>,
    action: SaveChargeableGainsSummary
  ) {
    return this.lossesNTRLService
      .saveChargeableGainsSummary(action.chargeableGainsSummaryData)
      .pipe(
        tap((res) => {
          patchState({
            id: res.universalId,
            message: res.chargeableGainsLossesMessage,
          });
        })
      );
  }

  @Action(GetChargeableGainsSummary)
  getChargeableGainsSummary(
    { patchState }: StateContext<LossesNTRStateInfo>,
    action: GetChargeableGainsSummary
  ) {
    return this.lossesNTRLService
      .getChargeableGainsSummary(action.returnFormId)
      .pipe(
        tap((res) => {
          patchState({
            chargeableGainsSummaryData: res,
          });
        })
      );
  }

  @Action(SaveChargeableDisposal)
  saveChargeableDisposal(
    { patchState }: StateContext<LossesNTRStateInfo>,
    action: SaveChargeableDisposal
  ) {
    return this.lossesNTRLService
      .saveChargeableDisposal(action.chargeableDisposalData)
      .pipe(
        tap((res) => {
          patchState({
            id: res.universalId,
            message: res.statMsg,
          });
        })
      );
  }

  @Action(GetChargeableDisposal)
  getChargeableDisposal(
    { patchState }: StateContext<LossesNTRStateInfo>,
    action: GetChargeableDisposal
  ) {
    return this.lossesNTRLService
      .getChargeableDisposal(action.disposalDetailId, action.returnFormId)
      .pipe(
        tap((res) => {
          patchState({
            chargeableDisposalData: res,
          });
        })
      );
  }

  @Action(DeleteChargeableDisposal)
  deleteChargeableDisposal(
    { patchState }: StateContext<LossesNTRStateInfo>,
    action: DeleteChargeableDisposal
  ) {
    return this.lossesNTRLService
      .deleteChargeableDisposal(action.disposalDetailId, action.returnFormId)
      .pipe(
        tap((res) => {
          patchState({
            message: res.toString(),
          });
        })
      );
  }

  @Action(SaveManagementExpensesLosses)
  saveManagementExpensesLosses(
    { patchState }: StateContext<LossesNTRStateInfo>,
    action: SaveManagementExpensesLosses
  ) {
    return this.lossesNTRLService
      .saveManagementExpensesLosses(action.managementExpensesLossesData)
      .pipe(
        tap((res) => {
          patchState({
            id: res.universalId,
            message: res.managementExpensesMessage,
          });
        })
      );
  }

  @Action(GetManagementExpensesLosses)
  getManagementExpensesLosses(
    { patchState }: StateContext<LossesNTRStateInfo>,
    action: GetManagementExpensesLosses
  ) {
    return this.lossesNTRLService
      .getManagementExpensesLosses(action.returnFormId)
      .pipe(
        tap((res) => {
          patchState({
            managementExpensesLossesData: res,
          });
        })
      );
  }
}
