import { Ct600ReturnModel, EmailData, APReportModel } from '@app/core/models';
import { Guid } from 'guid-typescript';

export class CreateCt600Return {
  static readonly type = '[CT600RETURN] Create ct600 Return';
  constructor(public ct600Return: Ct600ReturnModel) {}
}

export class DeleteCt600Returns {
  static readonly type = '[CT600RETURN] Delete Selected Ct600 Returns';

  constructor(public ids: Array<Guid>) {}
}

export class GetDataByCt600ReturnId {
  static readonly type =
    '[CT600RETURN] Get ct600Return Data By ct600 Return Detail Id';
  constructor(public returnDetailId: Guid) {}
}

export class GetDataByManualPeriods {
  static readonly type = '[CT600RETURN] Get ct600Return Data By Manual Periods';
  constructor(public clientId: Guid) {}
}

export class GetAPReportPeriods {
  static readonly type =
    '[CT600RETURN] Get ct600Return Data By APReport Periods';
  constructor(public apReportModel: APReportModel) {}
}

export class GetCT600Number {
  static readonly type = '[CLIENT] Get CT600 Auto Generated Number';

  constructor(public clientId: Guid, public clientName: string) {}
}

export class GetEmailData {
  static readonly type = '[CT600] Get Email Data';

  constructor(public clientId: Guid, public returnDetailsId: Guid) {}
}
export class SendEmail {
  static readonly type = '[CT600RETURN] Send Email';
  constructor(public emailData: EmailData) {}
}
export class SaveDataSecurity {
  static readonly type = '[DATASECURITY] Save Data';
  constructor(public formData: any) {}
}

export class GetData {
  static readonly type = '[DATASECURITY] Get Data By ClientId';

  constructor(public clientId: Guid) {}
}
export class GetReturnDetails {
  static readonly type = '[RETURN DETAILS] Get Submit Return Details';
  constructor(public reqData: any) {}
}
export class SubmitReturns {
  static readonly type = '[CT600] Submit Returns';
  constructor(public formData: any) {}
}
export class FinalSubmitReturns {
  static readonly type = '[CT600] Final Submit Returns by ReturnDetailId';
  constructor(public returnDetailId: Guid) {}
}
export class UpdateStatusByRecord {
  static readonly type = '[CT600] Record Update Status';
  constructor(public formData: any) {}
}

export class GetBKAccountingCodes {
  static readonly type = '[CT600] Record Accounting Codes';
  constructor(
    public clientName: string,
    public returnDetailId: any,
    public clientId: any
  ) {}
}

export class SaveReturnPayment {
  static readonly type = '[DATASECURITY] Save Return Payment';
  constructor(public formData: any) {}
}

export class SaveAssociatedCompanies {
  static readonly type = '[ASSOCIATEDCOMPANIES] Save Associated Companies';
  constructor(public formData: any) {}
}

export class GetAssociatedCompanies {
  static readonly type = '[ASSOCIATEDCOMPANIES] Get Associated Companies';
  constructor(public returnFormId: Guid) {}
}
