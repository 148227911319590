import { SortOrdering } from '@app/core/enums/sort-ordering';
import { Guid } from 'guid-typescript';

export class SideListModel {
  name: string;
  id: any;
  isSelected? = false;
  universalId?: Guid;
  value?: string;
  data?: Array<any>;
  isMainForm?: boolean;
  formName?: string;
  sideModuleList?: any;
}

export class HighLightModel {
  sortBy: string = SortOrdering.createdOn;
  isHighlighted = false;
  moduleId?: number;
}

export class ListModel {
  name: string;
  universalId: Guid;
  isAssigned = false;
}
