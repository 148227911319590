import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import {
  Client,
  ClientContactModel,
  ClientGridModel,
  ClientIdModel,
  ClientModel,
  MainListParameters,
  QueryParams,
  SideListModel,
} from '@app/core/models';
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  constructor(private http: HttpClient) {}

  createCLient(client: ClientModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Client/saveClient`,
      JSON.stringify(client),
      headers
    );
  }

  createClientContact(clientContactModel: ClientContactModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Client/saveClientContact`,
      JSON.stringify(clientContactModel),
      headers
    );
  }

  deleteClients(ids?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Client/deleteClients`,
      JSON.stringify(ids),
      headers
    );
  }

  deleteClientContact(ids?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Client/deleteClientContacts`,
      JSON.stringify(ids),
      headers
    );
  }

  getClientList(queryParams: MainListParameters): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Client/getClientListData`,
      JSON.stringify(queryParams),
      headers
    );
  }

  getDataByClientId(universalId: Guid): Observable<ClientModel> {
    return this.http.get<ClientModel>(
      `${environment.apiVersionUrl}Client/getClientDetails/${universalId}`
    );
  }

  getDataByContactId(universalId: Guid): Observable<ClientContactModel> {
    return this.http.get<ClientContactModel>(
      `${environment.apiVersionUrl}Client/getClientContactDetails/${universalId}`
    );
  }

  getClientNumber(): Observable<ClientIdModel> {
    return this.http.get<ClientIdModel>(
      `${environment.apiVersionUrl}Client/getAutoClientId`
    );
  }

  getContactList(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}Client/getContactList`
    );
  }

  getCountryList(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}Client/getCountryList`
    );
  }

  getDueDaysList(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}Common/getListofDueDays`
    );
  }

  copyClient(clientIds?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}api/Client/copy`,
      JSON.stringify(clientIds),
      headers
    );
  }

  getVATSubmitTypeList(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}Client/getVATSubmitTypeList`
    );
  }

  getVATSchemeList(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}Client/getVATSchemeList`
    );
  }

  getAllClientList(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}AccountingPeriod/getPeriodClientList`
    );
  }

  checkClientLimitByUser(): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Client/getClientSubscription`
    );
  }

  //new changes
  saveClient(client: Client): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}client/saveClient`,
      JSON.stringify(client),
      headers
    );
  }

  getClientById(clientId: Guid): Observable<Client> {
    return this.http.get<Client>(
      `${environment.apiVersionUrl}Client/getClientByUniversalId/${clientId}`
    );
  }

  getClientDetailList(
    queryParams: QueryParams
  ): Observable<HttpResponse<ClientGridModel>> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Client/executeClientList`,
      queryParams,
      httpOptions
    );
  }
}
