import {
  AssetSummaryModel,
  CaCalculationModel,
  CalCapitalAllowanceModel,
} from '@app/core/models';
import { Guid } from 'guid-typescript';

export class SaveCACalculationData {
  static readonly type = '[CACALCULATION] Save CA Calculation Data';
  constructor(public CaCalculationData: CaCalculationModel) {}
}

export class GetCapitalAllowanceSummary {
  static readonly type = '[CACALCULATION] Get Capital Allowance Summary';
  constructor(public returnFormId: Guid) {}
}

export class GetAssetCategoryCalculation {
  static readonly type = '[CACALCULATION] Get Asset Category Calculation';
  constructor(
    public ct600ReturnId: Guid,
    public assetCategoryId: Guid,
    public assetAcqDate: string
  ) {}
}

export class GetAssetCategoryList {
  static readonly type = '[CACALCULATION] Get Asset Category List';
  constructor(public returnFormId: Guid) {}
}

export class GetAssetCategoryDetails {
  static readonly type = '[CACALCULATION]  Get Asset Category Details';
}

export class GetCapitalAllowanceData {
  static readonly type = '[CACALCULATION] Get Capital Allowance Data';
  constructor(public returnFormId: Guid) {}
}

export class DeleteCapitalAllowance {
  static readonly type = '[CACALCULATION] Delete Capital Allowance';

  constructor(public assestId: any) {}
}

export class CalCapitalAllowance {
  static readonly type = '[CACALCULATION] Calculate Capital Allowance';

  constructor(public calAllowanceModel: CalCapitalAllowanceModel) {}
}

export class SaveAssetSummary {
  static readonly type = '[CACALCULATION] Save Asset Summary';

  constructor(public assetSummaryList: AssetSummaryModel[]) {}
}
