import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ExportFormat } from '@app/core/enums/export-type';
import { MainListParameters, SideListModel } from '@app/core/models';
import { GetDueDaysList } from '@app/core/store';
import { Store } from '@ngxs/store';
import { DueDaysEnum, Modules } from '@app/core/enums';

@Component({
  selector: 'app-side-list-actions',
  templateUrl: './side-list-actions.component.html',
  styleUrls: ['./side-list-actions.component.scss'],
})
export class SideListActionsComponent implements OnInit {
  numberOfRecords = 0;
  moduleEnum = Modules;
  listParameters: MainListParameters = new MainListParameters();
  istoggleSideList = false;
  dueDaysList: SideListModel[];

  @Input()
  isViewPermission: boolean;

  @Input() getModuleId: number;

  @Input() toggleSideList: Observable<boolean>;

  @Input() triggerNumberOfRecords: Observable<any>;

  @Output()
  readonly deleteClick = new EventEmitter<any>();

  @Output()
  readonly exportClick = new EventEmitter<any>();

  @Output()
  readonly copyClick = new EventEmitter<any>();

  @Output()
  readonly cancelSelectionClick = new EventEmitter<any>();

  @Output()
  readonly printClick = new EventEmitter<any>();

  @Output()
  readonly archiveAndRestoreClick = new EventEmitter<any>();

  exportFormat = ExportFormat;
  dueDays = DueDaysEnum['Due in 30 days'];

  constructor(
    public datepipe: DatePipe,
    public dialog: MatDialog,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.triggerNumberOfRecords.subscribe((data: any) => {
      this.numberOfRecords = data && data > 0 ? data : 0;
    });

    this.toggleSideList.subscribe((data: boolean) => {
      this.istoggleSideList = !this.istoggleSideList;
    });

    if (this.getModuleId === this.moduleEnum.Clients) {
      this.getDueDaysList();
    }
  }

  getDueDaysList(): void {
    this.store
      .dispatch(new GetDueDaysList())
      .pipe(
        tap((res) => {
          this.dueDaysList = res.client.dueDaysList;
        })
      )
      .subscribe();
  }

  export(format: number): void {
    this.exportClick.emit(format);
  }

  delete(): void {
    this.deleteClick.emit();
  }

  copy(): void {
    this.copyClick.emit();
  }

  cancelSelection(): void {
    this.cancelSelectionClick.emit();
  }

  print(): void {
    this.printClick.emit();
  }

  archiveAndRestore(isArchive: boolean): void {
    this.archiveAndRestoreClick.emit(isArchive);
  }
}
