import { Guid } from 'guid-typescript';

export class ChargeableGainsLosses {
  returnFormId?: Guid;
  chgblGainLossesLossBFW?: number;
  chgblGainLossesIncomePrd?: number;
  chgblGainLossesArisingPrdBX825?: number;
  chgblGainLossesLossUtilisedPrd?: number;
  chgblGainLossesLossCFW?: number;
}

export class ChargeableGainsSummary {
  returnFormId?: Guid;
  grossInYearBX210?: number;
  capitalLossesInYearBX825?: number;
  capitalLossesBFWPrevoiusYear?: number;
  maximumLossesIncBFW?: number;
  allowableLossesIncBFWBX215?: number;
  netChargeableGainsBX220?: number;
  capitalLossCFWD?: number;
  isAutoSave:boolean
}

export class ChargeableDisposalDataList {
  chargeableDispData?: ChargeableDisposal[];
  listIncidental?: ChargeableDisposalItems[];
  listCostAcquistion?: ChargeableDisposalItems[];
  listCostEnhancement?: ChargeableDisposalItems[];
}

export class ChargeableDisposal {
  returnFormId?: Guid;
  id?: Guid;
  assetDesc?: string;
  dispDate?: string;
  dispProceeds?: number;
  isPartDisp?: boolean;
  orginalAcqDate?: string;
  originalCost?: number;
  isMarketUsed?: boolean;
  marketValuePart?: number;
  gainBeforeIndexAllowance?: number;
  lessIndexAllowance?: number;
  netGainLoss?: number;
  rollRelief?: number;
  anyOhterRelief?: number;
  chargeGainLoss?: number;
  chargeDispItems?: ChargeableDisposalItems[];
}

export class ChargeableDisposalItems {
  id?: number;
  chargeDispId?: number;
  type?: number;
  itemDesc?: string;
  amount?: number;
  expDate?: string;
  isDeleted?: boolean;
}
