import { Guid } from 'guid-typescript';
import { QueryParams, SideListModel } from '../../common';

export class Ct600ReturnModel {
  universalId: Guid;
  clientId: Guid;
  clientName: string;
  reference: string;
  autoReferenceId?: number;
  description: string;
  formType: number;
  returnType: number;
  importDataFrom: number;
  f_AccountingPeriod?: number;
  aPPeriodId?: number;
  periodFrom: any;
  periodTo: any;
  dperiodFrom?: any;
  dperiodTo?: any;
  declarationName: number;
  declarationDate: any;
  isSplitDormant: boolean;
  isPropertyBusiness: boolean;
  importReturnId: Guid;
}

export class ManualPeriodsResponse {
  id: number;
  universalId: Guid;
  accountingPeriod: string;
}

export class AcountingPrdAndCorporationPrd {
  corporationPeriodList: Array<SideListModel>;
  accountingPeriod: string;
}

export class CT600FormGridList {
  pagination: QueryParams;
  ct600FormList: Array<Ct600ReturnList>;
}

export class Ct600ReturnList {
  returnFormUniversalId: Guid;
  returnDetailUniversalId: Guid;
  reference: string;
  fromDate: string;
  toDate: string;
  description: string;
  formType: string;
  returnType: string;
  isSelected?: boolean;
}
export class CMPFormData {
  returnFormId: Guid;
  noAccountsReason: number;
  accounts_DiffrentPeriod: boolean;
  accounts_CurrentPeriod: boolean;
}
export class AccountList {
  id: number;
  name: string;
}
export class AttachmentsData {
  returnFormId: Guid;
  clientId: Guid;
  attachmentType: number;
  description: string;
  FormFile: any;
}

export class EmailData {
  returnDetailId: Guid;
  clientId: Guid;
  toEmail: Array<string>;
  cc: Array<string>;
  bcc: Array<string>;
  subject: string;
  attachments: Array<any>;
  message: any;
  attachTypeId: number;
}

export class EmailLog {
  universalId: Guid;
  ccAddress: string;
  bccAddress: string;
  emailDate: string;
  emailSubject: string;
  toAddress: string;
  name: string;
  isSent: boolean;
  hasAttachments: boolean;
  emailBody: string;
  isSelected: boolean;
}

export class UserlLog {
  universalId: Guid;
  ccAddress: string;
  bccAddress: string;
  emailDate: string;
  emailSubject: string;
  toAddress: string;
  name: string;
  isSent: boolean;
  hasAttachments: boolean;
  emailBody: string;
  isSelected: boolean;
}
