import { Injectable } from '@angular/core';
import { CommonService } from '@app/core/services';
import { Guid } from 'guid-typescript';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class GlobalComponent {
  constructor(
    private cookieService: CookieService,
    private commonService: CommonService
  ) {}

  public getLoggedInAuthorizedRole(): number {
    const authorizedRole = this.cookieService.get('authorizedRole');
    return +authorizedRole;
  }

  public getLoggedInUserId(): Guid {
    const userId = this.cookieService.get('userid');
    return !!this.commonService.convertStringToGuid(userId)
      ? this.commonService.convertStringToGuid(userId)
      : (Guid.EMPTY as unknown as Guid);
  }

  public getClientId(): Guid {
    const clientId = this.cookieService.get('clientId');
    return !!this.commonService.convertStringToGuid(clientId)
      ? this.commonService.convertStringToGuid(clientId)
      : (Guid.EMPTY as unknown as Guid);
  }

  public getClientName(): string {
    const clientName = this.cookieService.get('clientName');
    return clientName;
  }

  public getBusinessId(): Guid {
    const businessid = this.cookieService.get('businessid');
    return !!this.commonService.convertStringToGuid(businessid)
      ? this.commonService.convertStringToGuid(businessid)
      : (Guid.EMPTY as unknown as Guid);
  }

  public getCompanyId(): Guid {
    const companyid = this.cookieService.get('companyid');

    return !!this.commonService.convertStringToGuid(companyid)
      ? this.commonService.convertStringToGuid(companyid)
      : (Guid.EMPTY as unknown as Guid);
  }

  public themecolor(): string {
    const themecolor = this.cookieService.get('themecolor');
    return themecolor;
  }
  public getReturnFormId(): any {
    const returnFormId = sessionStorage.getItem('returnFormId');
    return returnFormId;
  }

  public setSubmitReturns(returnDetailId:any, returnForms:any): void{
    sessionStorage.setItem(returnDetailId.toString(), JSON.stringify(returnForms));
  }

  public getSubmitReturns(returnDetailId:any) : any {
    return sessionStorage.getItem(returnDetailId.toString());

  }

}
