// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://ctapi.dev.capium.co.uk/',
  uiUrl: 'https://ct.dev.capium.co.uk/',
  appDomain: 'ct.dev',
  stsServerUrl: 'https://identity.dev.capium.co.uk',
  identityLoginUrl: 'https://identity.dev.capium.co.uk/Account/Login',
  clientId: 'capium_timeandfee_angular',
  apiVersionUrl: 'https://ctapi.dev.capium.co.uk/api/v1/',
  communicationFrameworkApiUrl:
    'https://communicationframworkapi.dev.capium.co.uk/api',
  helpDeskUrl: 'https://capium.freshdesk.com/support/home',
};
