export enum ModuleName {
  Dashboard = 'Dashboard',
  Clients = 'Clients',
  Contact = 'Contact',
  Tasks = 'task',
  Manage = 'manage',
  AccountingPeriod = 'Accounting Period',
  CT600Return = 'CT600 Return',
  TradingProfessionalProfitsCalculator = 'Trading Professional Profits Calculator',
  Logs = 'Logs',
  Users = 'Users',
  SubmissionHistory = 'SubmissionHistory',
  Export = 'Export',
}

export enum HeaderTextForSideList {
  Clients = 'Clients',
  AccountingPeriod = 'Accouting Periods',
  CT600Return = 'CT600 Returns',
  Contact = 'Contacts',
  Users = 'Users',
}

export enum ModuleHeaderText {
  Clients = 'Client',
  Tasks = 'Task',
  Taxes = 'Tax',
  ExpenseTypes = 'Expense Type',
  Users = 'User',
  Jobs = 'Job',
  Expenses = 'Expense',
  Invoices = 'Invoice',
  RecurringInvoices = 'Recurring Invoice',
  Estimates = 'Estimate',
}
