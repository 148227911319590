import { Injectable } from '@angular/core';
import {
  AccoutingPeriodModel,
  AssestCalculation,
  AssestDetail,
  CaCalculationModel,
  CalSummaryModel,
  CaSummaryModel,
  SideListModel,
} from '@app/core/models';
import { CACalculatorService } from '@app/core/services';
import { Action, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs';
import {
  CalCapitalAllowance,
  DeleteCapitalAllowance,
  GetAssetCategoryCalculation,
  GetAssetCategoryDetails,
  GetAssetCategoryList,
  GetCapitalAllowanceData,
  GetCapitalAllowanceSummary,
  SaveAssetSummary,
  SaveCACalculationData,
} from './ca-calculator.action';

export class CACalculatorStateInfo {
  assestId?: Guid;
  accoutingPeriodData?: AccoutingPeriodModel;
  caSummaryData?: CaSummaryModel;
  calSummaryData?: CalSummaryModel;
  assestCalculationData?: AssestCalculation;
  assetCategoryList?: SideListModel[];
  assestDetail?: AssestDetail[];
  caCalculatorData?: CaCalculationModel;
  exported?: boolean;
  totalRecord?: number;
  message: string;
  isDeleted?: boolean;
  summarySaved?: boolean;
}

@State<CACalculatorStateInfo>({
  name: 'caCalculator',
  defaults: {
    exported: false,
    isDeleted: false,
    message: '',
  },
})
@Injectable()
export class CACalculatorState {
  constructor(private cACalculatorService: CACalculatorService) {}

  @Action(SaveCACalculationData)
  saveCACalculationData(
    { patchState }: StateContext<CACalculatorStateInfo>,
    action: SaveCACalculationData
  ) {
    return this.cACalculatorService
      .saveCACalculationData(action.CaCalculationData)
      .pipe(
        tap((res) => {
          patchState({
            assestId: res.universalId,
            message: res.statusMsg,
          });
        })
      );
  }

  @Action(GetCapitalAllowanceSummary)
  getCapitalAllowanceSummary(
    { patchState }: StateContext<CACalculatorStateInfo>,
    action: GetCapitalAllowanceSummary
  ) {
    return this.cACalculatorService
      .getCapitalAllowanceSummary(action.returnFormId)
      .pipe(
        tap((res) => {
          patchState({
            caSummaryData: res,
          });
        })
      );
  }

  @Action(DeleteCapitalAllowance)
  deleteCapitalAllowance(
    { getState, patchState }: StateContext<CACalculatorStateInfo>,
    action: DeleteCapitalAllowance
  ) {
    return this.cACalculatorService.deleteCapitalAllowance(action.assestId);
  }

  @Action(GetAssetCategoryCalculation)
  getAssetCategoryCalculation(
    { patchState }: StateContext<CACalculatorStateInfo>,
    action: GetAssetCategoryCalculation
  ) {
    return this.cACalculatorService
      .getAssetCategoryCalculation(
        action.ct600ReturnId,
        action.assetCategoryId,
        action.assetAcqDate
      )
      .pipe(
        tap((res) => {
          patchState({
            assestCalculationData: res,
          });
        })
      );
  }

  @Action(GetAssetCategoryList)
  getAssetCategoryList(
    { patchState }: StateContext<CACalculatorStateInfo>,
    action: GetAssetCategoryList
  ) {
    return this.cACalculatorService
      .getAssetCategoryList(action.returnFormId)
      .pipe(
        tap((res) => {
          patchState({
            assetCategoryList: res,
          });
        })
      );
  }

  @Action(GetAssetCategoryDetails)
  getAssetCategoryDetails(
    { patchState }: StateContext<CACalculatorStateInfo>,
    action: GetAssetCategoryDetails
  ) {
    return this.cACalculatorService.getAssetCategoryDetails().pipe(
      tap((res) => {
        patchState({
          assestDetail: res,
        });
      })
    );
  }

  @Action(GetCapitalAllowanceData)
  getCapitalAllowanceData(
    { patchState }: StateContext<CACalculatorStateInfo>,
    action: GetCapitalAllowanceData
  ) {
    return this.cACalculatorService
      .getCapitalAllowanceData(action.returnFormId)
      .pipe(
        tap((res) => {
          patchState({
            caCalculatorData: res,
          });
        })
      );
  }

  @Action(CalCapitalAllowance)
  calCapitalAllowanceData(
    { patchState }: StateContext<CACalculatorStateInfo>,
    action: CalCapitalAllowance
  ) {
    return this.cACalculatorService
      .calCapitalAllowanceData(action.calAllowanceModel)
      .pipe(
        tap((res) => {
          patchState({
            calSummaryData: res,
          });
        })
      );
  }

  @Action(SaveAssetSummary)
  saveAssetSummary(
    { patchState }: StateContext<CACalculatorStateInfo>,
    action: SaveAssetSummary
  ) {
    return this.cACalculatorService
      .saveAssetSummary(action.assetSummaryList)
      .pipe(
        tap((res) => {
          patchState({
            summarySaved: res,
          });
        })
      );
  }
}
