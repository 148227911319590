import {
  ChargeableGainsLosses,
  ManagementExpensesLosses,
  NonTradingLoanLosses,
  OverseasIncomeLosses,
  PropertyIncomeCalculatorLosses,
  PropertyIncomeLosses,
  TradingProfitLosses,
  ChargeableDisposal,
  ChargeableGainsSummary,
} from '@app/core/models';
import { Guid } from 'guid-typescript';

export class GetTradingProfitLossesData {
  static readonly type = '[LossesNTR] Get Losses NTR Data';
  constructor(public returnFormId: Guid) {}
}
export class SaveTradingProfitLosses {
  static readonly type = '[LossesNTR] Save Losses NTRL Trading Profit Loss';
  constructor(public tradingProfitLossesData: TradingProfitLosses) {}
}

export class GetNonTradingLoanLossesData {
  static readonly type = '[LossesNTR] Get Non Trading Loan Losses Data';
  constructor(public returnFormId: Guid) {}
}
export class SaveNonTradingLoanLosses {
  static readonly type = '[LossesNTR] Save Non Trading Loan Losses NTRL';
  constructor(public nonTradingLoanLossesData: NonTradingLoanLosses) {}
}

export class GetPropertyIncomeLosses {
  static readonly type = '[LossesNTR] Get Property Income Losses';
  constructor(public returnFormId: Guid) {}
}

export class SavePropertyIncomeLosses {
  static readonly type = '[LossesNTR] Save Property Income Losses';
  constructor(public propertyIncomeLossesData: PropertyIncomeLosses) {}
}

export class GetPropertyIncomeCalculatorLosses {
  static readonly type = '[LossesNTR] Get Property Income Calculator Losses';
  constructor(public returnFormId: Guid) {}
}

export class SavePropertyIncomeCalculatorLosses {
  static readonly type = '[LossesNTR] Save Property Income Calculator Losses';
  constructor(
    public propertyIncomeCalculatorLossesData: PropertyIncomeCalculatorLosses
  ) {}
}

export class SaveOverseasIncomeLosses {
  static readonly type = '[LossesNTR] Save Overseas Income Losses';
  constructor(public overseasIncomeLossesData: OverseasIncomeLosses) {}
}
export class GetOverseasIncomeLosses {
  static readonly type = '[LossesNTR] Get Overseas Income Losses';
  constructor(public returnFormId: Guid) {}
}

export class GetNonTradingGainsLosses {
  static readonly type = '[LossesNTR] Get Non Trading Gains Losses';
  constructor(public returnFormId: Guid) {}
}

export class SaveNonTradingGainsLosses {
  static readonly type = '[LossesNTR] Save Non Trading Gains Losses';
  constructor(public overseasIncomeLossesData: OverseasIncomeLosses) {}
}

export class GetCharageableGainsLosses {
  static readonly type = '[LossesNTR] Get Chargeable Gains Losses';
  constructor(public returnFormId: Guid) {}
}

export class SaveCharageableGainsLosses {
  static readonly type = '[LossesNTR] Save Chargeable Gains Losses';
  constructor(public chargeableGainsLossesData: ChargeableGainsLosses) {}
}

export class SaveChargeableGainsSummary {
  static readonly type = '[LossesNTR] Save Chargeable Gains Summary';
  constructor(public chargeableGainsSummaryData: ChargeableGainsSummary) {}
}

export class GetChargeableGainsSummary {
  static readonly type = '[LossesNTR] Get Chargeable Gains Summary';
  constructor(public returnFormId: Guid) {}
}

export class SaveChargeableDisposal {
  static readonly type = '[LossesNTR] Save Chargeable Disposal';
  constructor(public chargeableDisposalData: ChargeableDisposal) {}
}

export class GetChargeableDisposal {
  static readonly type = '[LossesNTR] Get Chargeable Disposal';
  constructor(public disposalDetailId: Guid, public returnFormId: Guid) {}
}

export class DeleteChargeableDisposal {
  static readonly type = '[LossesNTR] Delete Chargeable Disposal';
  constructor(public disposalDetailId: Guid, public returnFormId: Guid) {}
}


export class GetManagementExpensesLosses {
  static readonly type = '[LossesNTR] Get Management Expenses Losses';
  constructor(public returnFormId: Guid) {}
}

export class SaveManagementExpensesLosses {
  static readonly type = '[LossesNTR] Save Management Expenses Losses';
  constructor(public managementExpensesLossesData: ManagementExpensesLosses) {}
}
