import { Guid } from 'guid-typescript';
import { ActionModel } from '../common/grid-action';

export class ClientModel {
  id: Guid;
  clientId: string;
  autoClientId?: number;
  clientTypeId: number;
  contactId: number;
  name: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  postCode: string;
  county: string;
  countryId: number;
  email: string;
  mobile: string;
  website: string;
  businessName: string;
  businessStartDate?: string;
  bookStartDate?: string;
  yearEndDay: number;
  yearEndMonth?: number;
  companyRegNo: string;
  uTRNo: string;
  vATSchemeId: number;
  vATSubmitTypeId: number;
  vATRegNo: string;
  vATRegDate?: string;
  accountOfficeRefNo?: number;
  payeRefNo: string;
  insuranceNo?: string;
  status?: string;
  isAutoPeriod?: boolean;
  taxRefNo?: string;
  taxDistNo?: string;
}

export class ClientIdModel {
  autoClientId: number;
  clientId: string;
}

export class ClientListModel {
  universalId: Guid;
  clientId: string;
  clientName: string;
  clientType: string;
  accountingPeriod: string;
  dueDate: string;
  cT600Status: string;
  taxAmount: number;
  isSelected?: boolean;
}

export class ClientContactModel {
  id: Guid;
  designation: number;
  firstName: string;
  middleName: string;
  lastName: string;
  phone: string;
  eMail: string;
  website: string;
  title: number;
}

//new Changes
export class Client {
  id: Guid;
  clientId: string;
  autoClientId?: number;
  clientTypeId: number;
  name?: string;
  businessStartDate?: string;
  bookStartDate?: string;
  yearEndDay: number;
  yearEndMonth?: number;
  companyRegNo: string;
  uTRNo: string;
  vATSchemeId?: number | null;
  vATSubmitTypeId?: number | null;
  vATRegNo?: string;
  vATRegDate?: string;
  accountOfficeRefNo?: number;
  payeRefNo: string;
  insuranceNo?: string;
  status?: string;
  isAutoPeriod?: boolean;
  taxRefNo?: string;
  taxDistNo?: string;
  contacts: Array<Contacts>;
  addresses: Array<AddressModel>;
  addToAllFutureUsers: boolean;
  addToAllExistingUsers: boolean;
  isSelected?: boolean = false;
  isArchived?: boolean;
  universalId?: Guid;
}

export class Contacts {
  universalId: string;
  name: string;
  email: string;
  phone: string;
  title: string;
  countryId?: number;
  isPrimary: boolean;
  eMail: string;
  firstName: string;
}

export class AddressModel {
  universalId: Guid;
  clientId: Guid;
  addressType: string;
  address: string;
  city: string;
  county: string;
  countryId: number;
  postalCode: string;
}

export class ClientGridModel {
  totalItemCount: number;
  data: Array<Client>;
  actions: Array<ActionModel>;
}

export class CompanyModel {
  name: string;
  companyNumber: string;
  address: string;
  city: string;
  county: string;
  country: string;
  countryId: number;
  postalCode: string;
}
