import {
  Client,
  ClientContactModel,
  ClientModel,
  QueryParams,
} from '@app/core/models';
import { Guid } from 'guid-typescript';

export class CreateCLient {
  static readonly type = '[CLIENT] Create Client';
  constructor(public client: ClientModel) {}
}

export class CreateClientContact {
  static readonly type = '[CLIENT] Create Client Contact';
  constructor(public clientContactModel: ClientContactModel) {}
}

export class DeleteClients {
  static readonly type = '[CLIENT] Delete Selected Clients';

  constructor(public ids: Array<Guid>) {}
}
export class DeleteClientContact {
  static readonly type = '[CLIENT] Delete Selected Clients Contact';

  constructor(public ids: Array<Guid>) {}
}

export class GetContactList {
  static readonly type = '[CLIENT] Get Contact List';
}

export class GetCountryList {
  static readonly type = '[CLIENT] Get Country List';
}

export class GetDueDaysList {
  static readonly type = '[CLIENT] Get Due Days List';
}

export class GetDataByClientId {
  static readonly type = '[CLIENT] Get Client Data By Client Id';
  constructor(public clientId: Guid) {}
}

export class GetDataByContactId {
  static readonly type = '[CLIENT] Get Contact Data By Contact Id';
  constructor(public contactId: Guid) {}
}

export class GetClientNumber {
  static readonly type = '[CLIENT] Get Client Number';
}

export class GetVATSubmitTypeList {
  static readonly type = '[CLIENT] Get VAT Submit Type List';
}

export class GetVATSchemeList {
  static readonly type = '[CLIENT] Get VAT Scheme List';
}

export class GetAllClientList {
  static readonly type = '[CLIENT] Get All Client List';
}
export class CheckClientLimit {
  static readonly type = '[CLIENT] Checking Client Limit';
}

// new changes
export class GetClientDataByClientId {
  static readonly type = '[CLIENT] Get Client Data By Client ID';

  constructor(public clientId: Guid) {}
}

export class SaveClient {
  static readonly type = '[CLIENT] Save Client';

  constructor(public client: Client) {}
}

export class GetClientDetailList {
  static readonly type = '[CLIENT] Get Client';

  constructor(public queryParams: QueryParams) {}
}

export class SetDefaultClientId {
  static readonly type = '[CLIENT] Set Default Client ID';
}
