import { Injectable } from '@angular/core';

import { ReportsService } from '@app/core/services/tasks/quick-entry/reports/reports.service';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
import {
  DownloadComputation,
  GetComputationData,
  GetAPReportDetails,
  DownloadHTMLComputation,
  DetachAPReportDetails
} from './reports.action';

export class ReportsStateInfo {
  message: string;
  computationData?: string;
  apReportData?: string;
  isSuccess: boolean;
  isExport: boolean;
}

@State<ReportsStateInfo>({
  name: 'reports',
  defaults: {
    message: '',
    isSuccess: false,
    isExport: false,
  },
})
@Injectable()
export class Reportsstate {
  constructor(private reportService: ReportsService) {}

  @Action(GetComputationData)
  getCT600ComputationData(
    { patchState }: StateContext<ReportsStateInfo>,
    action: GetComputationData
  ) {
    return this.reportService
      .getComputationData(action.retirnFormId, action.clientId)
      .pipe(
        tap((res) => {
          patchState({
            computationData: res.computationHtml,
            message: res.fileName
          });
        })
      );
  }

  @Action(DownloadComputation)
  downloadComputation(
    { patchState }: StateContext<ReportsStateInfo>,
    action: DownloadComputation
  ) {
    let isExport = false;
    return this.reportService
      .downloadComputation(action.returnFormId, action.clientId)
      .pipe(
        tap((res) => {
          isExport = true;
          patchState({
            isExport,
          });
        })
      );
  }

  

  @Action(DownloadHTMLComputation)
  downloadHTMLComputation(
    { patchState }: StateContext<ReportsStateInfo>,
    action: DownloadHTMLComputation
  ) {
    let isExport = false;
    return this.reportService
      .downloadHTMLComputation(action.htmlContnet, action.filename, action.clientId)
      .pipe(
        tap((res) => {
          isExport = true;
          patchState({
            isExport,
          });
        })
      );
  }

  @Action(GetAPReportDetails)
  getAPReportDetails(
    { patchState }: StateContext<ReportsStateInfo>,
    action: GetAPReportDetails
  ) {
    return this.reportService
      .getAPReportDetails(action.returnDetailId, action.returnFormId, action.clientId)
      .pipe(
        tap((res) => {
          patchState({           
            apReportData: res.apReportHtml,
          });
        })
      );
  }

  @Action(DetachAPReportDetails)
  detachAPReportDetails(
    { patchState }: StateContext<ReportsStateInfo>,
    action: DetachAPReportDetails
  ) {
    return this.reportService
      .detachAPReportDetails(action.returnFormId, action.clientId)
      .pipe(
        tap((res) => {
          patchState({
            isSuccess: res,
          });
        })
      );
  }
}
