import { Guid } from 'guid-typescript';

export class OverseasIncomeLosses {
  returnFormId?: Guid;
  oTLossesBFW?: number;
  oTIncomeArising?: number;
  oTLossesArising?: number;
  oTLossesUtilised?: number;
  oTLossesCarryFWD?: number;
  oLPLossesBFW?: number;
  oLPIncomeArising?: number;
  oLPLossesArising?: number;
  oLPLossesUtilised?: number;
  oLPLossesCarryFWD?: number;
  oTIncomeNetBX155?: number;
  oTPropertyNetBX190?: number;
  oTLossesBX790?: number;
  oPBLossesBX815?: number;
}
