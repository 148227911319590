import { Injectable } from '@angular/core';
import { Modules } from '@app/core/enums';
import {
  AcountingPrdAndCorporationPrd,
  CompanyModel,
  Currency,
  FileUploadRequestModel,
  SideListModel,
} from '@app/core/models';
import {
  AccoutingPeriodService,
  CACalculatorService,
  ClientService,
  CommonService,
  Ct600ReturnService,
} from '@app/core/services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { tap } from 'rxjs';
import {
  CheckErrors,
  Copy,
  Export,
  FileDowload,
  FileUpload,
  GetAcountingPeriodAndCorporationPeriodList,
  GetAllUsersList,
  GetCompanyList,
  GetCurrencyList,
  GetFileAsByte,
  GetLastUsedClient,
  GetMainList,
  GetPreValidationList,
  GetSMSTemplates,
  GetSideList,
  GetTitleList,
  RefreshStatus,
  SaveLastUsedClient,
  SaveModuleRequest,
  SendSMS,
  StatusInfo,
  SubmissionSummery,
  SubmittedReturns,
  UpdateThemeColor,
} from './common.action';

export class CommonStateInfo {
  exported?: boolean;
  sideListModel: Array<SideListModel>;
  isCommonAdded?: boolean;
  isLastPage?: boolean;
  mainList?: any;
  isLoading?: boolean;
  totalRecord?: number;
  isCopied: boolean;
  isExport: boolean;
  fileUploadRequestModel: Array<FileUploadRequestModel>;
  actions?: any;
  primaryColor: string;
  lastUsedClientDetails?: SideListModel;
  aPrdAndCPrdList?: AcountingPrdAndCorporationPrd;
  isClientSave: boolean;
  title?: Array<SideListModel>;
  allUsersList: Array<any>;
  errorList: Array<any>;
  isErrosExist?: boolean;
  summery: Array<any>;
  returns: Array<any>;
  isSuccess?: boolean;
  message?: string;
  isReturnStatusError?: boolean;
  returnMsg?: string;
  returnErrorList?: Array<any>;
  templatesList?: Array<any>;
  isAutoPeriod?: boolean;
  company?: CompanyModel[];
  currency: Array<Currency>;
  fileByteUrl?: string;
  responseId?: string;
}

@State<CommonStateInfo>({
  name: 'common',
  defaults: {
    exported: false,
    sideListModel: [],
    fileUploadRequestModel: [],
    isCommonAdded: false,
    isClientSave: false,
    isCopied: false,
    isExport: false,
    primaryColor: '',
    title: [],
    allUsersList: [],
    errorList: [],
    summery: [],
    returns: [],
    currency: [],
  },
})
@Injectable()
export class CommonState {
  constructor(
    private commonService: CommonService,
    private clientService: ClientService,
    private accoutingPeriodService: AccoutingPeriodService,
    private ct600ReturnService: Ct600ReturnService,
    private cACalculatorService: CACalculatorService,

    private ngxSpinnerService: NgxSpinnerService
  ) {}

  @Selector()
  static getSideList(state: CommonStateInfo) {
    return state.sideListModel;
  }

  @Selector()
  static isLastPage(state: CommonStateInfo) {
    return state.isLastPage;
  }

  @Selector()
  static getTitle(state: CommonStateInfo) {
    return state.title;
  }

  @Selector()
  static isLoading(state: CommonStateInfo): boolean {
    return state.isLoading ?? false;
  }

  @Selector()
  static totalRecord(state: CommonStateInfo): number {
    return state.totalRecord ?? 0;
  }

  @Selector()
  static getPeriods(state: CommonStateInfo) {
    return state.aPrdAndCPrdList;
  }

  @Action(Export)
  export({ patchState }: StateContext<CommonStateInfo>, action: Export) {
    let isExport = false;
    return this.commonService.export(action.queryParams, action.moduleId).pipe(
      tap((res) => {
        isExport = true;
        patchState({
          isExport,
        });
      })
    );
  }

  @Action(GetSideList, { cancelUncompleted: true })
  getSideList(
    { getState, setState }: StateContext<CommonStateInfo>,
    action: GetSideList
  ) {
    return this.commonService
      .getSideList(action.queryParams, action.commonModel)
      .pipe(
        tap((res) => {
          const state = getState();
          let common: SideListModel[] | null = [];

          if (
            action.queryParams.pageNumber &&
            action.queryParams.pageNumber > 1
          ) {
            common = state.sideListModel;
            common = common.concat(res.body?.sideModuleList!);
          } else {
            common = res.body?.sideModuleList;
          }

          setState({
            ...state,
            sideListModel: common!,
            isLastPage: res.body?.pagination?.isLastPage,
          });
        })
      );
  }

  @Action(FileUpload)
  fileUpload(
    { patchState }: StateContext<CommonStateInfo>,
    action: FileUpload
  ) {
    return this.commonService.fileUpload(action.fileUploadRequestModel).pipe(
      tap((res) => {
        patchState({
          fileUploadRequestModel: res,
        });
      })
    );
  }

  @Action(Copy)
  copy({ patchState }: StateContext<CommonStateInfo>, action: Copy) {
    let isCopied = false;

    switch (action.moduleId) {
      case Modules.Clients:
        return this.clientService.copyClient(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.CT600Return:
        return this.ct600ReturnService.copyCt600Returns(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );
    }
    this.ngxSpinnerService.hide();
    return isCopied;
  }

  @Action(GetTitleList)
  getTitleList(
    { getState, setState }: StateContext<CommonStateInfo>,
    action: GetTitleList
  ) {
    return this.commonService.getTitleList().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          title: res,
        });
      })
    );
  }

  @Action(GetMainList)
  getMainList(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetMainList
  ) {
    switch (action.moduleId) {
      case Modules.Clients:
        return this.clientService.getClientList(action.queryParams).pipe(
          tap((res) => {
            patchState({
              mainList: res.clientList,
              totalRecord: res.pagination.totalItemCount,
            });
          })
        );

      case Modules.AccountingPeriod:
        return this.accoutingPeriodService
          .getAccoutingPeriodList(action.queryParams)
          .pipe(
            tap((res) => {
              patchState({
                mainList: res.accountPeriodList,
                totalRecord: res.pagination.totalItemCount,
                isAutoPeriod: res?.isAutoPeriod,
              });
            })
          );

      case Modules.CT600Return:
        return this.ct600ReturnService
          .getCt600ReturnList(action.queryParams)
          .pipe(
            tap((res) => {
              patchState({
                mainList: res.ct600FormList,
                totalRecord: res.pagination.totalItemCount,
              });
            })
          );

      case Modules.CapitalAllowancesCalculator:
        return this.cACalculatorService
          .getCACalculatorList(action.queryParams)
          .pipe(
            tap((res) => {
              patchState({
                mainList: res.assetList,
                totalRecord: res.pagination.totalItemCount,
              });
            })
          );

      case Modules.EmailLog:
        return this.commonService.getEmailLogList(action.queryParams).pipe(
          tap((res) => {
            patchState({
              mainList: res.emailLog,
              totalRecord: res.pagination.totalItemCount,
            });
          })
        );

      case Modules.UserLog:
        return this.commonService.getUserLogList(action.queryParams).pipe(
          tap((res) => {
            patchState({
              mainList: res.userLog,
              totalRecord: res.pagination.totalItemCount,
            });
          })
        );

      case Modules.SubmissionHistory:
        return this.commonService.getSubmissionList(action.queryParams).pipe(
          tap((res) => {
            patchState({
              mainList: res.submissionList,
              totalRecord: res?.pagination?.totalItemCount,
            });
          })
        );

      case Modules.Users:
        return this.commonService.getUsersList(action.queryParams).pipe(
          tap((res) => {
            patchState({
              mainList: res.userList,
              totalRecord: res.pagination.totalItemCount,
            });
          })
        );
    }
    return true;
  }

  @Action(FileDowload)
  downloadFile(
    { patchState }: StateContext<CommonStateInfo>,
    action: FileDowload
  ) {
    return this.commonService.downloadFile(action.fileData);
  }

  @Action(UpdateThemeColor)
  updateThemeColor(
    { getState, patchState }: StateContext<CommonStateInfo>,
    action: UpdateThemeColor
  ) {
    return this.commonService.updateThemeColor(action.colorCode).pipe(
      tap((res) => {
        patchState({
          primaryColor: res,
        });
      })
    );
  }

  @Action(GetLastUsedClient)
  getLastUsedClient(
    { getState, patchState }: StateContext<CommonStateInfo>,
    action: GetLastUsedClient
  ) {
    return this.commonService.getLastUsedClient().pipe(
      tap((res) => {
        patchState({
          lastUsedClientDetails: res,
        });
      })
    );
  }

  @Action(GetAcountingPeriodAndCorporationPeriodList)
  getAcountingPeriodAndCorporationPeriodList(
    { getState, patchState }: StateContext<CommonStateInfo>,
    action: GetAcountingPeriodAndCorporationPeriodList
  ) {
    return this.commonService
      .getAcountingPeriodAndCorporationPeriodList(action.returnFormDetailId)
      .pipe(
        tap((res) => {
          patchState({
            aPrdAndCPrdList: res,
          });
        })
      );
  }

  @Action(SaveLastUsedClient)
  saveLastUsedClient(
    { patchState }: StateContext<CommonStateInfo>,
    action: SaveLastUsedClient
  ) {
    return this.commonService.saveLastUsedClient(action.clientId).pipe(
      tap((res) => {
        if (res) {
          this.commonService.setClientId(action.clientId, action.clientName);
        }
        patchState({
          isClientSave: res,
        });
      })
    );
  }

  @Action(GetAllUsersList)
  getAllClientList({ patchState }: StateContext<CommonStateInfo>) {
    return this.commonService.getAllUsersList().pipe(
      tap((res) => {
        patchState({
          allUsersList: res,
        });
      })
    );
  }

  @Action(GetPreValidationList)
  GetPreValidationList(
    { getState, patchState }: StateContext<CommonStateInfo>,
    action: GetPreValidationList
  ) {
    return this.commonService
      .getErrorReport(action.returnFormId, action.clientId)
      .pipe(
        tap((res) => {
          patchState({
            errorList: res,
          });
        })
      );
  }

  @Action(CheckErrors)
  CheckErros(
    { getState, patchState }: StateContext<CommonStateInfo>,
    action: CheckErrors
  ) {
    return this.commonService
      .getCheckErrors(action.returnDetyailId, action.clientId)
      .pipe(
        tap((res) => {
          patchState({
            isErrosExist: res,
          });
        })
      );
  }

  @Action(SubmissionSummery)
  GetSubmissionSummery(
    { getState, patchState }: StateContext<CommonStateInfo>,
    action: SubmissionSummery
  ) {
    return this.commonService
      .getSubmissionSummery(action.days, action.startdate, action.enddate)
      .pipe(
        tap((res) => {
          patchState({
            summery: res,
          });
        })
      );
  }
  @Action(SubmittedReturns)
  GetSubmittedReturns(
    { getState, patchState }: StateContext<CommonStateInfo>,
    action: SubmittedReturns
  ) {
    return this.commonService.getSubmittedReturns(action.year).pipe(
      tap((res) => {
        patchState({
          returns: res,
        });
      })
    );
  }

  @Action(RefreshStatus)
  UpdatePendingStatus(
    { getState, patchState }: StateContext<CommonStateInfo>,
    action: RefreshStatus
  ) {
    return this.commonService.updateStatus(action.submitId).pipe(
      tap((res) => {
        patchState({
          isSuccess: res?.listStatus[0]?.isSuccess,
        });
      })
    );
  }
  @Action(StatusInfo)
  getStatusInfo(
    { getState, patchState }: StateContext<CommonStateInfo>,
    action: StatusInfo
  ) {
    return this.commonService
      .getInfoByStatus(action.submitId, action.statusId)
      .pipe(
        tap((res) => {
          patchState({
            isReturnStatusError: res.isError,
            returnMsg: res.message,
            returnErrorList: res.errList,
          });
        })
      );
  }

  @Action(GetSMSTemplates)
  getSMSTemplates({ getState, patchState }: StateContext<CommonStateInfo>) {
    return this.commonService.getSMSTemplates().pipe(
      tap((res) => {
        patchState({
          templatesList: res,
        });
      })
    );
  }

  @Action(SendSMS)
  sendSms(
    { getState, patchState }: StateContext<CommonStateInfo>,
    action: SendSMS
  ) {
    return this.commonService.sendSms(action.formData).pipe(
      tap((res) => {
        patchState({
          isSuccess: res.status,
          message: res.message,
        });
      })
    );
  }

  @Action(GetCompanyList, { cancelUncompleted: true })
  getCompanyList(
    { getState, patchState }: StateContext<CommonStateInfo>,
    action: GetCompanyList
  ) {
    return this.commonService.getCompanyList(action.queryParams).pipe(
      tap((res) => {
        const state = getState();
        let common: CompanyModel[] | null = [];
        if (
          action.queryParams.pageNumber &&
          action.queryParams.pageNumber > 1
        ) {
          common = state.company!;
          common = common.concat(res);
        } else {
          common = res;
        }

        patchState({
          company: common,
        });
      })
    );
  }

  @Action(GetCurrencyList)
  getCurrencyList({ getState, setState }: StateContext<CommonStateInfo>) {
    return this.commonService.getCurrencyList().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          currency: res,
        });
      })
    );
  }

  @Action(GetFileAsByte)
  getFileAsByte(
    { getState, setState }: StateContext<CommonStateInfo>,
    action: GetFileAsByte
  ) {
    return this.commonService.getFileAsByte(action.fileUrl).pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          fileByteUrl: res.fileData,
        });
      })
    );
  }

  @Action(SaveModuleRequest)
  saveModuleRequest(
    { getState, setState }: StateContext<CommonStateInfo>,
    action: SaveModuleRequest
  ) {
    return this.commonService
      .saveModuleRequest(action.SaveImportRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            responseId: res,
          });
        })
      );
  }
}
