import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-activity',
  templateUrl: './user-activity.component.html',
  styleUrls: ['./user-activity.component.scss'],
})
export class UserActivityComponent implements OnInit {
  constructor() {
    // do nothing.
  }

  ngOnInit(): void {
    // do nothing.
  }
}
