import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  SideListModel,
  TradingPPCalculationModel,
  TradingProfitTagsModel,
} from '@app/core/models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TradingPPCalculatorService {
  constructor(private http: HttpClient) {}

  createTradingPPCalculator(
    tradingPPCalculationData: TradingPPCalculationModel
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}TradingProfits/saveTradingProfitsTotal`,
      JSON.stringify(tradingPPCalculationData),
      headers
    );
  }

  createTradingPPCalculatorTags(
    tradingProfitTagsData: TradingProfitTagsModel
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}TradingProfits/saveTradingProfitsTags`,
      JSON.stringify(tradingProfitTagsData),
      headers
    );
  }

  getDataForTradingPPCalculatorTags(
    returnFormId: Guid,
    type: number
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}TradingProfits/getTradingProfitsDataBasedOnType/${returnFormId}/${type}`
    );
  }

  getDataForTradingPPCalculator(returnFormId: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}TradingProfits/getTradingProfitsData/${returnFormId}`
    );
  }

  getTradingProfitsTagsList(type: number): Observable<SideListModel[]> {
    return this.http.get<SideListModel[]>(
      `${environment.apiVersionUrl}TradingProfits/getTradingProfitsTagsList/${type}`
    );
  }
}
