import { Guid } from 'guid-typescript';

export class SubmissionHistoryListModel {
  refNo: string;
  fromDate: string;
  toDate: string;
  type: string;
  description: string;
  submissionDate: string;
  status: string;
  isSelected: boolean;
  returnDetailId: Guid;
}
