import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ImportBfwdModel } from '@app/core/models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImportBfwdService {
  constructor(private http: HttpClient) {}

  getImportReturnList(clientId: Guid, periodFrom: string): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}CT600Form/getImportReturns/${clientId}/${periodFrom}`
    );
  }

  saveImportBfwdData(importBfwdModel: ImportBfwdModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}LossesNTLR/saveImportBfwdData`,
      JSON.stringify(importBfwdModel),
      headers
    );
  }
}
