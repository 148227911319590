<div class="content-body content-body-scroll">
  <div class="chart-wrapper d-flex gap-20">
    <div class="card">
      <div
        class="d-flex align-items-center justify-space-between hr-bottom pb-1 mb-2"
      >
        <h3 class="card-title mb-0">CT600 Submission Summary</h3>

        <div class="content-header-center year-picker">
          <app-custom-day-picker
            (triggerDateChange)="triggerDateChange($event)"
          ></app-custom-day-picker>
        </div>
      </div>
      <app-submission-summary-chart [inputObj]="inputObj">
      </app-submission-summary-chart>
    </div>
    <div class="card">
      <div
        class="d-flex align-items-center justify-space-between hr-bottom pb-1"
      >
        <h3 class="card-title mb-0">Monthly Submitted Return(s)</h3>
        <div class="w-150">
          <ng-select panelClass="myPanelClass" [(ngModel)]="year">
            <ng-option [value]="0"> This Financial Year</ng-option>
            <ng-option *ngFor="let year of selectYear" [value]="year.id">
              {{ year.name }}</ng-option
            >
          </ng-select>
        </div>
      </div>
      <app-monthly-submitted-return [year]="year">
      </app-monthly-submitted-return>
    </div>
  </div>
</div>
