import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

import { Guid } from 'guid-typescript';
import { UserPermission } from '@app/core/models';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  getUserPermissions(): Observable<Array<UserPermission>> {
    return this.http.get<Array<UserPermission>>(
      `${environment.apiVersionUrl}User/getStandardRoles`
    );
  }

  createUser(user: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}User/saveUserClients`,
      JSON.stringify(user),
      headers
    );
  }

  getDataByClientId(universalId: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}User/getUserClients?universalId=${universalId}`
    );
  }

  deleteUsers(ids?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}User/deleteUser`,
      JSON.stringify(ids),
      headers
    );
  }

  inviteUser(userData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}User/inviteUser`,
      JSON.stringify(userData),
      headers
    );
  }
}
