<div class="content client-list">
  <div class="content-body p-0">
    <div class="mat-table-wrapper">
      <table
        aria-describedby="receipt-list"
        mat-table
        matSort
        [dataSource]="dataSource"
      >
        <ng-container matColumnDef="RefNo.">
          <th mat-header-cell *matHeaderCellDef class="w-30">
            <span mat-sort-header>Ref No.</span>
          </th>
          <td mat-cell *matCellDef="let element; let i = index">
            <div class="d-flex">
              {{ i + 1 }}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="FromDate">
          <th mat-header-cell *matHeaderCellDef class="w-40">
            <span mat-sort-header>From Date</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex">
              {{ element.fromDate }}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="ToDate">
          <th mat-header-cell *matHeaderCellDef class="w-40">
            <span mat-sort-header>To Date</span>
          </th>
          <td mat-cell *matCellDef="let element" class="widthemail">
            {{ element.toDate }}
          </td>
        </ng-container>
        <ng-container matColumnDef="Description">
          <th mat-header-cell *matHeaderCellDef class="w-40">
            <span mat-sort-header>Description</span>
          </th>

          <td mat-cell *matCellDef="let element" class="widthemail">
            {{ element.description }}
          </td>
        </ng-container>
        <ng-container matColumnDef="Status">
          <th mat-header-cell *matHeaderCellDef class="w-30">
            <span mat-sort-header>Status</span>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.status }}
          </td>
        </ng-container>
        <ng-container matColumnDef="Computation">
          <th mat-header-cell *matHeaderCellDef class="w-30">
            <span mat-sort-header>Computation</span>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.Computation }}
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
</div>
