import { Component } from '@angular/core';

export interface CT600PeriodicElement {
  refNo: number;
  fromDate: string;
  toDate: string;
  description: string;
  status: string;
  Computation: string;
}

const ELEMENT_DATA: CT600PeriodicElement[] = [
  {
    refNo: 1,
    fromDate: '10/12/2022',
    toDate: '26/12/2022',
    description: 'H',
    status: 'In-Progress',
    Computation: 'Computation',
  },
  {
    refNo: 2,
    fromDate: '11/12/2022',
    toDate: '27/12/2022',
    description: 'H',
    status: 'In-Progress',
    Computation: 'Computation',
  },
  {
    refNo: 3,
    fromDate: '12/12/2022',
    toDate: '28/12/2022',
    description: 'H',
    status: 'In-Progress',
    Computation: 'Computation',
  },
  {
    refNo: 4,
    fromDate: '13/12/2022',
    toDate: '29/12/2022',
    description: 'H',
    status: 'In-Progress',
    Computation: 'Computation',
  },
  {
    refNo: 5,
    fromDate: '14/12/2022',
    toDate: '30/12/2022',
    description: 'H',
    status: 'In-Progress',
    Computation: 'Computation',
  },
];

@Component({
  selector: 'app-CT600-graph',
  templateUrl: './CT600-graph.component.html',
  styleUrls: ['./CT600-graph.component.scss'],
})
export class CT600GraphComponent {
  displayedColumns: string[] = [
    'RefNo.',
    'FromDate',
    'ToDate',
    'Description',
    'Status',
    'Computation',
  ];
  dataSource = ELEMENT_DATA;
}
