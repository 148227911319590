import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { Store } from '@ngxs/store';
export class YearDictionarynew {
  key: number;
  value: string;
}
export const MY_DATE_FORMATS_NEW = {
  display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-custom-day-picker',
  templateUrl: './custom-day-picker.component.html',
  styleUrls: ['./custom-day-picker.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS_NEW }],
})
export class CustomDayPickerComponent implements OnInit {
  selectedOption = 0;
  currentYear: number = new Date().getFullYear();
  yearsListPair: Array<YearDictionarynew> = [];
  rangeFormGroup: FormGroup;

  @Output()
  triggerDateChange = new EventEmitter<any>();

  constructor(public datepipe: DatePipe, private store: Store) {}

  ngOnInit(): void {
    this.rangeFormGroup = new FormGroup({
      start: new FormControl(null),
      end: new FormControl(null),
    });

    this.yearsListPair = [
      {
        key: 0,
        value: 'This day',
      },
      {
        key: 30,
        value: '30 days',
      },
      {
        key: 60,
        value: '60 days',
      },
      {
        key: 90,
        value: '90 days',
      },
      {
        key: 180,
        value: '180 days',
      },
      {
        key: 360,
        value: '360 days',
      },
      {
        key: 7,
        value: 'Custom',
      },
    ];
  }

  generateYears(): void {
    for (
      let i = this.currentYear - 5, j = 0;
      i <= this.currentYear + 5;
      i++, j++
    ) {
      if (j === 0) {
        this.yearsListPair.push({
          key: j,
          value: ' All years ',
        });
      } else if (i === this.currentYear + 5) {
        this.yearsListPair.push({
          key: j,
          value: 'Custom dates',
        });
      } else {
        this.yearsListPair.push({
          key: j,
          value: i.toString() + ' - ' + (i + 1).toString(),
        });
      }
    }
  }

  onMinus(): void {
    this.selectedOption--;

    const sendingData = {
      startDate:
        this.selectedOption > 0 ? this.getStartDate(this.selectedOption) : '',
      endDate:
        this.selectedOption > 0 ? this.getEndDate(this.selectedOption) : '',
      filter: this.selectedOption,
    };

    this.triggerDateChange.emit(sendingData);

    if (this.selectedOption === -1) {
      this.selectedOption = 10;
    }
  }

  getStartDate(selectedOption: number): any {
    return this.datepipe.transform(
      new Date(
        Number(
          this.yearsListPair[selectedOption].value.split('-', 2)[0].trim()
        ),
        0,
        1
      ),
      'dd-MMM-yyyy'
    );
  }

  getEndDate(selectedOption: number): any {
    return this.datepipe.transform(
      new Date(
        Number(
          this.yearsListPair[selectedOption].value.split('-', 2)[1].trim()
        ),
        11,
        31
      ),
      'dd-MMM-yyyy'
    );
  }

  onPlus(): void {
    this.selectedOption++;
    const sendingData = {
      startDate:
        this.selectedOption < 10 ? this.getStartDate(this.selectedOption) : '',
      endDate:
        this.selectedOption < 10 ? this.getEndDate(this.selectedOption) : '',
      filter: this.selectedOption,
    };

    this.triggerDateChange.emit(sendingData);

    if (this.yearsListPair.length === this.selectedOption) {
      this.selectedOption = 0;
    }
  }

  onDropdownChange(event: number): void {
    if (this.selectedOption === 7) {
      this.rangeFormGroup.reset();
      this.getStartWeekAndEndWeek();
    } else {
      const sendingData = {
        startDate: '',
        endDate: '',
        filter: this.selectedOption,
      };
      this.triggerDateChange.emit(sendingData);
    }
  }

  getStartWeekAndEndWeek(): void {
    const curr = new Date();

    const firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()));
    const lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 6));

    this.rangeFormGroup.setValue({
      start: new Date(firstday) ?? null,
      end: new Date(lastday) ?? null,
    });
    if (firstday && lastday) this.dateRangeChange(firstday, lastday);
  }

  dateRangeChange(dateRangeStart: any, dateRangeEnd: any): void {
    const sendingData = {
      startDate: this.datepipe.transform(
        new Date(dateRangeStart),
        'dd-MMM-yyyy'
      ),
      endDate: this.datepipe.transform(new Date(dateRangeEnd), 'dd-MMM-yyyy'),
      filter: this.selectedOption,
    };
    this.triggerDateChange.emit(sendingData);
  }

  lessWeek(e: any): void {
    e.stopPropagation();
    this.rangeFormGroup.setValue({
      end: new Date(
        new Date(this.rangeFormGroup.controls.start.value).setDate(
          new Date(this.rangeFormGroup.controls.start.value).getDate() - 1
        )
      ),
      start: new Date(
        new Date(this.rangeFormGroup.controls.start.value).setDate(
          new Date(this.rangeFormGroup.controls.start.value).getDate() - 7
        )
      ),
    });

    this.dateRangeChange(
      this.rangeFormGroup.controls.start.value,
      this.rangeFormGroup.controls.end.value
    );
  }

  addWeek(e: any): void {
    e.stopPropagation();
    this.rangeFormGroup.setValue({
      start: new Date(
        new Date(this.rangeFormGroup.controls.end.value).setDate(
          new Date(this.rangeFormGroup.controls.end.value).getDate() + 1
        )
      ),
      end: new Date(
        new Date(this.rangeFormGroup.controls.end.value).setDate(
          new Date(this.rangeFormGroup.controls.end.value).getDate() + 7
        )
      ),
    });

    this.dateRangeChange(
      this.rangeFormGroup.controls.start.value,
      this.rangeFormGroup.controls.end.value
    );
  }
}
