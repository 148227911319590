<div class="action-wrapper">
  <div class="content-body">
    <button
      *ngIf="getRollForward"
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      [ngShortcut]="'@rollforward'"
      (click)="onRollSave()"
    >
      Roll Forward
    </button>
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      [ngShortcut]="'@save'"
      (click)="onSave(false)"
    >
      Save
    </button>
    <button
      *ngIf="
        getModuleId === moduleEnum.Clients ||
        getModuleId === moduleEnum.CT600Return ||
        getModuleId === moduleEnum.Users ||
        getModuleId === moduleEnum.AccountingPeriod ||
        getModuleId === moduleEnum.CapitalAllowances
      "
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      [ngShortcut]="'@saveAndExits'"
      (click)="onSave(true)"
    >
      Save and Exit
    </button>
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      [ngShortcut]="'@saveAndInvite'"
      (click)="onSave(true, false, true)"
      *ngIf="getModuleId === modules.Users"
    >
      Save and Invite
    </button>
    <button
      class="action-button secondary-button mr-1"
      type="button"
      mat-button
      disableRipple
      [ngShortcut]="'@cancel'"
      (click)="onCancel()"
    >
      Cancel Changes
    </button>
  </div>
</div>
<ng-shortcut></ng-shortcut>
