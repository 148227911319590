import { Guid } from 'guid-typescript';

export class MenuModel {
  id: number;
  icon: string;
  name: string;
  url: string;
  editUrl?: string;
  addURL: string;
  subMenu: Array<MenuModel>;
  permissionId?: number;
  sortOrder?: number;
}

export class ProfileInfoModel {
  id: Guid;
  name: string;
  email: string;
  photo: string;
  businessId: Guid;
  themeColor: string;
}
