<div id="chart">
  <apx-chart
    *ngIf="isLoadingComplete && noOfRecords > 0"
    [series]="chartOption?.series!"
    [chart]="chartOption?.chart!"
    [labels]="chartOption?.labels!"
    [tooltip]="chartOption?.tooltip!"
    [responsive]="chartOption?.responsive!"
    [legend]="chartOption?.legend!"
    [fill]="chartOption?.fill!"
    [noData]="chartOption?.noData!"
    [colors]="[
      '#9B2FC5',
      '#FA76A1',
      '#8960C2',
      '#E57F6A',
      '#FF6498',
      '#E57F6A'
    ]"
  ></apx-chart>
</div>
<div class="norecord-chart" *ngIf="isLoadingComplete && noOfRecords === 0">
  <img src="assets/images/pia-icon.svg" alt="Pie Chart" />
  <p class="pt-10">No data available yet!</p>
</div>
