import { Guid } from 'guid-typescript';

export class MainFormModel {
  returnFormId: Guid;
  returnDetailId: Guid;
  fieldId: string;
  value: any;
  fieldType: number;
  clientId: Guid;
}

export class SupplamentoryFormsModel {
  returnFormId: Guid;
  suppFormValues: string;
  formName: string;
}
export class CT600Form {
  returnFormId: Guid;
  clientId: string;
  activeTab: string;
}
