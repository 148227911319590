import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { CommonService } from '@app/core/services';
import { environment } from '@environments/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Guid } from 'guid-typescript';
import jwt_decode from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PermissionType, RoutingPath } from '../enums';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  isLogin = false;
  appDomain = environment.appDomain;
  domain = 'capium.co.uk/';
  uiUrl = environment.uiUrl;
  constructor(
    private cookieService: CookieService,
    private router: Router,
    public oidcSecurityService: OidcSecurityService,
    @Inject(DOCUMENT) private document: Document,
    private commonService: CommonService,
    private spinner: NgxSpinnerService
  ) {}

  canActivateChild(
    next: ActivatedRouteSnapshot
  ): Observable<boolean> | boolean {
    return this.canActivate(next);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state?: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    const userId = this.cookieService.get('userid') as unknown as Guid;
    if (
      userId === ('' as unknown as Guid) ||
      this.document.location.href.includes('code')
    ) {
      this.spinner.show();
    }
    return this.oidcSecurityService.checkAuth().pipe(
      switchMap((isAuthenticated) => {
        const userId = this.cookieService.get('userid') as unknown as Guid;
        if (isAuthenticated && userId !== ('' as unknown as Guid)) {
          if (state?.url === '/') {
            return this.router.navigate([RoutingPath.Dashboard]);
          }

          return this.hasPermissionToAccess(state?.url).pipe(
            map((hasAccess) => {
              if (hasAccess) {
                this.isLogin = isAuthenticated;
                return this.handleTokenRedirection();
              } else {
                this.router.navigate([RoutingPath.accessDenied]);

                return false;
              }
            })
          );
        } else {
          this.isLogin = isAuthenticated;
          return of(this.handleTokenRedirection());
        }
      }),
      catchError((err) => {
        this.router.navigate([RoutingPath.Dashboard]);

        return of(false);
      })
    );
  }

  hasPermissionToAccess(url): Observable<boolean> {
    let urlIndex = this.setUrlIndex(url);

    const currentUrl = url.indexOf(urlIndex) > 0 ? url.split(urlIndex)[0] : url;

    const userPermission = localStorage.getItem('userPermission')!;
    const element = JSON.parse(userPermission);

    if (!element) {
      return of(true);
    }

    for (const ele of element) {
      if (
        (ele.moduleUrl === currentUrl || ele.moduleAddUrl === currentUrl) &&
        ele.permissionId === PermissionType.None
      ) {
        return of(false);
      }

      for (const childElement of ele.children) {
        if (
          (childElement.moduleUrl === currentUrl ||
            childElement.moduleAddUrl === currentUrl) &&
          childElement.permissionId === PermissionType.None
        ) {
          return of(false);
        }
      }
    }

    return of(true);
  }
  setUrlIndex(url) {
    let urlIndex = '';
    if (url.indexOf(';') > 0) {
      urlIndex = ';';
    }
    if (url.indexOf('?') > 0) {
      urlIndex = '?';
    }
    return urlIndex;
  }

  notLoggedIn(): void {
    const url = window.location.href;

    localStorage.clear();
    sessionStorage.clear();

    this.deleteAllCookies();

    if (url.split('/')[url.split('/').length - 1] === 'register') {
      this.isLogin = false;
    } else {
      this.login();
    }
  }

  login(): void {
    this.oidcSecurityService.authorize();
  }

  logout(): void {
    this.oidcSecurityService.logoff();
  }

  deleteAllCookies(): void {
    const cookies = document.cookie.split(';');

    for (const element of cookies) {
      const cookie = element;
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  }

  getAppUrl(): string {
    const restUrl = `${window.location.pathname}${window.location.search}`;

    return `https://${this.appDomain}.${this.domain}${restUrl}`;
  }

  private handleTokenRedirection(): boolean {
    const token = this.oidcSecurityService.getToken();

    const origin = window.location.origin;
    const originParts = origin.split('.');
    let urlTenantName = '';

    this.isLogin = false;

    if (!token) {
      this.notLoggedIn();

      return false;
    }

    const decoded: any = jwt_decode(token);

    this.cookieService.set('externalUserGUID', decoded.ExternalUserGUID);
    this.cookieService.set('name', decoded.name);
    this.cookieService.set('email', decoded.email);
    if (!decoded.tenantName) {
      return false;
    }

    if (originParts.length > 3) {
      urlTenantName = originParts[0].split('//')[1];
    }

    if (originParts.length === 3) {
      urlTenantName = decoded.tenantName;
    }

    this.commonService.setTenantName(decoded.tenantName);
    this.navigateToDashboard(decoded.tenantName, decoded.IsTenantEnabled);

    return true;
  }

  navigateToDashboard(tenantName: string, IsTenantEnabled: string): void {
    let baseurl: string = `https://${tenantName}.${this.appDomain}.${this.domain}`;
    if (
      this.document.location.href.includes('code') ||
      (this.document.location.href === this.uiUrl &&
        IsTenantEnabled === 'False') ||
      (this.document.location.href === baseurl && IsTenantEnabled === 'True')
    ) {
      this.document.location.href =
        this.document.location.origin + '/dashboard';
    } else if (
      IsTenantEnabled === 'True' &&
      this.document.location.origin + '/' !== baseurl &&
      this.document.location.origin !== 'https://localhost:4200'
    ) {
      this.document.location.href = baseurl + 'dashboard';
    } else if (
      IsTenantEnabled === 'False' &&
      this.document.location.origin + '/' !== this.uiUrl &&
      this.document.location.origin !== 'https://localhost:4200'
    ) {
      this.document.location.href = this.uiUrl + 'dashboard';
    }
  }
}
