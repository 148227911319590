import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { environment } from '@environments/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  days: number = 0;
  year: number = 0;

  selectYear: any = [];
  appDomain = environment.appDomain;
  domain = 'capium.co.uk/';
  uiUrl = environment.uiUrl;
  constructor(
    public oidcSecurityService: OidcSecurityService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.checkUrlRedirection();
  }

  ngOnInit(): void {
    // do nothing.
    let yr = this.generateArrayOfYears();
    yr.forEach((element) => {
      let obj = {
        id: element,
        name: element.toString(),
      };
      this.selectYear.push(obj);
    });
  }

  generateArrayOfYears(): Array<any> {
    let max = new Date().getFullYear();
    let min = max - 10;
    let years: any = [];

    for (let i = max - 1; i >= min; i--) {
      years.push(i);
    }
    return years;
  }
  inputObj: any = { filter: 0, startDate: '', endDate: '' };
  triggerDateChange(data: any): void {
    this.inputObj = data;
  }

  checkUrlRedirection(): void {
    if (
      this.oidcSecurityService.getToken() &&
      this.document.location.origin !== 'https://localhost:4200'
    ) {
      const decoded: any = jwt_decode(this.oidcSecurityService.getToken());
      let baseurl: string = `https://${decoded.tenantName}.${this.appDomain}.${this.domain}`;
      if (
        decoded.IsTenantEnabled === 'True' &&
        this.document.location.origin + '/' !== baseurl
      ) {
        this.document.location.href = baseurl + 'dashboard';
      } else if (
        decoded.IsTenantEnabled === 'False' &&
        this.document.location.origin + '/' !== this.uiUrl
      ) {
        this.document.location.href = this.uiUrl + 'dashboard';
      }
    }
  }
}
