import { Guid } from 'guid-typescript';

export class AccoutingPeriodModel {
  fromDate?: string;
  toDate?: string;
  accountsDueDate?: string;
  cTDueDate?: string;
  clientId?: Guid;
  isLocked: boolean;
  isCeased: boolean;
  isComparative: boolean;
  isPriorAccoutingPeriod: boolean;
  id: Guid;
}

export class APReportModel {
  clientId?: Guid;
  clientName?: string;
}

export class APReportResponse {
  id?: number;
  vNo: string;
  fromDate: Date;
  toDate: Date;
}
