import {
  CT600Form,
  MainFormModel,
  SupplamentoryFormsModel,
} from '@app/core/models';
import { Guid } from 'guid-typescript';

export class SaveMainFormData {
  static readonly type = '[MainForm] Save MainForm Data';
  constructor(public mainFormData: MainFormModel) {}
}

export class GetMainFormsByReturnId {
  static readonly type =
    '[CT600RETURN] Get Main Forms Data By ct600 Return form Id';
  constructor(
    public clientId: Guid,
    public returnFormId: Guid,
    public pageNo: number
  ) {}
}
export class GetSupplamentoryFormsByReturnId {
  static readonly type =
    '[CT600RETURN] Get SupplamentoryForms Data By ct600 Return form Id';
  constructor(public returnFormId: Guid) {}
}

export class GetSupplamentoryFormsDetailsByReturnId {
  static readonly type =
    '[CT600RETURN] Get SupplamentoryForms Details By ct600 Return form Id and form name';
  constructor(public returnFormId: Guid, public formName: string) {}
}

export class SaveSupplamentoryFormsDetails {
  static readonly type =
    '[MainForm] Save SupplamentoryForms Details by formname';
  constructor(public spFormData: SupplamentoryFormsModel) {}
}

export class GetSupplamentoryFormNamesByReturnId {
  static readonly type =
    '[CT600Form] Get SupplamentoryForms names By ct600 Return form Id';
  constructor(public returnFormId: Guid) {}
}

export class DownloadCT600Form {
  static readonly type = '[MainForm] Download CT600 Form Based On Tab';
  constructor(public formData: CT600Form) {}
}
