import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';

export class YearDictionary {
  key: number;
  value: string;
}
export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-custom-year-picker',
  templateUrl: './custom-year-picker.component.html',
  styleUrls: ['./custom-year-picker.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }],
})
export default class CustomYearPickerComponent implements OnInit {
  selectedOption = 0;
  currentYear: number = new Date().getFullYear();
  yearsListPair: Array<YearDictionary> = [];
  rangeFormGroup: FormGroup;

  @Output()
  triggerDateChange = new EventEmitter<any>();

  constructor(public datepipe: DatePipe, private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 6);
    this.rangeFormGroup = this.formBuilder.group({
      start: [startDate],
      end: [new Date()],
    });

    this.generateYears();
  }

  generateYears(): void {
    for (
      let i = this.currentYear - 5, j = 0;
      i <= this.currentYear + 5;
      i++, j++
    ) {
      if (j === 0) {
        this.yearsListPair.push({
          key: j,
          value: ' All years ',
        });
      } else if (j === 1) {
        this.yearsListPair.push({
          key: j,
          value: 'Custom dates',
        });
      } else {
        this.yearsListPair.push({
          key: j,
          value: i.toString() + ' - ' + (i + 1).toString(),
        });
      }
    }
  }

  onDateChange(): void {
    const sendingData = {
      startDate:
        this.selectedOption > 0 ? this.getStartDate(this.selectedOption) : '',
      endDate:
        this.selectedOption > 0 ? this.getEndDate(this.selectedOption) : '',
    };

    if (sendingData.endDate !== null && sendingData.startDate !== null) {
      this.triggerDateChange.emit(sendingData);
    }
  }

  onMinus(): void {
    this.selectedOption--;

    this.onDateChange();

    if (this.selectedOption === -1) {
      this.selectedOption = 1;
    }
  }

  getStartDate(selectedOption: number): any {
    if (selectedOption !== 1) {
      return this.datepipe.transform(
        new Date(
          Number(
            this.yearsListPair[selectedOption].value.split('-', 2)[0].trim()
          ),
          0,
          1
        ),
        'dd-MMM-yyyy'
      );
    } else {
      return this.rangeFormGroup.controls.start.value;
    }
  }

  getEndDate(selectedOption: number): any {
    if (selectedOption !== 1) {
      return this.datepipe.transform(
        new Date(
          Number(
            this.yearsListPair[selectedOption].value.split('-', 2)[1].trim()
          ),
          11,
          31
        ),
        'dd-MMM-yyyy'
      );
    } else {
      return this.rangeFormGroup.controls.end.value;
    }
  }

  onPlus(): void {
    this.selectedOption++;
    this.onDateChange();

    if (this.yearsListPair.length === this.selectedOption) {
      this.selectedOption = 0;
    }
  }

  onDropdownChange(event: number): void {
    this.onDateChange();
  }
}
