import { Guid } from 'guid-typescript';

export class CaCalculationModel {
  universalId?: Guid;
  returnFormGuid: Guid;
  assetDesc: string;
  assetCatId: number;
  assetAcqDate: string;
  assetRate: number;
  wDABroughtFWD: number;
  additions: number;
  restrictAmount: number;
  isNoClaim: boolean;
  aIAAvailable: number;
  aIAClaimed: number;
  assetDispDate: string | null;
  dispProceeds: number;
  isBusinessClosed: boolean;
  isCar: boolean;
  isPurchaseAfterApril: boolean;
  co2Emission: number;
  isFlatConversion: boolean;
  isAllowance100: boolean;
  isFYA40: boolean;
  isFYA100: boolean;
  isFA50: boolean;
  isSuperDeduct130: boolean;
  isSmallPoolAllowance: boolean;
  isSBAssetNotQual: boolean;
  sBStartQualDate: string | null;
  sBCeaseQualDate: string | null;
  spDedcutCalim: number;
  PrevAIAClaim: number;
  importAssets: any;
  isRollFwd: boolean;
  isFullExp100: boolean;
}

export class AssestCalculation {
  wDARate: string;
  aIAValue: string;
  accFrom: string;
  accTo: string;
  enableAssetValue: Array<number>;
}

export class AssestDetail {
  value: number;
  label: string;
  isSelected: boolean;
  isDisable: boolean;
}
