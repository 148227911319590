<div class="content-body-wrapper general-setting mb-4">
  <div class="content-body-title">
    <div class="header-text d-flex justify-space-between align-items-center">
      <div>
        <p><span>Document Templates</span></p>
      </div>
      <button
        mat-button
        class="header-text-close d-flex align-items-center"
        disableRipple
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="content-body">
    <div>
      <div class="accordion-toggle">
        <mat-icon
          (click)="toggleAccordion(); accordion.openAll()"
          *ngIf="isExpandAll"
        >
          keyboard_double_arrow_down
        </mat-icon>
        <mat-icon
          (click)="toggleAccordion(); accordion.closeAll()"
          *ngIf="!isExpandAll"
        >
          keyboard_double_arrow_up
        </mat-icon>
      </div>
      <mat-accordion [multi]="true">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="d-flex flex-column">
                <span>TAX Document Template</span>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="content-body">
            <form [formGroup]="docTempForm">
              <div class="form-field fund-name">
                <p>Subject</p>
                <mat-form-field class="w-650">
                  <input
                    matInput
                    formControlName="subject"
                    autocomplete="off"
                  />
                </mat-form-field>
              </div>
              <div class="form-field w-650">
                <p>Contents</p>
                <angular-editor
                  formControlName="contents"
                  [config]="editorConfig"
                ></angular-editor>
              </div>
            </form>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>

<div class="action-wrapper button-action-wrapper wp-100">
  <div class="content-body">
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="saveDocTemplate()"
    >
      Save
    </button>
    <button
      class="action-button secondary-button mr-1"
      type="button"
      mat-button
      disableRipple
    >
      Cancel
    </button>
  </div>
</div>

<app-custom-notifications></app-custom-notifications>
