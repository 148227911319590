import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MainListParameters } from '@app/core/models';

export interface PeriodicElements {
  userName: string;
  browser: string;
  login: string;
  logout: string;
  stayTime: string;
  status: string;
  ipAddress: string;
}

@Component({
  selector: 'app-login-activity',
  templateUrl: './login-activity.component.html',
  styleUrls: ['./login-activity.component.scss'],
})
export class LoginActivityComponent implements OnInit {
  loginActivityList: Array<PeriodicElements>;
  listParameters: MainListParameters = new MainListParameters();

  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = [
    'userName',
    'browser',
    'login',
    'logout',
    'stayTime',
    'status',
    'ipAddress',
  ];
  constructor() {
    // do nothing.
  }

  ngOnInit(): void {
    // do nothing.
  }

  getDataFromHeader(data: any): void {
    this.listParameters = data;
  }
}
