import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { NotificationTextMessage } from '@app/core/enums';
import { CommonService } from '@app/core/services';
import { GetEmailTemplate, SaveEmailTemplate } from '@app/core/store/settings';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-email-templates',
  templateUrl: './email-templates.component.html',
  styleUrls: ['./email-templates.component.scss'],
})
export class EmailTemplatesComponent implements OnInit {
  isExpandAll = false;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    showToolbar: true,
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [['bold']],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  emailForm: FormGroup;

  constructor(
    private store: Store,
    private dialog: MatDialog,
    private commonService: CommonService,
    private datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    private router: Router,
    private fb: FormBuilder
  ) {}
  ngOnInit(): void {
    this.setForm();
    this.getEmailTemplateData();
  }

  setForm() {
    this.emailForm = this.fb.group({
      subject: ['', Validators.required],
      messageBody: ['', Validators.required],
    });
  }

  getEmailTemplateData() {
    this.store.dispatch(new GetEmailTemplate()).subscribe((res) => {
      console.log(res);
      this.emailForm.patchValue({
        subject: res.setting.emailData.subject,
        messageBody: res.setting.emailData.messageBody,
      });
    });
  }

  saveEmailTemplate() {
    let emailData = {
      subject: this.emailForm.controls.subject.value,
      emailBody: this.emailForm.controls.messageBody.value,
    };

    this.store.dispatch(new SaveEmailTemplate(emailData)).subscribe((res) => {
      if (res.setting.isSucess) {
        this.commonService.onSucess('Email template saved successfully');
      } else {
        this.commonService.onFailure(NotificationTextMessage.errorMessage);
      }
    });
  }

  resetForm() {}
}
