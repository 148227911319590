<div class="d-flex year-picker-content gap-10">
  <div class="d-flex gap-10">
    <button mat-button type="button" (click)="onMinus()">
      <mat-icon>arrow_back</mat-icon>
    </button>

    <ng-select
      #select
      [(ngModel)]="selectedOption"
      (change)="onDropdownChange($event)"
      panelClass="mat-select-position"
      [disableOptionCentering]="true"
      class="w-120"
    >
      <ng-option *ngFor="let year of yearsListPair" [value]="year.key">
        {{ year.value }}
      </ng-option>
    </ng-select>

    <button mat-button type="button" (click)="onPlus()">
      <mat-icon>arrow_forward</mat-icon>
    </button>
  </div>
  <form *ngIf="selectedOption === 1" [formGroup]="rangeFormGroup">
    <mat-form-field (click)="picker.open()" appearance="standard">
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate matInput readonly #start formControlName="start" />
        <input
          matEndDate
          matInput
          readonly
          #end
          formControlName="end"
          (dateChange)="onDateChange()"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </form>
</div>
