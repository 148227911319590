export enum DetailRoutingType {
  AddBack = 1,
  Adjustment = 2,
  DiallowableExpenses = 3,
  TradingLossesSummary = 4,
  PropertyBusinessCA = 5,
  PropertyBusinessBalansingCharge = 6,
}

export enum DetailHeaderText {
  AddBack = 'Add Back',
  Adjustment = 'Adjustment',
  DiallowableExpenses = 'Disallowable Expenditure',
  TradingLossesSummary = 'Trading Losses Summary',
  PropertyBusinessCA = 'Capital Allowances',
  PropertyBusinessBalansingCharge = 'Balancing Charge',
}
