import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionType, Modules } from '@app/core/enums';

@Component({
  selector: 'app-more-grid-actions',
  templateUrl: './more-grid-actions.component.html',
  styleUrls: ['./more-grid-actions.component.scss'],
})
export class MoreGridActionsComponent implements OnInit {
  selectedRowIndex: number = -1;
  @Input()
  moduleId: number;

  @Input()
  isViewPermission: boolean;

  @Input()
  element: any;
  @Input()
  index: number;

  actionType = ActionType;

  @Output()
  readonly triggerActionType = new EventEmitter<any>();

  actions: any = [];
  constructor() {
    // do nothing.
  }

  actionClick(actionType: ActionType) {
    this.triggerActionType.emit(actionType);
  }

  ngOnInit(): void {
    switch (this.moduleId) {
      case Modules.Clients:
      case Modules.CapitalAllowancesCalculator:
      case Modules.Users:
        this.actions = this.commonActions();
        break;

      case Modules.AccountingPeriod:
        if (this.element['isDeleteble'] && this.index === 0) {
          this.actions = this.apActions1();
        } else if (this.element['isDeleteble'] && this.index !== 0) {
          this.actions = this.apActions2();
        } else if (!this.element['isDeleteble'] && this.index === 0) {
          this.actions = this.apActions3();
        } else if (!this.element['isDeleteble'] && this.index !== 0) {
          this.actions = this.apActions4();
        }
        break;

      case Modules.CT600Return:
        if (this.element['isSubmit']) {
          this.actions = [
            {
              name: 'Copy',
              actionType: ActionType.Copy,
              icon: 'content_copy',
            },
          ];
        } else {
          this.actions = [
            {
              name: 'Edit',
              actionType: ActionType.Edit,
              icon: 'edit',
            },
            {
              name: 'Delete',
              actionType: ActionType.Delete,
              icon: 'delete_outline',
            },
            {
              name: 'Copy',
              actionType: ActionType.Copy,
              icon: 'content_copy',
            },
            // {
            //   name: 'Send To Capsign',
            //   actionType: ActionType.SendToCapsign,
            //   icon: 'done_all',
            // },
            {
              name: 'Submit',
              actionType: ActionType.Submit,
              icon: 'send',
            },
          ];
        }

        break;

      case Modules.SubmissionHistory:
        this.actions = [
          {
            name: 'Submit',
            actionType: ActionType.Submit,
            icon: 'send',
          },
          {
            name: 'Export',
            actionType: ActionType.Export,
            icon: 'download',
          },
          {
            name: 'Send SMS',
            actionType: ActionType.SendToCapsign,
            icon: 'sms',
          },
        ];
        break;

      case Modules.ChargeableGainsLosses:
        this.actions = [
          {
            name: 'Edit',
            actionType: ActionType.Edit,
            icon: 'edit',
          },
          {
            name: 'Delete',
            actionType: ActionType.Delete,
            icon: 'delete_outline',
          }
        ];
        break;
    }
  }

  apActions1(): any {
    let arr1 = [
      {
        name: ActionType[ActionType.Delete],
        actionType: ActionType.Delete,
        icon: 'delete_outline',
      },
      {
        name: this.element.isLocked ? 'Unlock Period' : 'Lock Period',
        actionType: ActionType.UnlockPeriod,
        icon: this.element.isLocked ? 'lock_open' : 'lock',
      },
      {
        name: this.element.isCeased ? 'Continue' : 'Ceased',
        actionType: ActionType.Ceased,
        icon: 'gavel',
      },
      {
        name: this.element.isComparative
          ? 'Undo Comparative'
          : 'Make Comparative',
        actionType: ActionType.MarkComparative,
        icon: 'compare_arrows',
      },
    ];
    return arr1;
  }
  apActions2(): any {
    let arr2 = [
      {
        name: ActionType[ActionType.Delete],
        actionType: ActionType.Delete,
        icon: 'delete_outline',
      },
      {
        name: this.element.isLocked ? 'Unlock Period' : 'Lock Period',
        actionType: ActionType.UnlockPeriod,
        icon: this.element.isLocked ? 'lock_open' : 'lock',
      },

      {
        name: this.element.isComparative
          ? 'Undo Comparative'
          : 'Make Comparative',
        actionType: ActionType.MarkComparative,
        icon: 'compare_arrows',
      },
    ];
    return arr2;
  }
  apActions3(): any {
    let arr3 = [
      {
        name: this.element.isLocked ? 'Unlock Period' : 'Lock Period',
        actionType: ActionType.UnlockPeriod,
        icon: this.element.isLocked ? 'lock_open' : 'lock',
      },
      {
        name: this.element.isCeased ? 'Continue' : 'Ceased',
        actionType: ActionType.Ceased,
        icon: 'gavel',
      },
      {
        name: this.element.isComparative
          ? 'Undo Comparative'
          : 'Make Comparative',
        actionType: ActionType.MarkComparative,
        icon: 'compare_arrows',
      },
    ];
    return arr3;
  }
  apActions4(): any {
    let arr4 = [
      {
        name: this.element.isLocked ? 'Unlock Period' : 'Lock Period',
        actionType: ActionType.UnlockPeriod,
        icon: this.element.isLocked ? 'lock_open' : 'lock',
      },

      {
        name: this.element.isComparative
          ? 'Undo Comparative'
          : 'Make Comparative',
        actionType: ActionType.MarkComparative,
        icon: 'compare_arrows',
      },
    ];
    return arr4;
  }

  onButtonClick(i): void {
    this.selectedRowIndex = i;
  }

  allowCloseOnClickOut(): void {
    this.selectedRowIndex = -1;
  }

  commonActions(): Array<any> {
    let ar: Array<any>;
    ar = [
      {
        name: 'Edit',
        actionType: ActionType.Edit,
        icon: 'edit',
      },
      {
        name: 'Delete',
        actionType: ActionType.Delete,
        icon: 'delete_outline',
      },
    ];
    return ar;
  }
}
