<div class="content-body content-body-scroll">
  <div class="content-header mb-1">
    <div class="content-header-input d-flex justify-space-between">
      <div class="d-flex align-items-center gap-20">
        <p class="text-primary font-size-20 fw-bold m-0">General Dashboard</p>
      </div>
    </div>
  </div>

  <div class="chart-wrapper d-flex gap-20 mt-2">
    <div class="card">
      <div
        class="d-flex align-items-center justify-space-between hr-bottom pb-1 mb-2"
      >
        <h3 class="card-title card-title-without-dropdown">
          Return(s) due in 30 days
        </h3>
        <div class="d-flex gap-20">
          <mat-form-field appearance="fill" class="w-150">
            <mat-select
              panelClass="myPanelClass"
              [disableOptionCentering]="true"
              placeholder="This day"
            >
              <mat-option value="1">This day</mat-option>
              <mat-option value="2">30 days</mat-option>
              <mat-option value="3">60 days</mat-option>
              <mat-option value="4">90 days</mat-option>
              <mat-option value="5">180 days</mat-option>
              <mat-option value="6">360 days</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <app-dashboard-return-days> </app-dashboard-return-days>
    </div>
    <div class="card">
      <div
        class="d-flex align-items-center justify-space-between hr-bottom pb-1 mb-2"
      >
        <h3 class="card-title card-title-without-dropdown">
          Monthly submitted return(s)
        </h3>
        <div class="d-flex gap-20">
          <mat-form-field appearance="fill" class="w-150">
            <mat-select
              panelClass="myPanelClass"
              [disableOptionCentering]="true"
              placeholder="2024"
            >
              <mat-option value="1">2024</mat-option>
              <mat-option value="2">2023</mat-option>
              <mat-option value="3">2022</mat-option>
              <mat-option value="4">2021</mat-option>
              <mat-option value="5">2020</mat-option>
              <mat-option value="6">2019</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <app-dashboard-monthly-return> </app-dashboard-monthly-return>
    </div>
  </div>
</div>
