import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AttachmentsData,
  CMPFormData,
  TshqRequestOptions,
} from '@app/core/models';

import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AttachmentsService {
  constructor(private http: HttpClient) {}

  updateCMPFormData(cpmValues: CMPFormData): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}CT600Form/SaveAccounts`,
      JSON.stringify(cpmValues),
      headers
    );
  }

  getAccountsList(): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}CT600Form/getAttachAccountsList`
    );
  }
  getAccountsCMPValues(returnFormId: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}CT600Form/GetAccountsValues/${returnFormId}`
    );
  }

  fileUpload(attachments: AttachmentsData): Observable<any> {
    const formData = new FormData();

    formData.append('ReturnFormId', attachments.returnFormId.toString());
    formData.append('ClientId', attachments.clientId.toString());
    formData.append('AttachmentType', attachments.attachmentType.toString());
    formData.append('Description', attachments.description.toString());
    formData.append('FormFile', attachments.FormFile);

    return this.http.post<any>(
      `${environment.apiVersionUrl}CT600Form/saveFileAttachment`,
      formData
    );
  }

  getAttachments(returnFormId: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}CT600Form/getFileAttachment/${returnFormId}`
    );
  }

  downloadAttachments(returnFormId: Guid, recordId: Guid): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    let queryParams: any = {};
    return this.postExportRequest(
      `${environment.apiVersionUrl}CT600Form/downloadFileAttachment?returnFormId=${returnFormId}&fileAttchmentId=${recordId}`,
      JSON.stringify(queryParams),
      headers
    ).pipe(
      switchMap((response: any) => {
        this.download(response);

        return of(true);
      })
    );
  }

  download(response: HttpResponse<Blob>): void {
    const fileName = response.headers
      .get('Content-Disposition')
      ?.split(';')
      .map((x) => (x ?? '').trimStart().split('='))
      .find((x) => x[0] === 'filename')
      ?.pop();

    const a = document.createElement('a');
    let navigator: any;
    navigator = window.navigator;

    if (navigator && navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(response, fileName);
    } else {
      a.href = URL.createObjectURL(response.body || new Blob());
      a.download = fileName ?? '';
      a.click();
    }
  }

  postExportRequest(
    endpoint: string,
    params?: any | null,
    options?: TshqRequestOptions
  ): Observable<HttpResponse<Blob>> {
    return this.http.post(endpoint, params, {
      ...options,
      responseType: 'blob',
      observe: 'response',
    });
  }

  deleteAttachment(returnFormId: Guid, recordId: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}CT600Form/DeleteFileAttachment/${returnFormId}/${recordId}`
    );
  }
}
