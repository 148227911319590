import { Guid } from 'guid-typescript';

export class PropertyBusinessCalculationModel {
  netPropertyIncome: number;
  taxableIncome: number;
  taxableLoss: number;
  adjustments: number;
  diallowableExpenditure: number;
  balancingCharge: number;
  capitalAllowance: number;
  returnFormId: Guid;
}
