import { Guid } from 'guid-typescript';

export class FilmsEnhanceExpenditure {
  randDEnahanceExps: RandDEnahanceExp[];
  randDCreativeExps: RandDCreativeExp[];
  randDExpCredits: RandDExpCredit[];
}

export class RandDEnahanceExp {
  returnFormId: Guid;

  qualRandDExp: number;

  reliefClaimed: number;
}

export class RandDCreativeExp {
  returnFormId: Guid;
  typeofClaim?: number;
  qualCreativeExp: number;
  reliefClaimPerc?: number;
  reliefClaimed: number;
  availAmountSurrender: number;
  surrenderCreativeTax: number;
  creativeTaxPerc: number;
  creativeTaxClaimed: number;
}

export class RandDExpCredit {
  returnFormId: Guid;
  typeofClaim?: number;
  randDExpCreditItems: Array<RandDExpCreditItem>;
  deleteIds?: string;
}
export class RandDExpCreditItem {
  id: number;
  typeofExp: number;
  description: string;
  claimPeriod: number;
  qualExp: number;
  rate: number;
  rdecClaim: number;
  isDeleted?: boolean;
}

export class FilmsEnhanceExpenditureData {
  returnFormId: Guid;
  randDExpTypes: number;
  randDExpItems: Array<RandDExpCreditItemForm>;
  deleteIds: string;
}

export class RandDExpCreditItemForm {
  id: number;
  typeofExp: number;
  description: string;
  expDate: Date | null;
  amountExp: number;
  rate: number;
  reliefClaimed: number;
}

export class RandDExpCreditItemFormList {
  id: number;
  dateOfExp: string;
  description: string;
  expAmount: number;
  expenditureType: number;
  rate: number;
  reliefClaimed: number;
}
