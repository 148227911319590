<div class="sidenav-body">
  <mat-tab-group mat-align-tabs="start" disableRipple animationDuration="0ms">
    <mat-tab *ngFor="let value of list" [label]="value.name">
      <ng-template mat-tab-label>
        <span>{{ value.name }}</span>
      </ng-template>
      <div>
        <span [innerHTML]="value.value"> </span>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
