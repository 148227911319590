import { Injectable } from '@angular/core';
import { PermissionType } from '@app/core/enums';
import { MenuModel, ProfileInfoModel } from '@app/core/models';
import { MenuService } from '@app/core/services/menu/menu.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GetMenus, GetProfileInfo, SetSubMenuData } from './menu.action';

export class MenuStateInfo {
  menus?: Array<MenuModel>;
  moduleId?: number;
  profileInfo?: ProfileInfoModel;
  userName?: string;
  isViewPermission: boolean;
  subMenus?: Array<MenuModel>;
  subMenuChildren?: Array<MenuModel>;
  selectedMenu?: MenuModel;
}

@State<MenuStateInfo>({
  name: 'menu',
  defaults: {
    moduleId: 0,
    userName: '',
    isViewPermission: false,
  },
})
@Injectable()
export class MenuState {
  constructor(private menuService: MenuService) {}

  @Selector()
  static getMenu(state: MenuStateInfo): Array<MenuModel> {
    return state.menus ?? [];
  }

  @Selector()
  static getSubMenus(state: MenuStateInfo) {
    return state.subMenus ?? [];
  }

  @Selector()
  static getSelectedMenuPermission(state: MenuStateInfo) {
    return state.isViewPermission ?? false;
  }

  @Selector()
  static userName(state: MenuStateInfo): string | undefined {
    return state.userName;
  }

  @Selector()
  static moduleId(state: MenuStateInfo): number | undefined {
    return state.moduleId;
  }

  @Action(GetMenus)
  getMenu(
    { getState, setState }: StateContext<MenuStateInfo>,
    action: GetMenus
  ): Observable<any> {
    return this.menuService.getMenus().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          menus: res,
        });
      })
    );
  }

  @Action(SetSubMenuData)
  SetSubMenuData(
    { patchState }: StateContext<MenuStateInfo>,
    action: SetSubMenuData
  ) {
    patchState({
      subMenus: action.subMenu,
      subMenuChildren: action.subMenu[0]?.subMenu ?? [],
      selectedMenu: action.selectedMenu,
      isViewPermission:
        action.selectedMenu?.permissionId === PermissionType.View,
    });
  }

  @Action(GetProfileInfo)
  getProfileInfo({ patchState }: StateContext<MenuStateInfo>): Observable<any> {
    return this.menuService.getProfileInfo().pipe(
      tap((res) => {
        patchState({
          profileInfo: res,
          userName: res.name,
        });
      })
    );
  }
}
