import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, tap } from 'rxjs';

import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { Guid } from 'guid-typescript';
import { ConfirmationBoxComponent } from '../confirmation-box/confirmation-box.component';
import { FilteredStatus } from '@app/core/enums/filtered-status';
import {
  CommonModel,
  ConfirmationType,
  CT600Status,
  ExportFormat,
  HeaderTextForSideList,
  Modules,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
  PaymentStatus,
} from '@app/core/enums';
import {
  CommonService,
  HighlightRow,
  NotificationService,
} from '@app/core/services';
import {
  Copy,
  Export,
  GetSideList,
} from '@app/core/store/common/common.action';
import { CommonState, MenuState } from '@app/core/store';
import { GlobalComponent, MainListParameters } from '@app/core/models';
import { SideListActionsComponent } from '../side-list-actions/side-list-actions.component';

@Component({
  selector: 'app-side-list',
  templateUrl: './side-list.component.html',
  styleUrls: ['./side-list.component.scss'],
})
export class SideListComponent implements OnInit {
  @Input()
  getModuleId: number;
  @Input()
  isFrom: number;
  @Input()
  isViewPermission: boolean;

  dataSourceSelectedLength = 0;
  selectedId = Guid.EMPTY as unknown as Guid;
  closeMenu = false;
  ids: Array<Guid>;
  dataSource: any;
  isAllSelected = false;
  headerText: string;
  showSearchBar = false;
  isAddMode = true;
  isRowHighlighted = false;
  displayedColumns: string[] = ['name'];

  @Input()
  reloadSideList: Observable<any>;

  @ViewChild('searchText') searchField: ElementRef;
  listParameters: MainListParameters = new MainListParameters();

  @ViewChild(SideListActionsComponent, { static: true })
  sideListActionsDetails;

  @Output()
  readonly toggleSideList = new EventEmitter<boolean>();

  @Output()
  readonly showSideListAction = new EventEmitter<any>();

  triggerNumberOfRecords: Subject<any> = new Subject<any>();

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  store: Store;
  notify: NotificationService;
  dialog: MatDialog;
  highlightRow: HighlightRow;
  commonService: CommonService;
  activatedRoute: ActivatedRoute;
  spinner: NgxSpinnerService;
  datepipe: DatePipe;

  constructor(
    private injector: Injector,
    private renderer: Renderer2,
    private globalComponent: GlobalComponent
  ) {
    this.dialog = injector.get<MatDialog>(MatDialog);
    this.store = injector.get<Store>(Store);
    this.notify = injector.get<NotificationService>(NotificationService);
    this.highlightRow = injector.get<HighlightRow>(HighlightRow);
    this.commonService = injector.get<CommonService>(CommonService);
    this.activatedRoute = injector.get<ActivatedRoute>(ActivatedRoute);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.datepipe = injector.get<DatePipe>(DatePipe);
  }

  ngOnInit(): void {
    this.isAddMode = true;

    this.activatedRoute.params.subscribe((params) => {
      if (params && params['id']) {
        this.selectedId = atob(params['id']!) as unknown as Guid;
      }
    });

    this.getParamters();

    this.reloadSideList?.subscribe(() => {
      const queryParams = {
        pageNumber: this.listParameters.pageNumber,
        pageSize: this.listParameters.pageSize,
        filter: FilteredStatus.All,
        sortOrder: false,
        sortBy: this.highlightRow.sideListHighlighted.sortBy,
        search: this.listParameters.search,
        isRowHighlighted: this.highlightRow.sideListHighlighted.isHighlighted,
      };
      this.selectedId = this.highlightRow.sideListHighlighted.isHighlighted
        ? (Guid.EMPTY as unknown as Guid)
        : this.selectedId;
      this.bindList(queryParams);
    });

    this.bindList(this.getParamters());
  }

  getParamters(): any {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: FilteredStatus.All,
      sortOrder: this.listParameters.sortOrder,
      sortBy: this.listParameters.sortBy,
      search: this.listParameters.search,
      isRowHighlighted: false,
    };

    return queryParams;
  }

  changeHeaderText(): void {
    switch (this.getModuleId) {
      case Modules.Clients:
        this.headerText = HeaderTextForSideList.Clients;
        break;
      case Modules.CT600Return:
        this.headerText = HeaderTextForSideList.CT600Return;
        break;

      case Modules.AccountingPeriod:
        this.headerText = HeaderTextForSideList.AccountingPeriod;
        break;

      case Modules.Contact:
        this.headerText = HeaderTextForSideList.Contact;
        break;
      case Modules.Users:
        this.headerText = HeaderTextForSideList.Users;
        break;
    }
  }

  bindList(queryParams: any): void {
    this.changeHeaderText();

    let commonModel: CommonModel = {
      clientId: this.globalComponent.getClientId(),
      moduleId: this.getModuleId,
    };

    this.store
      .dispatch(new GetSideList(queryParams, commonModel))
      .pipe(
        tap((res) => {
          this.isRowHighlighted = queryParams.isRowHighlighted ?? false;
          this.dataSource = this.store.selectSnapshot(CommonState.getSideList);
        })
      )
      .subscribe();
  }

  selectAll(event: any): void {
    this.dataSource.forEach((x) => (x.isSelected = event.checked));
    this.isActionVisible(false);
  }

  onReloadSideList(): void {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: FilteredStatus.All,
      sortOrder: this.listParameters.sortOrder,
      sortBy: this.listParameters.sortBy,
      search: this.listParameters.search,
      isRowHighlighted: true,
    };

    this.cancelSelectionClick();
    this.bindList(queryParams);
  }

  editClick(id: any): void {
    if (this.getModuleId !== Modules.AccountingPeriod) {
      this.isAddMode = false;
      this.selectedId = id;
      this.isRowHighlighted = false;
      this.spinner.show();
      this.commonService.onEditRouting(this.getModuleId, id, true, this.isFrom);
    }
  }

  deleteClick(): void {
    this.selectedIds();

    if (this.ids.length > 0) {
      this.dialog
        .open(ConfirmationBoxComponent, {
          data: {
            ids: this.ids,
            type: ConfirmationType.Delete,
            moduleId: this.getModuleId,
            totalNumberOfRecordSelected: this.ids.length,
            headerText: NotificationHeader.deleteConfirmation,
            detailText: NotificationDetails.deleteAllDetailText,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.bindList(this.getParamters());
            this.cancelSelectionClick();
          } else {
            this.cancelSelectionClick();
          }
        });
    }
  }

  selectedIds(): void {
    this.ids = [];
    this.dataSource.forEach((x) =>
      x.isSelected ? this.ids.push(x.universalId ?? 0) : ''
    );
  }

  toggleSearchBar(): void {
    this.showSearchBar = !this.showSearchBar;

    if (this.showSearchBar) {
      setTimeout(() => {
        this.searchField.nativeElement.focus();
      }, 0);
    }
  }

  copyClick(): void {
    this.spinner.show();
    this.selectedIds();

    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: FilteredStatus.All,
      sortOrder: false,
      sortBy: this.listParameters.sortBy,
      search: this.listParameters.search,
      isRowHighlighted: false,
    };

    this.store
      .dispatch(new Copy(this.getModuleId, this.ids))
      .subscribe((res) => {
        if (res.common.isCopied) {
          this.notify.success(
            NotificationHeader.success,
            NotificationTextMessage.recordCopySuccessfully
          );
          this.bindList(queryParams);
          this.cancelSelectionClick();
        }
      });
  }

  getExportParamter(format: number, isPrint: boolean): any {
    let queryParams;
    switch (this.getModuleId) {
      case Modules.Clients:
        queryParams = {
          statusType: CT600Status.All,
          payStatus: PaymentStatus.All,
          dueDays: this.sideListActionsDetails.dueDays,
          pageNumber: this.listParameters.pageNumber,
          pageSize: this.listParameters.pageSize,
          searchText: this.listParameters.search,
          sortBy: this.listParameters.sortBy,
          sortOrder: this.listParameters.sortOrder,
          format: format,
          isPrint: isPrint,
          universalId: this.ids,
        };
        break;

      case Modules.AccountingPeriod:
      case Modules.CT600Return:
        queryParams = {
          pageNumber: this.listParameters.pageNumber,
          pageSize: this.listParameters.pageSize,
          search: this.listParameters.search,
          sortBy: this.listParameters.sortBy,
          sortOrder: this.listParameters.sortOrder,
          clientId: this.globalComponent.getClientId(),
          format: format,
          isPrint: isPrint,
          universalId: this.ids,
        };
        break;
      case Modules.Users:
        queryParams = {
          pageNumber: this.listParameters.pageNumber,
          pageSize: this.listParameters.pageSize,
          search: this.listParameters.search,
          sortBy: this.listParameters.sortBy,
          sortOrder: this.listParameters.sortOrder,
          status: 0,
          format: format,
          isPrint: isPrint,
          universalId: this.ids,
        };
        break;
    }

    return queryParams;
  }

  exportClick(format: number, isPrint: boolean): void {
    this.spinner.show();
    this.selectedIds();

    this.store
      .dispatch(
        new Export(this.getExportParamter(format, isPrint), this.getModuleId)
      )
      .subscribe((res) => {
        this.dataSource = res.common.sideListModel;
      });
  }

  archiveAndRestoreClick(isArchive: boolean): void {
    this.selectedIds();

    if (this.ids.length > 0) {
      this.dialog
        .open(ConfirmationBoxComponent, {
          data: {
            ids: this.ids,
            isArchive,
            type: ConfirmationType.Archive,
            moduleId: this.getModuleId,
            totalNumberOfRecordSelected: this.ids.length,
            headerText: isArchive
              ? NotificationHeader.archiveConfirmation
              : NotificationHeader.restoreConfirmation,
            detailText: isArchive
              ? NotificationDetails.archiveDetailText
              : NotificationDetails.restoreDetailText,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.cancelSelectionClick();
          }
        });
    }
  }

  onSearch(event: any): void {
    this.listParameters.search = event.target.value;
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: FilteredStatus.All,
      sortOrder: this.listParameters.sortOrder,
      sortBy: this.listParameters.sortBy,
      search: this.listParameters.search,
      isRowHighlighted: false,
    };

    this.bindList(queryParams);
  }

  onCancel(): void {
    this.listParameters.search = '';
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: FilteredStatus.All,
      sortOrder: this.listParameters.sortOrder,
      sortBy: this.listParameters.sortBy,
      search: this.listParameters.search,
      isRowHighlighted: false,
    };
    this.bindList(queryParams);
  }

  onTableScroll(event): void {
    let isLastPage: boolean | undefined = false;
    isLastPage = this.store.selectSnapshot(CommonState.isLastPage);

    if (isLastPage === false) {
      if (
        event.target.offsetHeight + event.target.scrollTop >=
        event.target.scrollHeight
      ) {
        const pageIndex = this.listParameters.pageNumber + 1;
        this.pageChanged(pageIndex);
      }
    }
  }

  pageChanged(pageIndex: number): void {
    this.listParameters.pageNumber = pageIndex;
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: FilteredStatus.All,
      sortOrder: this.listParameters.sortOrder,
      sortBy: this.listParameters.sortBy,
      search: this.listParameters.search,
      isRowHighlighted: false,
    };

    this.bindList(queryParams);
  }

  onCheckBoxSelected(): void {
    this.isAllSelected = this.dataSource.every(
      (item: any) => item.isSelected === true
    );
    this.isActionVisible(false);
  }

  cancelSelectionClick(): void {
    this.deSelectAll();
    this.isActionVisible(true);
  }

  deSelectAll(): void {
    this.dataSource.forEach((x) => (x.isSelected = false));
    this.triggerNumberOfRecords.next(0);
    this.isAllSelected = false;
  }

  printClick(): void {
    this.selectedIds();
    this.exportClick(ExportFormat.PDF, true);
  }

  isActionVisible(reloadList: boolean): void {
    this.dataSourceSelectedLength = 0;
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: FilteredStatus.All,
      sortOrder: this.listParameters.sortOrder,
      sortBy: this.listParameters.sortBy,
      search: this.listParameters.search,
      isRowHighlighted: false,
    };
    this.dataSource.forEach((x) =>
      x.isSelected ? +this.dataSourceSelectedLength++ : ''
    );
    this.showSideListAction.emit(
      this.dataSourceSelectedLength > 0 ? true : false
    );
    this.triggerNumberOfRecords.next(this.dataSourceSelectedLength);
    if (reloadList || this.dataSourceSelectedLength === 0) {
      this.bindList(queryParams);
    }
  }

  isActive(element: any): boolean {
    return this.selectedId === element.universalId;
  }

  menuToggle(): void {
    this.closeMenu = !this.closeMenu;

    this.toggleSideList.emit(this.closeMenu);
  }
  addClick(): void {
    if (this.getModuleId === Modules.Clients) {
      this.commonService.onAddRouting(Modules.Clients);
    } else if (this.getModuleId === Modules.Users) {
      this.commonService.onAddRouting(Modules.Users);
    } else if (this.getModuleId === Modules.CT600Return) {
      this.commonService.onAddRouting(Modules.CT600Return);
    } else if (this.getModuleId === Modules.AccountingPeriod) {
      this.commonService.onAddRouting(Modules.AccountingPeriod);
    } else if (this.getModuleId === Modules.Contact) {
      this.commonService.onAddRouting(Modules.Contact);
    }
  }
}
