import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  SideListModel,
  PropertyBusinessCalculationModel,
  TradingProfitTagsModel,
} from '@app/core/models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PropertyBusinessCalculatorService {
  constructor(private http: HttpClient) {}

  createPropertyBCalculator(
    propertyBCalculationData: PropertyBusinessCalculationModel
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}TradingProfits/savePropertyBusinessTotal`,
      JSON.stringify(propertyBCalculationData),
      headers
    );
  }

  getDataForPropertyBCalculator(returnFormId: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}TradingProfits/getPropertyBusinessData/${returnFormId}`
    );
  }

  // getTradingProfitsTagsList(): Observable<SideListModel[]> {
  //   return this.http.get<SideListModel[]>(
  //     `${environment.apiVersionUrl}TradingProfits/getTradingProfitsTagsList`
  //   );
  // }
}
