import { Injectable } from '@angular/core';
import {
  FilmsEnhanceExpenditure,
  RandDCreativeExp,
  RandDExpCredit,
} from '@app/core/models';
import { RandDEnahanceExp } from '@app/core/models/tasks/quick-entry/films-enhance-expenditure';
import { FilmsEnhanceExpenditureService } from '@app/core/services';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
import {
  GetFilmsEnhanceExpenditureData,
  GetRandDExpenditureData,
  GetTypeList,
  SaveCreativeEnhancedExpenditure,
  SaveFilmsEnhanceExpenditure,
  SaveResearchDevelopmentExpCredit,
  SaveSMERandDExpenditure,
  SaveSMERDenhancedExpenditure,
  SaveSMESubContractor,
} from './films-enhance-expenditure.action';

export class FilmsEnhanceExpenditureStateInfo {
  id?: number;
  getFilmRandDEnahanceExpData?: FilmsEnhanceExpenditure;
  filmsRandDEnahanceExpData?: RandDEnahanceExp;
  creativeEnhancedExpenditureData?: RandDCreativeExp;
  researchDevelopmentExpCreditData?: RandDExpCredit;
  message: string;
  isSuccess: boolean;
  RandDExpenditureData: any;
  typeList: Array<any>;
}

@State<FilmsEnhanceExpenditureStateInfo>({
  name: 'filmsEnhanceExpenditure',
  defaults: {
    id: 0,
    message: '',
    isSuccess: false,
    RandDExpenditureData: [],
    typeList: [],
  },
})
@Injectable()
export class FilmsEnhanceExpenditureState {
  constructor(
    private filmsEnhanceExpenditureService: FilmsEnhanceExpenditureService
  ) {}

  @Action(SaveCreativeEnhancedExpenditure)
  saveCreativeEnhancedExpenditure(
    { patchState }: StateContext<FilmsEnhanceExpenditureStateInfo>,
    action: SaveCreativeEnhancedExpenditure
  ) {
    return this.filmsEnhanceExpenditureService
      .saveCreativeEnhancedExpenditure(action.creativeEnhancedExpenditureData)
      .pipe(
        tap((res) => {
          patchState({
            message: res.message,
          });
        })
      );
  }

  @Action(SaveFilmsEnhanceExpenditure)
  saveFilmsEnhanceExpenditure(
    { patchState }: StateContext<FilmsEnhanceExpenditureStateInfo>,
    action: SaveFilmsEnhanceExpenditure
  ) {
    return this.filmsEnhanceExpenditureService
      .saveFilmsEnhanceExpenditure(action.filmsRandDEnahanceExpData)
      .pipe(
        tap((res) => {
          patchState({
            message: res.message,
          });
        })
      );
  }

  @Action(SaveResearchDevelopmentExpCredit)
  saveResearchDevelopmentExpCredit(
    { patchState }: StateContext<FilmsEnhanceExpenditureStateInfo>,
    action: SaveResearchDevelopmentExpCredit
  ) {
    return this.filmsEnhanceExpenditureService
      .saveResearchDevelopmentExpCredit(action.researchDevelopmentExpCreditData)
      .pipe(
        tap((res) => {
          patchState({
            message: res.message,
          });
        })
      );
  }

  @Action(GetFilmsEnhanceExpenditureData)
  getFilmsEnhanceExpenditureData(
    { patchState }: StateContext<FilmsEnhanceExpenditureStateInfo>,
    action: GetFilmsEnhanceExpenditureData
  ) {
    return this.filmsEnhanceExpenditureService
      .getFilmsEnhanceExpenditureData(action.returnFormId)
      .pipe(
        tap((res) => {
          patchState({
            getFilmRandDEnahanceExpData: res,
          });
        })
      );
  }

  @Action(SaveSMERDenhancedExpenditure)
  saveSMErandExpenditure(
    { patchState }: StateContext<FilmsEnhanceExpenditureStateInfo>,
    action: SaveSMERDenhancedExpenditure
  ) {
    return this.filmsEnhanceExpenditureService
      .saveSMErandExpenditure(action.filmssmeRandDEnahanceExpData)
      .pipe(
        tap((res) => {
          patchState({
            isSuccess: res,
          });
        })
      );
  }

  @Action(GetRandDExpenditureData)
  getRandDEnhanceExpenditureData(
    { patchState }: StateContext<FilmsEnhanceExpenditureStateInfo>,
    action: GetRandDExpenditureData
  ) {
    return this.filmsEnhanceExpenditureService
      .getRandDEnhanceExpenditureData(action.returnFormId, action.typeId)
      .pipe(
        tap((res) => {
          patchState({
            RandDExpenditureData: res.randDExpItems,
          });
        })
      );
  }

  @Action(SaveSMERandDExpenditure)
  saveSMERandDExpenditureDetails(
    { patchState }: StateContext<FilmsEnhanceExpenditureStateInfo>,
    action: SaveSMERandDExpenditure
  ) {
    return this.filmsEnhanceExpenditureService
      .saveSMErandExpenditureDetails(action.smeRandDData)
      .pipe(
        tap((res) => {
          patchState({
            message: res.message,
          });
        })
      );
  }

  @Action(SaveSMESubContractor)
  saveSMESubcontractorDetails(
    { patchState }: StateContext<FilmsEnhanceExpenditureStateInfo>,
    action: SaveSMESubContractor
  ) {
    return this.filmsEnhanceExpenditureService
      .saveSubcontractorDetails(action.smeRandDData)
      .pipe(
        tap((res) => {
          patchState({
            message: res.message,
          });
        })
      );
  }

  @Action(GetTypeList)
  getDueDaysList({
    patchState,
  }: StateContext<FilmsEnhanceExpenditureStateInfo>) {
    return this.filmsEnhanceExpenditureService.getTypeList().pipe(
      tap((res) => {
        patchState({
          typeList: res,
        });
      })
    );
  }
}
