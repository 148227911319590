import { SideListModel } from '@app/core/models';
import { Guid } from 'guid-typescript';

export class CaSummaryModel {
  cAcatItems: SideListModel[];
  cASummaryItems: CASummaryItem[];
}

export class CASummaryItem {
  isRowEdit: boolean;
  title: string;
  entries: CASummaryCatItem[];
}

export class CASummaryCatItem {
  id: number;
  value: string;
  isEditable: boolean;
}

export class CalSummaryModel {
  netBalWDA: string;
  wDA6: string;
  wDACFWD: string;
  TotalFYAWDA: string;
}

export class CalCapitalAllowanceModel {
  returnFormId: Guid;
  value: number;
  assetCatId: number;
}

export class AssetSummaryModel {
  assetCatId: number;
  balAllowance: number;
  balCharge: number;
  returnFormId: Guid;
  transferFromSla: number;
  transSRFYAtoSR: number;
}
