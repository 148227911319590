import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TshqRequestOptions } from '@app/core/models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  constructor(private http: HttpClient) {}

  getComputationData(returnFormId: Guid, clientId: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Computation/getReturnComputation?returnFormId=${returnFormId}&clientId=${clientId}`
    );
  }

  getAPReportDetails(returnDetailId: Guid, returnFormId:Guid, clientId: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}AccountsReport/getReturnAccountsReport?returnDetailId=${returnDetailId}&returnFormId=${returnFormId}&clientId=${clientId}`
    );
  }

  detachAPReportDetails(returnFormId:Guid, clientId: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}AccountsReport/detachAccountsReport?returnFormId=${returnFormId}&clientId=${clientId}`
    );
  }

  downloadComputation(returnFormId: Guid, clientId: Guid): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    let queryParams: any = {
      ReturnFormId: returnFormId,
      ClientId: clientId,
    };
    return this.postExportRequest(
      `${environment.apiVersionUrl}Computation/downloadComputation`,
      JSON.stringify(queryParams),
      headers
    ).pipe(
      switchMap((response: any) => {
        this.download(response);

        return of(true);
      })
    );
  }

  downloadHTMLComputation(htmlcontent: string, filename:string, clientId: Guid): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    let queryParams: any = {
      HTMLContent: htmlcontent,
      ClientId:clientId,
      FileName:filename
    };
    return this.postExportRequest(
      `${environment.apiVersionUrl}Computation/downloadHTMLComputation`,
      JSON.stringify(queryParams),
      headers
    ).pipe(
      switchMap((response: any) => {
        this.download(response);

        return of(true);
      })
    );
  }

  download(response: HttpResponse<Blob>): void {
    const fileName = response.headers
      .get('Content-Disposition')
      ?.split(';')
      .map((x) => (x ?? '').trimStart().split('='))
      .find((x) => x[0] === 'filename')
      ?.pop();

    const a = document.createElement('a');
    let navigator: any;
    navigator = window.navigator;

    if (navigator && navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(response, fileName);
    } else {
      a.href = URL.createObjectURL(response.body || new Blob());
      a.download = fileName ?? '';
      a.click();
    }
  }

  postExportRequest(
    endpoint: string,
    params?: any | null,
    options?: TshqRequestOptions
  ): Observable<HttpResponse<Blob>> {
    return this.http.post(endpoint, params, {
      ...options,
      responseType: 'blob',
      observe: 'response',
    });
  }
}
