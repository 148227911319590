<div class="content loginActivity-list">
  <div class="content-body">
    <app-login-activity-header
      (triggerDataFromHeader)="getDataFromHeader($event)"
      [listParameters]="listParameters"
    >
    </app-login-activity-header>

    <div class="mat-table-wrapper">
      <table
        aria-describedby="loginActivity-list"
        matSort
        mat-table
        [dataSource]="loginActivityList"
      >
        <ng-container matColumnDef="userName">
          <th mat-header-cell *matHeaderCellDef class="w-30">
            <div class="d-flex align-items-center">
              <span mat-sort-header> User Name </span>
            </div>
          </th>
        </ng-container>
        <ng-container matColumnDef="browser">
          <th mat-header-cell *matHeaderCellDef class="w-40">
            <span mat-sort-header>Browser</span>
          </th>
        </ng-container>
        <ng-container matColumnDef="login">
          <th mat-header-cell *matHeaderCellDef class="w-40">
            <span mat-sort-header>Login</span>
          </th>
          <td mat-cell *matCellDef="let element" class="widthemail">
            {{ element.login }}
          </td>
        </ng-container>
        <ng-container matColumnDef="logout">
          <th mat-header-cell *matHeaderCellDef class="w-40">
            <span mat-sort-header>Logout</span>
          </th>

          <td mat-cell *matCellDef="let element" class="widthemail">
            {{ element.logout }}
          </td>
        </ng-container>
        <ng-container matColumnDef="stayTime">
          <th mat-header-cell *matHeaderCellDef class="w-30">
            <span mat-sort-header>Stay Time</span>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.stayTime }}
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef class="w-30">
            <span mat-sort-header>Status</span>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.status }}
          </td>
        </ng-container>

        <ng-container matColumnDef="ipAddress">
          <th mat-header-cell *matHeaderCellDef class="w-30">
            <span mat-sort-header>IP Address</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex align-items-center justify-space-between">
              {{ element.ipAddress }}
            </div>
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
</div>
