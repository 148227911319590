import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HomeRoutingModule } from './home-routing.module';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { CustomHttpInterceptor } from '@app/core/interceptors';
import { ShortNamePipe } from '@app/core/pipes/short-name.pipes';
import { SubscriptionService, TicketService } from '@app/core/services';
import { MenuService } from '@app/core/services/menu/menu.service';
import { AngularMaterialModule } from '@app/core/sharedmodules';
import { ComponentsModule } from '@app/core/sharedmodules/components/components.module';
import {
  AccoutingPeriodState,
  Attachmentstate,
  CACalculatorState,
  ClientState,
  CommonState,
  Ct600ReturnState,
  FilmsEnhanceExpenditureState,
  ImportBfwdState,
  LossesNTRState,
  MainFormState,
  MenuState,
  PropertyBCalculatorState,
  Reportsstate,
  SettingState,
  SubscriptionState,
  TicketState,
  TradingPPCalculatorState,
} from '@app/core/store';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsModule } from '@ngxs/store';
import { NgApexchartsModule } from 'ng-apexcharts';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import {
  ActivityComponent,
  LoginActivityComponent,
  LoginActivityHeaderComponent,
  UserActivityComponent,
} from './activity';
import {
  CT600GraphComponent,
  DashboardComponent,
  MonthlySubmittedReturnComponent,
  SubmissionSummaryChartComponent,
} from './dashboard';
import { DashboardMonthlyReturnComponent } from './general-dashboard/dashboard-monthly-return/dashboard-monthly-return.component';
import { DashboardReturnDaysComponent } from './general-dashboard/dashboard-return-days/dashboard-return-days.component';
import { GeneralDashboardComponent } from './general-dashboard/general-dashboard.component';
import { DocumentTemplatesComponent } from './general-settings/document-templates/document-templates.component';
import { EmailTemplatesComponent } from './general-settings/email-templates/email-templates.component';
import { TaxDocumentComponent } from './general-settings/tax-document/tax-document.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home.component';
import { ClientTicketComponent } from './ticket/client-ticket/client-ticket.component';
import { TicketDialogComponent } from './ticket/common/ticket-dialog/ticket-dialog.component';
import { SupportTicketComponent } from './ticket/support-ticket/support-ticket.component';
import { TicketComponent } from './ticket/ticket.component';

@NgModule({
  declarations: [
    HomeComponent,
    HeaderComponent,
    DashboardComponent,
    ShortNamePipe,
    MonthlySubmittedReturnComponent,
    SubmissionSummaryChartComponent,
    CT600GraphComponent,
    ActivityComponent,
    UserActivityComponent,
    LoginActivityComponent,
    LoginActivityHeaderComponent,
    TicketComponent,
    SupportTicketComponent,
    ClientTicketComponent,
    TicketDialogComponent,
    EmailTemplatesComponent,
    DocumentTemplatesComponent,
    TaxDocumentComponent,
    GeneralDashboardComponent,
    DashboardReturnDaysComponent,
    DashboardMonthlyReturnComponent,
    AccessDeniedComponent,
  ],
  providers: [
    MenuService,
    SubscriptionService,
    TicketService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    },
  ],
  schemas: [],
  imports: [
    CommonModule,
    HomeRoutingModule,
    AngularMaterialModule,
    ComponentsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxsFormPluginModule.forRoot(),
    NgxsModule.forFeature([
      CommonState,
      MenuState,
      SubscriptionState,
      TicketState,
      ClientState,
      SettingState,
      AccoutingPeriodState,
      Ct600ReturnState,
      CACalculatorState,
      TradingPPCalculatorState,
      LossesNTRState,
      FilmsEnhanceExpenditureState,
      MainFormState,
      Attachmentstate,
      Reportsstate,
      PropertyBCalculatorState,
      ImportBfwdState,
    ]),
    NgApexchartsModule,
    AngularEditorModule,
  ],
})
export class HomeModule {}
