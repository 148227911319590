import { Guid } from 'guid-typescript';

export class PropertyIncomeLosses {
  returnFormId?: Guid;
  prpIncomeArisingPrdBX190?: number;
  prpIncomeLossPrdBX805?: number;
  prpIncomeLossUtilPeriodBX250?: number;
  prpIncomeSurrenderedGroupRelief?: number;
  prpIncomeUKLossBFW?: number;
  prpIncomeUKLossCFW?: number;
  prpIncomeBFWLosses?: number;
  prpIncomeCurrentPrdLosses?: number;
}

export class PropertyIncomeCalculatorLosses {
  returnFormId?: Guid;
  incomeFrmUKLB?: number;
  lessPrpIncomeExpenses?: number;
  lessNetPrpIncomeAccounts?: number;
  annualInvestAllowances?: number;
  businessPrmRenovation?: number;
  enterprizeZones?: number;
  zeroEmissionsGoodVehicles?: number;
  otherCapitalAllownaces?: number;
  otherExpenses?: number;
  costofReplacingDomesticItems?: number;
  addBusinessprmRenovation?: number;
  addEnterprizeZones?: number;
  addZeroEmissionsGoodVehicles?: number;
  addOtherCapitalAllownaces?: number;
  taxableIncmeUKLBBX190?: number;
  taxableAdjustedlossUKLBBX805?: number;
}
