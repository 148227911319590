import { Injectable } from '@angular/core';

import { AttachmentsService } from '@app/core/services';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
import {
  DeleteFile,
  DownloadFile,
  GetAccoumtCMPData,
  GetAccoumtListData,
  GetAttachments,
  SaveAccountsCMPValues,
  SaveAttachmentsData,
} from './attachments.action';

export class AttachmentsStateInfo {
  message: string;
  accountList: Array<any>;
  isSuccess: boolean;
  accountCMPValues: Array<any>;
  attachmentsData: Array<any>;
  isExport: boolean;
}

@State<AttachmentsStateInfo>({
  name: 'attachments',
  defaults: {
    accountList: [],
    message: '',
    isSuccess: false,
    accountCMPValues: [],
    attachmentsData: [],
    isExport: false,
  },
})
@Injectable()
export class Attachmentstate {
  constructor(private attachmentsService: AttachmentsService) {}

  @Action(SaveAccountsCMPValues)
  saveAccountsCMPValues(
    { patchState }: StateContext<AttachmentsStateInfo>,
    action: SaveAccountsCMPValues
  ) {
    return this.attachmentsService
      .updateCMPFormData(action.accountsCMPData)
      .pipe(
        tap((res) => {
          patchState({
            isSuccess: res.accountsStatus,
            message: res.accountsSaveMessage,
          });
        })
      );
  }

  @Action(GetAccoumtListData)
  getAccountsListData(
    { patchState }: StateContext<AttachmentsStateInfo>,
    action: GetAccoumtListData
  ) {
    return this.attachmentsService.getAccountsList().pipe(
      tap((res) => {
        patchState({
          accountList: res,
        });
      })
    );
  }

  @Action(GetAccoumtCMPData)
  getAccountsCMPData(
    { patchState }: StateContext<AttachmentsStateInfo>,
    action: GetAccoumtCMPData
  ) {
    return this.attachmentsService
      .getAccountsCMPValues(action.retirnFormId)
      .pipe(
        tap((res) => {
          patchState({
            accountCMPValues: res,
          });
        })
      );
  }

  @Action(SaveAttachmentsData)
  saveAttachmentsForm(
    { patchState }: StateContext<AttachmentsStateInfo>,
    action: SaveAttachmentsData
  ) {
    return this.attachmentsService.fileUpload(action.attachments).pipe(
      tap((res) => {
        patchState({
          isSuccess: res.fileStatus,
          message: res.fileMessage,
        });
      })
    );
  }

  @Action(GetAttachments)
  getAttachmentsData(
    { patchState }: StateContext<AttachmentsStateInfo>,
    action: GetAttachments
  ) {
    return this.attachmentsService.getAttachments(action.retirnFormId).pipe(
      tap((res) => {
        patchState({
          attachmentsData: res.fileAttachmentRes,
        });
      })
    );
  }
  @Action(DownloadFile, { cancelUncompleted: true })
  downloadFile(
    { patchState }: StateContext<AttachmentsStateInfo>,
    action: DownloadFile
  ) {
    let isExport = false;
    return this.attachmentsService
      .downloadAttachments(action.retirnFormId, action.recordId)
      .pipe(
        tap((res) => {
          isExport = true;
          patchState({
            isExport,
          });
        })
      );
  }

  @Action(DeleteFile)
  deleteFile(
    { patchState }: StateContext<AttachmentsStateInfo>,
    action: DeleteFile
  ) {
    return this.attachmentsService
      .deleteAttachment(action.retirnFormId, action.recordId)
      .pipe(
        tap((res) => {
          patchState({
            isSuccess: res,
          });
        })
      );
  }
}
