export enum CT600Status {
  All = -1,
  Submitted = 2,
  Due = 0,
  Error = 3,
}

export enum PaymentStatus {
  All = -1,
  Paid = 1,
  Unpaid = 0,
}

export enum DueDaysEnum {
  'Due in 30 days' = 30,
  'Due in 60 days' = 60,
  'Due in 120 days' = 120,
  'Due in 180 days' = 180,
  'Due in 360 days' = 360,
  'Overdue' = -1,
}

export enum ActionName {
  'Create' = 1,
  'Update' = 2,
  'Delete' = 3,
}
