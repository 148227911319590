import { Injectable } from '@angular/core';
import { SettingService } from '@app/core/services/settings/setting.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  BusinessModel,
  BusinessTax,
  DynamicColumnModel,
  EmailTemplateModel,
  MenuItem,
  StandardPermission,
  UserSettingWeekModel,
  UserStandardPermission,
} from '../../models';
import { CommonService } from '../../services';
import {
  AddRemoveDemoData,
  GetBusinessEstimate,
  GetBusinessFeesSetting,
  GetBusinessInvoice,
  GetBusinessTax,
  GetDocTemplate,
  GetEmailTemplate,
  // GetMenus,
  GetNextCodeByModuleId,
  GetPermissions,
  GetSettingsData,
  GetSettingsTemplateData,
  GetStartWeekAndEndWeek,
  GetUserBusinessPermission,
  GetVatScheme,
  GetVatSubmitType,
  IsDuplicateExist,
  SaveBasicInfo,
  SaveBusiness,
  SaveBusinessDynamicColumn,
  SaveBusinessEstimateInfo,
  SaveBusinessFeesSettingByModuleId,
  SaveBusinessInvoiceInfo,
  SaveDocTemplate,
  SaveDynamicColumns,
  SaveDynamicColumnsData,
  SaveEmailTemplate,
  SavePreferences,
  SaveUserPermission,
  SetSubMenuChildrenData,
  UpdatePermissions,
  UpdateSettingsConfig,
  UpdateSettingsTemplate,
} from './setting.action';

export class SettingsInfo {
  settingsData: BusinessModel;
  emailData: Array<EmailTemplateModel>;
  startWeekAndEndWeek: UserSettingWeekModel;
  dynamicColumnsData: Array<DynamicColumnModel>;
  standardPermission?: Array<StandardPermission>;
  isUpdated?: boolean;
  isBasicInfoSaved?: boolean;
  isPreferenceSaved?: boolean;
  isInvoiceInfoSave?: boolean;
  isEstimateInfoSave?: boolean;
  isPermissionUpdated?: boolean;
  businessTax: Array<BusinessTax>;
  subMenuChildren?: Array<MenuItem>;
  userPermission?: Array<UserStandardPermission>;
  isUserPermissionUpdated?: boolean;
  selectedMenu?: MenuItem;
  isViewPermission?: boolean;
  vatSchemes?: any;
  vatSubmitTypes?: any;
  nextCode?: string;
  isDuplicateExist?: boolean;
  message: string;
  isSucess: boolean;
  docData: any;
}

@State<SettingsInfo>({
  name: 'setting',
  defaults: {
    settingsData: {},
    emailData: [],
    startWeekAndEndWeek: {},
    dynamicColumnsData: [],
    businessTax: [],
    isSucess: false,
    message: '',
    docData: {},
  },
})
@Injectable()
export class SettingState {
  constructor(
    private settingService: SettingService,
    private commonService: CommonService
  ) {}

  @Selector()
  static getBusinessTax(state: SettingsInfo) {
    return state.businessTax;
  }

  @Selector()
  static getSettingData(state: SettingsInfo) {
    return state.settingsData;
  }

  @Selector()
  static getSettingsEmailTemplates(state: SettingsInfo) {
    return state.emailData;
  }

  @Selector()
  static getBusinessInvoice(state: SettingsInfo) {
    return state.settingsData;
  }

  @Selector()
  static getBusinessEstimate(state: SettingsInfo) {
    return state.settingsData;
  }

  @Selector()
  static getBusinessFeesSetting(state: SettingsInfo) {
    return state.dynamicColumnsData;
  }

  @Selector()
  static getPermissions(state: SettingsInfo) {
    return state.standardPermission;
  }

  @Selector()
  static getSubMenuChildren(state: SettingsInfo) {
    return state.subMenuChildren ?? [];
  }

  @Selector()
  static getUserBusinessPermission(state: SettingsInfo) {
    return state.userPermission;
  }

  @Selector()
  static getSelectedMenu(state: SettingsInfo) {
    return state.selectedMenu ?? new MenuItem();
  }

  @Selector()
  static getSelectedMenuPermission(state: SettingsInfo) {
    return state.isViewPermission ?? false;
  }

  @Selector()
  static getVatSchemes(state: SettingsInfo) {
    return state.vatSchemes ?? [];
  }

  @Selector()
  static getNextCode(state: SettingsInfo) {
    return state.nextCode ?? '';
  }

  @Selector()
  static getVatSubmitTypes(state: SettingsInfo) {
    return state.vatSubmitTypes ?? [];
  }

  @Action(GetSettingsData)
  getSettingData({ getState, setState }: StateContext<SettingsInfo>) {
    return this.settingService.getSettingsData().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          settingsData: res,
        });
      })
    );
  }

  @Action(GetSettingsTemplateData)
  getSettingsTemplateData({ getState, setState }: StateContext<SettingsInfo>) {
    return this.settingService.getSettingsEmailTemplates().pipe(
      tap((res: Array<EmailTemplateModel>) => {
        const state = getState();
        setState({
          ...state,
          emailData: res,
        });
      })
    );
  }

  @Action(SaveBusiness)
  saveBusiness(
    { patchState }: StateContext<SettingsInfo>,
    action: SaveBusiness
  ) {
    return this.settingService.saveBusiness(action.businessModel).pipe(
      tap((res) => {
        patchState({
          isUpdated: res,
        });
      })
    );
  }

  @Action(UpdateSettingsTemplate)
  updateSettingsTemplate(
    { getState, patchState }: StateContext<SettingsInfo>,
    action: UpdateSettingsTemplate
  ) {
    return this.settingService.updateEmailTemplate(action.settingsData).pipe(
      tap((res) => {
        patchState({
          isUpdated: res,
        });
      })
    );
  }

  @Action(GetStartWeekAndEndWeek)
  getStartWeekAndEndWeek(
    { patchState }: StateContext<SettingsInfo>,
    action: GetStartWeekAndEndWeek
  ) {
    return this.settingService.getStartWeekAndEndWeek().pipe(
      tap((res) => {
        patchState({
          startWeekAndEndWeek: res,
        });
      })
    );
  }

  @Action(SaveDynamicColumns)
  saveDynamicColumns(
    { patchState }: StateContext<SettingsInfo>,
    action: SaveDynamicColumns
  ) {
    return this.settingService.saveDynamicColumns(action.dynamicColumns).pipe(
      tap((res) => {
        patchState({
          isUpdated: res,
        });
      })
    );
  }

  @Action(GetBusinessFeesSetting)
  getBusinessFeesSetting(
    { patchState }: StateContext<SettingsInfo>,
    action: GetBusinessFeesSetting
  ) {
    return this.settingService.getBusinessFeesSetting().pipe(
      tap((res) => {
        patchState({
          dynamicColumnsData: res,
        });
      })
    );
  }

  @Action(SaveBusinessFeesSettingByModuleId)
  saveBusinessFeesSettingByModuleId(
    { getState, patchState }: StateContext<SettingsInfo>,
    action: SaveBusinessFeesSettingByModuleId
  ) {
    return this.settingService.saveBusinessFeesSettingByModuleId(
      action.dynamicColumnDetailModel
    );
  }

  @Action(SaveDynamicColumnsData)
  SaveDynamicColumnsData(
    { getState, patchState }: StateContext<SettingsInfo>,
    action: SaveDynamicColumnsData
  ) {
    const state = getState();
    patchState({
      ...state,
      dynamicColumnsData: action.dynamicColumns,
    });
  }

  @Action(AddRemoveDemoData)
  addRemoveDemoData(
    { getState }: StateContext<SettingsInfo>,
    action: AddRemoveDemoData
  ) {
    return this.settingService.addRemoveDemoData(action.isAddDemoData);
  }

  @Action(GetPermissions, { cancelUncompleted: true })
  getPermissions(
    { getState, setState }: StateContext<SettingsInfo>,
    action: GetPermissions
  ) {
    return this.settingService.getPermissions(action.searchText).pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          standardPermission: res,
        });
      })
    );
  }

  @Action(UpdatePermissions)
  updatePermissions(
    { patchState }: StateContext<SettingsInfo>,
    action: UpdatePermissions
  ) {
    return this.settingService
      .updatePermissions(action.standardPermission)
      .pipe(
        tap((res) => {
          patchState({
            isPermissionUpdated: res,
          });
        })
      );
  }

  @Action(SaveBusinessDynamicColumn)
  saveBusinessDynamicColumn(
    { patchState }: StateContext<SettingsInfo>,
    action: SaveBusinessDynamicColumn
  ) {
    return this.settingService
      .saveBusinessDynamicColumn(action.moduleId, action.dynamicColumns)
      .pipe(
        tap((res) => {
          patchState({
            isUpdated: res,
          });
        })
      );
  }

  @Action(GetBusinessInvoice)
  getBusinessInvoice({ getState, patchState }: StateContext<SettingsInfo>) {
    return this.settingService.getBusinessInvoice().pipe(
      tap((res) => {
        patchState({
          settingsData: res,
        });
      })
    );
  }

  @Action(GetBusinessEstimate)
  getBusinessEstimate({ getState, patchState }: StateContext<SettingsInfo>) {
    return this.settingService.getBusinessEstimate().pipe(
      tap((res) => {
        patchState({
          settingsData: res,
        });
      })
    );
  }

  @Action(SaveBasicInfo)
  saveBasicInfo(
    { patchState }: StateContext<SettingsInfo>,
    action: SaveBasicInfo
  ) {
    return this.settingService.saveBasicInfo(action.businessModel).pipe(
      tap((res) => {
        patchState({
          isBasicInfoSaved: res.isSuccess,
        });
      })
    );
  }

  @Action(SavePreferences)
  savePreferences(
    { patchState }: StateContext<SettingsInfo>,
    action: SavePreferences
  ) {
    return this.settingService.savePreferences(action.businessModel).pipe(
      tap((res) => {
        patchState({
          isPreferenceSaved: res.isSuccess,
        });
      })
    );
  }

  @Action(SaveBusinessInvoiceInfo)
  saveBusinessInvoiceInfo(
    { patchState }: StateContext<SettingsInfo>,
    action: SaveBusinessInvoiceInfo
  ) {
    return this.settingService
      .saveBusinessInvoiceInfo(action.businessModel)
      .pipe(
        tap((res) => {
          patchState({
            isInvoiceInfoSave: res.isSuccess,
          });
        })
      );
  }

  @Action(SaveBusinessEstimateInfo)
  saveBusinessEstimateInfo(
    { patchState }: StateContext<SettingsInfo>,
    action: SaveBusinessEstimateInfo
  ) {
    return this.settingService
      .saveBusinessEstimateInfo(action.businessModel)
      .pipe(
        tap((res) => {
          patchState({
            isEstimateInfoSave: res.isSuccess,
          });
        })
      );
  }

  @Action(GetBusinessTax)
  getBusinessTax({ getState, setState }: StateContext<SettingsInfo>) {
    return this.settingService.getBusinessTax().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          businessTax: res,
        });
      })
    );
  }

  @Action(SetSubMenuChildrenData)
  setSubMenuChildrenData(
    { patchState }: StateContext<SettingsInfo>,
    action: SetSubMenuChildrenData
  ) {
    patchState({
      subMenuChildren: action.subMenuChildren,
    });
  }

  @Action(GetUserBusinessPermission, { cancelUncompleted: true })
  getUserBusinessPermission(
    { getState, setState }: StateContext<SettingsInfo>,
    action: GetUserBusinessPermission
  ) {
    return this.settingService.getUserBusinessPermission(action.userId).pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          userPermission: res,
        });
      })
    );
  }

  @Action(SaveUserPermission)
  saveUserPermission(
    { patchState }: StateContext<SettingsInfo>,
    action: SaveUserPermission
  ) {
    return this.settingService
      .saveUserPermission(action.standardPermission, action.userId)
      .pipe(
        tap((res) => {
          patchState({
            isUserPermissionUpdated: res,
          });
        })
      );
  }

  @Action(UpdateSettingsConfig)
  updateSettingsConfig(
    { patchState }: StateContext<SettingsInfo>,
    action: UpdateSettingsConfig
  ) {
    return this.settingService
      .updateEmailConfig(action.isEmailSendFromCapium)
      .pipe(
        tap((res) => {
          patchState({
            isUpdated: res,
          });
        })
      );
  }

  @Action(GetVatScheme)
  getVatScheme(
    { patchState }: StateContext<SettingsInfo>,
    action: GetVatScheme
  ) {
    return this.settingService.getVatScheme().pipe(
      tap((res) => {
        patchState({
          vatSchemes: res,
        });
      })
    );
  }

  @Action(GetVatSubmitType)
  getVatSubmitType(
    { patchState }: StateContext<SettingsInfo>,
    action: GetVatSubmitType
  ) {
    return this.settingService.getVatSubmitType().pipe(
      tap((res) => {
        patchState({
          vatSubmitTypes: res,
        });
      })
    );
  }

  @Action(GetNextCodeByModuleId)
  getNextCodeByModuleId(
    { patchState }: StateContext<SettingsInfo>,
    action: GetNextCodeByModuleId
  ) {
    return this.settingService.getNextCodeByModuleId(action.moduleId).pipe(
      tap((res) => {
        patchState({
          nextCode: res,
        });
      })
    );
  }

  @Action(IsDuplicateExist)
  isDuplicateExist(
    { getState, setState }: StateContext<SettingsInfo>,
    action: IsDuplicateExist
  ) {
    return this.settingService
      .isDuplicateExist(action.duplicateExistModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            isDuplicateExist: res,
          });
        })
      );
  }

  @Action(GetEmailTemplate)
  getEmailTemplate(
    { patchState }: StateContext<SettingsInfo>,
    action: GetEmailTemplate
  ) {
    return this.settingService.getEmailTemplate().pipe(
      tap((res) => {
        patchState({
          emailData: res,
        });
      })
    );
  }

  @Action(SaveEmailTemplate)
  saveEmailTemplate(
    { patchState }: StateContext<SettingsInfo>,
    action: SaveEmailTemplate
  ) {
    return this.settingService.saveEmailTemplate(action.emailTemplate).pipe(
      tap((res) => {
        patchState({
          isSucess: res,
        });
      })
    );
  }

  @Action(GetDocTemplate)
  getDocTemplate(
    { patchState }: StateContext<SettingsInfo>,
    action: GetEmailTemplate
  ) {
    return this.settingService.getDocTemplate().pipe(
      tap((res) => {
        patchState({
          docData: res,
        });
      })
    );
  }

  @Action(SaveDocTemplate)
  saveDocTemplate(
    { patchState }: StateContext<SettingsInfo>,
    action: SaveEmailTemplate
  ) {
    return this.settingService.saveDocTemplate(action.emailTemplate).pipe(
      tap((res) => {
        patchState({
          isSucess: res,
        });
      })
    );
  }
}
