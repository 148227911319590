export enum CT600ReturnStatus {
  InProgress = 0,
  Complete = 1,
  Submitted = 2,
}

export enum CT600ReturnReport {
  CT600Form = 1,
  Computation = 2,
  //Accounts = 3,
  Attachments = 4,
  Email = 5,
}

export enum RandDenhancedReliefClaimed {
  Date = '2023-04-01',
  beforePers = '230%',
  onAfterPers = '186%',
}
export enum SMESubcontractorReliefClaimed {
  Date = '2023-04-01',
  beforePers = '130%',
  onAfterPers = '130%',
}
