import { Guid } from 'guid-typescript';

export class ManagementExpensesLosses {
  returnFormId?: Guid;
  mgmEExpensesBFW?: number;
  mgmEExpenseArisingPrd?: number;
  mgmEExpenseUtilisedPrdBX245?: number;
  mgmESurrenderGrpRelief?: number;
  mgmEExpensesCFW?: number;
  mgmExpUtilBFWExp?: number;
  mgmExpUtilCPExp?: number;
  mgmEIntDbLossBFW?: number;
  mgmEIntDbIncomeArisingPrd?: number;
  mgmELossesCFW?: number;
  mgmEIntDbLossesUtilisedPrd?: number;
  mgmESurrenderGrpRelief2?: number;
}
