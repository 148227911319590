import { Guid } from 'guid-typescript';

export enum Modules {
  Dashboard = 1,
  Submissions = 2,
  Tasks = 3,
  AccountingPeriod = 4,
  CT600Return = 5,
  QuickEntry = 6,
  MainForms = 7,
  Submit = 8,
  Settings = 9,
  DataSecurity = 10,
  EmailLog = 11,
  UserLog = 12,
  Calculator = 13,
  AddBack = 14,
  CapitalAllowances = 15,
  Adjustment = 16,
  RDExpenditure = 17,
  BalancingCharge = 18,
  CreativeEnhaExpenditure = 19,
  TradingLossesSummary = 20,
  LossesSurrenderedForRDTaxCredit = 21,
  DisallowableExpanses = 22,
  RDEnhancedExpenditure = 23,
  //Calculator = 24,
  TradingProfessionalProfitsCalculator = 25,
  CapitalAllowancesCalculator = 26,
  LossesDeficitsExcessAmountCalculator = 27,
  //Attachments = 28,
  AccountsAndComputationIncluded = 29,
  FileAttachment = 30,
  Reports = 31,
  CT600Computation = 32,
  AccountsReport = 33,
  RDOrFilmsEnhanceExpenditure = 34,
  Users = 35,
  Clients = 36,
  Export = 37,
  Permission = 38,
  Contact = 39,
  CT600Form = 40,
  Computation = 41,
  Accounts = 42,
  Attachments = 43,
  Email = 44,
  SubmissionHistory = 45,
  NonTradingLoanRelationshipProfitsDeficits = 46,
  PropertyIncomeLosses = 47,
  OverseasIncomeLosses = 48,
  NonTradingGainsLossesOnIntangible = 49,
  ChargeableGainsLosses = 50,
  ManagementExpensesLosses = 51,
  TradingProfitLosses = 52,
  PreValidation = 53,
  Submission = 54,
  SubmissionHistorySubRecord = 55,
  PropertyBusinessProfitsCalculator = 56,
  ImportBroughtForward = 57,
  Import = 58,
  AssociatedCompanies = 59
}

export enum MainFormsModules {
  Page1 = 1,
  Page2 = 2,
  Page3 = 3,
  Page6 = 6,
  Page7 = 7,
  Page8 = 8,
  Page4 = 4,
  Page5 = 5,
  Page9 = 9,
  Page10 = 10,
  Page11 = 11,
  Page12 = 12,
  CT600A = 13,
  CT600B = 14,
  CT600C = 15,
  CT600D = 16,
  CT600E = 17,
  CT600F = 18,
  CT600G = 19,
  CT600H = 20,
  CT600I = 21,
  CT600J = 22,
  CT600K = 23,
  CT600L = 24,
  CT600M = 25,
  CT600N = 26,
}

export enum RnDFilmsExpenditureDetailType {
  SMERnDEnhacedExpenditure = 1,
  SMESubcontractorToALargeCompany = 2,
  RnDEnhancedExpenditure = 3,
  CreativeEnhancedExpenditure = 4,
  RDEC = 5,
  CreativeTaxCredit = 6
}

export enum ComponentName {
  MainListComponent = 'app-main-list',
  ReportListComponent = 'app-report-list',
}

export class CommonModel {
  moduleId: number;
  clientId: Guid;
}
