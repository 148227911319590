import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(
    public oidcSecurityService: OidcSecurityService,
    private cookieService: CookieService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let apiReq = request.clone({ url: request.url });

    const token = this.cookieService.get('newToken')
      ? this.cookieService.get('newToken')
      : this.oidcSecurityService.getToken();
    const authToken = 'Bearer ' + token;
    apiReq = apiReq.clone({
      setHeaders: {
        Authorization: authToken ? authToken : '',
        'Access-Control-Allow-Origin': '*',
        'Ocp-Apim-Subscription-Key': '7be5d168a2954a78af1e11a8970c7136',
        userid: this.cookieService.get('userid'),
        businessid: this.cookieService.get('businessid'),
      },
    });

    return next.handle(apiReq).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.status === 401) {
            this.oidcSecurityService.logoff();
          }
          this.setUserDetails(event);
        }
      })
    );
  }

  setUserDetails(event): void {
    const userId = event.headers.get('userid');
    const businessId = event.headers.get('businessid')!;
    const themecolor = event.headers.get('themecolor')!;
    const authorizedRole = event.headers.get('authorizedRole')!;

    this.cookieService.set('isLoggedin', !!userId ? 'true' : 'false');
    if (!!userId) {
      this.cookieService.set('authorizedRole', authorizedRole);
      this.cookieService.set('userid', userId);
    }
    if (!!businessId) {
      this.cookieService.set('businessid', businessId);
    }
    if (!!themecolor) {
      this.cookieService.set('themecolor', themecolor);
    }
  }
}
