import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccoutingPeriodModel, MainListParameters } from '@app/core/models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccoutingPeriodService {
  constructor(private http: HttpClient) {}

  createAccoutingPeriod(
    accoutingPeriodData: AccoutingPeriodModel
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}AccountingPeriod/saveAccountingPeriod?isPriorPeriod=${accoutingPeriodData.isPriorAccoutingPeriod}`,
      JSON.stringify(accoutingPeriodData),
      headers
    );
  }

  getDataByAccoutingPeriodId(
    universalId: Guid
  ): Observable<AccoutingPeriodModel> {
    return this.http.get<AccoutingPeriodModel>(
      `${environment.apiVersionUrl}Client/getClientDetails/${universalId}`
    );
  }

  deleteAccoutingPeriods(ids?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}AccountingPeriod/deleteAccountingPeriods`,
      JSON.stringify(ids),
      headers
    );
  }

  getAccoutingPeriodList(queryParams: MainListParameters): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<any>(
      `${environment.apiVersionUrl}AccountingPeriod/getAccountingPeriods`,
      JSON.stringify(queryParams),
      headers
    );
  }

  onUnlockPeriod(id: Guid, isUnlockPeriod: boolean): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}AccountingPeriod/updateLockPeriod?id=${id}&isLocked=${isUnlockPeriod}`,
      headers
    );
  }

  onCeased(id: Guid, isCeased: boolean): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}AccountingPeriod/updateCeased?id=${id}&isCeased=${isCeased}`,
      headers
    );
  }

  onMarkComparative(id: Guid, isMarkComparative: boolean): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}AccountingPeriod/updateComparitive?id=${id}&isComparative=${isMarkComparative}`,
      headers
    );
  }

  onAutoPeriodGeneration(id: Guid, isAutoPeriod: boolean): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}AccountingPeriod/saveAutoPeriod?clientId=${id}&isChkAutoPeriod=${isAutoPeriod}`,
      headers
    );
  }

  getStartDateByClientId(clientId: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}AccountingPeriod/getPeriodFromDate?clientId=${clientId}`
    );
  }
}
