import { Guid } from 'guid-typescript';

export class BusinessModel {
  id?: number;
  userId?: Guid;
  fullName?: string;
  companyNumber?: string;
  companyVatnumber?: string;
  address1?: string;
  address2?: string;
  postalCode?: string;
  city?: string;
  county?: string;
  countryId?: number;
  title?: string;
  isCompany?: boolean;
  weekStartOn?: number;
  defaultCapacity?: string;
  minimumChargeableTime?: string;
  isAdminFieldVisible?: boolean = false;
  timesheetDueOn?: number;
  timeFormatHour?: number;
  timeModel?: number;
  currencyId?: number;
  isDemoData?: boolean;
  isInvoiceIdAutoGenerated?: boolean;
  invoicePreFix?: string;
  invoiceNextNumber?: number;
  invoiceTitle?: string;
  invoiceFooter?: string;
  invoicePaymentMethodId?: number;
  invoicePaymentDueTermsId?: number;
  newInvoiceNumber?: string;
  isEstimateIdAutoGenerated?: boolean;
  estimatePreFix?: string;
  estimateNextNumber?: number;
  estimateTitle?: string;
  estimateFooter?: string;
  estimatePaymentMethodId?: number;
  estimatePaymentDueTermsId?: number;
  newEstimateNumber?: string;
  isEmailSendFromCapium?: boolean;
  emailSignature?: string;
  isDefault?: boolean;
  logo?: string;
  contacts?: Array<UserSettingContact>;
  settings?: Array<DynamicColumnModel>;
  isDemoDataAdded?: boolean;
  timesheetDueTime?: string;
  timesheetReminderTime?: string;
  afterTimesheetDueTime?: string;
  isVatRegistered?: boolean;
  defaultTaxId?: Guid;
  isSendTimesheetReminder?: boolean;
  timesheetReminderDays?: number;
  isSendTimesheetReminderAfterDueDate?: boolean;
  timesheetReminderAfterDueDays?: number;
  sendTimesheetApprovalReminderType?: number;
  isAutoSendScheduledInvoice?: boolean;
  isDashboardCreated?: boolean;
  isAdministrativeFieldVisible?: boolean;
}

export class UserSettingContact {
  title: string;
  name: string;
  email: string;
  phone: string;
  isPrimary?: boolean;
  isArchived?: boolean;
  universalId?: string;
}

export class UserSettingWeekModel {
  startDate?: Date;
  endDate?: Date;
}

export class EmailTemplateModel {
  id: number;
  templateTypeName: string;
  templateDetails: Array<TemplateDetail>;
}

export class TemplateDetail {
  id?: number;
  businessTemplateId?: number;
  templateId?: number;
  displayName?: string;
  subject?: string;
  templateHtml?: string;
  templateParams?: string;
  suggestedLabels?: any;
}

export class DynamicColumnModel {
  id: number;
  settingTypeId: number;
  settingName: string;
  primaryName: string;
  secondaryName: string;
  settingValue: string;
  isSelected: boolean;
  optionId: number;
}

export class DynamicColumnDetailModel {
  moduleId?: number;
  entityId?: number;
  settings?: Array<DynamicColumnModel>;
  details?: Array<any>;
}

export class StandardPermission {
  name: string;
  ordinal: number;
  parentModuleId: number;
  id: number;
  isOwner: boolean;
  isAdministrator: number;
  isManager: number;
  isRegularUser: number;
  subMenu: Array<StandardPermission>;
  permissionId: number;
  isDisabled: boolean;
  moduleUrl?: string;
  moduleAddUrl?: string;
}

export class UserStandardPermission {
  name: string;
  ordinal: number;
  parentModuleId: number;
  id: number;
  subMenu: Array<UserStandardPermission>;
  permissionId: number;
  isDisabled: boolean;
}

export class MenuItem {
  name: string;
  friendlyName: string;
  ordinal: number;
  iconName: string;
  url: string;
  addUrl: string;
  permissionId: number;
  isIconMenu: boolean;
  children: Array<MenuItem>;
}

export class BusinessTax {
  universalId: Guid;
  rate: number;
  name: string;
  isDefault: boolean;
}
