<div class="sidenav-body-content p-0">
  <div class="content-header">
    <div class="content-header-input d-flex align-items-center gap-15">
      <button
        class="action-button primary-button"
        mat-button
        #button
        disableRipple
        (click)="openDialog()"
      >
        <mat-icon>add</mat-icon>
        <span>Add Support Ticket</span>
      </button>
      <mat-form-field appearance="fill" class="search-field">
        <input
          matInput
          placeholder="Search"
          autocomplete="off"
          (keyup)="onSearch($event)"
        />
        <mat-icon>search</mat-icon>
      </mat-form-field>
    </div>
    <div class="content-right-button">
      <div class="sidenav-content">
        <ng-select
          class="w-150"
          #status
          (focus)="status.open()"
          (valueChange)="status.close()"
          panelClass="myPanelClass"
          [(ngModel)]="selectedStatus"
          (change)="onSelectionChange()"
        >
          <ng-option
            *ngFor="let ts of ticketStatus | useEnumValue"
            [value]="+ts.key"
          >
            {{ ts.value }}
          </ng-option>
        </ng-select>
      </div>
    </div>
  </div>
  <div class="content-body p-0">
    <div class="mat-table-wrapper">
      <table
        mat-table
        [dataSource]="dataSource"
        aria-describedby="Support Ticket Table"
        matSort
      >
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-150">
            Ticket No.
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.id }}
          </td>
        </ng-container>
        <ng-container matColumnDef="subject">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Subject</th>
          <td mat-cell *matCellDef="let element">
            {{ element.subject }}
          </td>
        </ng-container>
        <ng-container matColumnDef="createdOn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Created on</th>
          <td mat-cell *matCellDef="let element">
            {{ element.created_at | date: "dd-MMM-yyyy" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let element">
            <span class="success">{{ ticketStatus[element.status] }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="groupName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Department</th>
          <td mat-cell *matCellDef="let element">
            {{ element.groupName }}
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td
            class="mat-cell"
            [ngClass]="'text-align-center'"
            [attr.colspan]="displayedColumns.length"
          >
            {{ commonNotificationText.NoRecordsFound }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
