<div class="side-list-actions footer-list-action">
  <div class="content-body sidenav-body-content">
    <button
      mat-button
      class="close-button"
      disableRipple
      (click)="cancelSelection()"
    >
      <mat-icon>highlight_off</mat-icon>
    </button>
    <div class="d-flex align-items-center">
      <div class="mr-3">
        <div class="mb-1">
          <p class="text-muted fw-bold m-0">Selected Items</p>
        </div>
        <div class="d-flex align-items-center justify-space-between">
          <p class="font-size-16 fw-bold m-0 mr-2">
            <span class="text-muted font-size-18">Records:</span>
            {{ totalSelectedRecords }}
          </p>
          <a
            class="hyperlink"
            href="javascript:void(0)"
            (click)="cancelSelection()"
            >Cancel Selection</a
          >
        </div>
      </div>
      <div class="d-flex align-items-center action-list">
        <div
          class="mr-2"
          *ngIf="
            getModuleName !== moduleName.Logs &&
            getModuleName !== moduleName.SubmissionHistory &&
            getModuleName !== moduleName.Clients
          "
        >
          <p class="font-size-16 fw-bold mb-1">Edit</p>
          <div class="d-flex align-items-center">
            <button
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              (click)="delete()"
              [disabled]="isViewPermission"
            >
              <mat-icon>delete_outline</mat-icon>
              <p>Delete</p>
            </button>
          </div>
        </div>
        <div class="mr-2">
          <p class="font-size-16 fw-bold mb-1">Export</p>
          <div class="d-flex align-items-center">
            <button
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              (click)="export(exportFormat.Excel)"
              [disabled]="isViewPermission"
            >
              <mat-icon>border_all</mat-icon>
              <p>Excel</p>
            </button>
            <button
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              (click)="export(exportFormat.CSV)"
              [disabled]="isViewPermission"
            >
              <mat-icon fontSet="material-icons-outlined">description</mat-icon>
              <p>CSV</p>
            </button>
            <button
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              (click)="export(exportFormat.PDF)"
              [disabled]="isViewPermission"
            >
              <mat-icon fontSet="material-icons-outlined"
                >picture_as_pdf</mat-icon
              >
              <p>PDF</p>
            </button>
          </div>
        </div>
        <div class="mr-2 end-list">
          <p class="font-size-16 fw-bold mb-1">Options</p>
          <div class="d-flex align-items-center">
            <button
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              (click)="print()"
              [disabled]="isViewPermission"
            >
              <mat-icon fontSet="material-icons-outlined">print</mat-icon>
              <p>Print</p>
            </button>
            <button
              style="display: none"
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              (click)="archiveAndRestore(true)"
              [disabled]="isViewPermission"
            >
              <mat-icon fontSet="material-icons-outlined">archive</mat-icon>
              <p>Archive</p>
            </button>
            <button
              style="display: none"
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              (click)="archiveAndRestore(false)"
              [disabled]="isViewPermission"
            >
              <mat-icon fontSet="material-icons-outlined">unarchive</mat-icon>
              <p>Restore</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
