import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { ConfirmationList } from '@app/core/models';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  ConfirmationType,
  Modules,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/enums';
import { NotificationService } from '@app/core/services';
import {
  DeleteClientContact,
  DeleteClients,
} from '@app/core/store/client/client.action';
import { DeleteAccoutingPeriods } from '@app/core/store/accounting-period/accounting-period.action';
import { DeleteCt600Returns } from '@app/core/store/tasks/ct600-return/ct600-return.action';
import { DeleteCapitalAllowance, DeleteUsers } from '@app/core/store';

@Component({
  selector: 'app-confirmation-box',
  templateUrl: './confirmation-box.component.html',
  styleUrls: ['./confirmation-box.component.scss'],
})
export class ConfirmationBoxComponent implements OnInit {
  confirmationList: ConfirmationList;

  constructor(
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConfirmationBoxComponent>,
    private notifier: NotificationService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.confirmationList = this.data;
  }

  onYesClick(): void {
    this.spinner.show();
    if (this.confirmationList.type === ConfirmationType.Archive) {
      this.onArchiveClick();
    }

    if (this.confirmationList.type === ConfirmationType.Delete) {
      this.onDeleteClick();
    }
  }

  onArchiveClick(): void {
    let actionName;

    if (actionName === undefined) {
      this.onFailure();
      this.spinner.hide();
      this.dialogRef.close();
    }
  }

  onDeleteClick(): void {
    let actionName;

    switch (this.confirmationList.moduleId) {
      case Modules.Clients:
        actionName = DeleteClients;
        break;

      case Modules.AccountingPeriod:
        actionName = DeleteAccoutingPeriods;
        break;

      case Modules.CT600Return:
        actionName = DeleteCt600Returns;
        break;

      case Modules.CapitalAllowancesCalculator:
        actionName = DeleteCapitalAllowance;
        break;

      case Modules.Contact:
        actionName = DeleteClientContact;
        break;

      case Modules.Users:
        actionName = DeleteUsers;
        break;
    }

    if (actionName !== undefined) {
      this.store
        .dispatch(new actionName(this.confirmationList.ids))
        .subscribe((res) => {
          if (this.confirmationList.moduleId === Modules.AccountingPeriod) {
            this.checkSatus(res);
          } else {
            this.doFunction(res);
          }
        });
    } else {
      this.spinner.hide();
      this.dialogRef.close(true);
      this.onFailure();
    }
  }

  checkSatus(res: any): void {
    if (res.accoutingPeriod.status) {
      this.onSuccessDelete();
    } else {
      this.dialogRef.close(false);
      this.notifier.error(
        NotificationHeader.error,
        res.accoutingPeriod.message
      );
    }
  }

  doFunction(res): void {
    if (res) this.onSuccessDelete();
  }
  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onFailure(): void {
    this.notifier.error(
      NotificationHeader.error,
      NotificationTextMessage.errorMessage
    );
  }

  onSuccessDelete(): void {
    this.notifier.success(
      NotificationHeader.success,
      NotificationTextMessage.recordDeletedSuccessfully
    );
    this.spinner.hide();
    this.dialogRef.close(true);
  }

  onArchiveAndRestoreSuccess(): void {
    this.notifier.success(
      NotificationHeader.success,
      this.confirmationList.isArchive
        ? NotificationTextMessage.recordArchivedSuccessfully
        : NotificationTextMessage.recordRestoredSuccessfully
    );
    this.dialogRef.close(true);
  }
}
