<div class="sidenav-body-content pl-0 pr-0">
  <div class="notification">
    <div class="notification-list">
      <mat-accordion>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title> May - 2022 </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="sidenav-body-content">
            <div class="notification-list-wrapper">
              <ul class="notification-list">
                <li>
                  <div class="d-flex gap-20">
                    <div class="notification-date d-flex flex-column">
                      <span class="date">12</span>
                      <span class="month">May</span>
                    </div>
                    <div class="notification-body">
                      <div class="media d-flex">
                        <div class="main-avatar avatar-md online">
                          <span class="rounded-img rounded-img-text">AD</span>
                        </div>
                        <div class="media-body">
                          <p class="status">
                            New version for <span>Time and Fee</span>
                          </p>
                          <p class="time">at 11:20 am</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="d-flex gap-20">
                    <div class="notification-date d-flex flex-column">
                      <span class="date">13</span>
                      <span class="month">May</span>
                    </div>
                    <div class="notification-body">
                      <div class="media d-flex">
                        <div class="main-avatar avatar-md online">
                          <img
                            class="rounded-img"
                            src="assets/images/user.jpg"
                            alt="User"
                          />
                        </div>
                        <div class="media-body">
                          <p class="status">
                            Updated <strong>Invoice</strong> sections.
                          </p>
                          <p class="time">at 11:20 am</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="d-flex gap-20">
                    <div class="notification-date d-flex flex-column">
                      <span class="date">14</span>
                      <span class="month">May</span>
                    </div>
                    <div class="notification-body">
                      <div class="media d-flex">
                        <div class="main-avatar avatar-md online">
                          <span class="rounded-img rounded-img-text">AD</span>
                        </div>
                        <div class="media-body">
                          <p class="status">
                            New version for <span>Time and Fee</span>
                          </p>
                          <p class="time">at 12:20 pm</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="notification-list mt-1">
      <mat-accordion>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title> April - 2022 </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="sidenav-body-content">
            <div class="notification-list-wrapper">
              <ul class="notification-list">
                <li>
                  <div class="d-flex gap-20">
                    <div class="notification-date d-flex flex-column">
                      <span class="date">12</span>
                      <span class="month">May</span>
                    </div>
                    <div class="notification-body">
                      <div class="media d-flex">
                        <div class="main-avatar avatar-md online">
                          <span class="rounded-img rounded-img-text">AD</span>
                        </div>
                        <div class="media-body">
                          <p class="status">
                            New version for <span>Time and Fee</span>
                          </p>
                          <p class="time">at 11:20 am</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="d-flex gap-20">
                    <div class="notification-date d-flex flex-column">
                      <span class="date">13</span>
                      <span class="month">May</span>
                    </div>
                    <div class="notification-body">
                      <div class="media d-flex">
                        <div class="main-avatar avatar-md online">
                          <img
                            class="rounded-img"
                            src="assets/images/user.jpg"
                            alt="User"
                          />
                        </div>
                        <div class="media-body">
                          <p class="status">
                            Updated <strong>Invoice</strong> sections.
                          </p>
                          <p class="time">at 11:20 am</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="d-flex gap-20">
                    <div class="notification-date d-flex flex-column">
                      <span class="date">14</span>
                      <span class="month">May</span>
                    </div>
                    <div class="notification-body">
                      <div class="media d-flex">
                        <div class="main-avatar avatar-md online">
                          <span class="rounded-img rounded-img-text">AD</span>
                        </div>
                        <div class="media-body">
                          <p class="status">
                            New version for <span>Time and Fee</span>
                          </p>
                          <p class="time">at 12:20 pm</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
