import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { Guid } from 'guid-typescript';
import {
  APReportModel,
  Ct600ReturnModel,
  EmailData,
  MainListParameters,
} from '@app/core/models';

@Injectable({
  providedIn: 'root',
})
export class Ct600ReturnService {
  constructor(private http: HttpClient) {}

  createCt600Return(ct600ReturnModel: Ct600ReturnModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}CT600Form/createNewCT600Form`,
      JSON.stringify(ct600ReturnModel),
      headers
    );
  }

  deleteCt600Returns(ids?: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}CT600Form/deleteCT600Form`,
      JSON.stringify(ids),
      headers
    );
  }

  copyCt600Returns(CT600Ids?: Guid): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}CT600Form/copyReturnForm`,
      JSON.stringify(CT600Ids),
      headers
    );
  }

  getCt600ReturnList(queryParams: MainListParameters): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}CT600Form/getCT600FormsList`,
      JSON.stringify(queryParams),
      headers
    );
  }

  getDataByCt600ReturnId(universalId: Guid): Observable<Ct600ReturnModel> {
    return this.http.get<Ct600ReturnModel>(
      `${environment.apiVersionUrl}CT600Form/getCT600ReturnDetails/${universalId}`
    );
  }

  getEmialData(
    clientId: Guid,
    returnDetailId: Guid
  ): Observable<Ct600ReturnModel> {
    return this.http.get<Ct600ReturnModel>(
      `${environment.apiVersionUrl}CT600Form/getEmailData?returnDetailId=${returnDetailId}&clientId=${clientId}`
    );
  }

  getCt600SelectPeriod(clientId: Guid): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<any>(
      `${environment.apiVersionUrl}CT600Form/getManualPeriods?clientId=${clientId}`,
      headers
    );
  }

  getAPReportPeriods(apReportModel: APReportModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<any>(
      `${environment.apiVersionUrl}AccountsReport/getAPReportPeriods`,
      JSON.stringify(apReportModel),
      headers
    );
  }

  getCT600Number(
    clientId: Guid,
    clientName: string
  ): Observable<Ct600ReturnModel> {
    return this.http.get<Ct600ReturnModel>(
      `${environment.apiVersionUrl}CT600Form/getAutoReferenceId/${clientId}/${clientName}`
    );
  }

  sendEmail(emailDtaa: EmailData): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}CT600Pdf/sendReturnEmail`,
      JSON.stringify(emailDtaa),
      headers
    );
  }

  saveData(formData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Settings/saveDataSecurityForPdfS`,
      JSON.stringify(formData),
      headers
    );
  }

  getData(clientId: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Settings/getDataSecurityDetails?clientId=${clientId}`
    );
  }

  getReturnDetailsData(data: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<any>(
      `${environment.apiVersionUrl}/Submission/getSubmitReturnDetails`,
      JSON.stringify(data),
      headers
    );
  }

  submitReturns(formData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Submission/submitReturn`,
      JSON.stringify(formData),
      headers
    );
  }
  finalSubmissionById(returnDetailId: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Submission/updatePollSubmission?returnDetailId=${returnDetailId}`
    );
  }

  updateStatus(formData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}CT600Form/returnStatusUpdate`,
      JSON.stringify(formData),
      headers
    );
  }

  getBKAccountingCodes(
    clientName: string,
    returndetailId: any,
    clientId: any
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}CT600Form/getBKAccountCodes/${clientName}/${clientId}/${returndetailId}`
    );
  }

  saveReturnPayment(formData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}CT600Form/updateReturnPayment`,
      JSON.stringify(formData),
      headers
    );
  }

  saveAssociatedCompanies(formData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}CT600Form/saveAssociatedCompanies`,
      JSON.stringify(formData),
      headers
    );
  }

  getAssociatedCompanies(returnFormId: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}CT600Form/getAssociatedCompanyDetails/${returnFormId}`
    );
  }

}
