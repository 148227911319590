<div
  class="header"
  [ngClass]="{
    'page-scroll-down': scrollDirection === 'down',
    'page-scroll-up': scrollDirection === 'up'
  }"
>
  <div class="d-flex align-items-center justify-space-between wp-100">
    <div class="d-flex align-items-center">
      <div class="logo-container">
        <a [routerLink]="'/'">
          <img
            class="report-landing-hero-image"
            alt="Logo"
            src="assets/images/logo.png"
          />
        </a>
      </div>
      <div class="nav-container">
        <div class="navigation-menu">
          <div *ngFor="let menu of menuData">
            <ng-container *ngIf="menu.id !== 3">
              <a
                *ngIf="menu.id === 2"
                (click)="onMenuClick(menu)"
                [ngClass]="addActive('task', 'submissions')"
                routerLink="{{ menu.url }}"
              >
                <mat-icon fontSet="material-icons-outlined">{{
                  menu.icon
                }}</mat-icon>
                <span>{{ menu.name }}</span>
              </a>

              <a
                *ngIf="menu.id !== 2"
                (click)="onMenuClick(menu)"
                
                [ngClass]="isActive(menu.url)"
                routerLink="{{
                  menu.subMenu && menu.subMenu.length > 0
                    ? menu.subMenu[0].url
                    : menu.url
                }}"
              >
                <mat-icon fontSet="material-icons-outlined">{{
                  menu.icon
                }}</mat-icon>
                <span>{{ menu.name }}</span>
              </a>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="account-container">
      <div class="account-menu">
        <ul class="d-flex align-items-center">          
          <li>
            <a>
              <mat-icon
                class="mat-settings-icon"
                fontSet="material-icons-outlined"
                [matMenuTriggerFor]="addSettings"
                title="Quick Add"
              >
                add_circle_outline</mat-icon
              >
            </a>
            <mat-menu
              #addSettings="matMenu"
              class="action-menu top-menu-list"
              xPosition="before"
            >
              <div class="main-menu-list">
                <div *ngFor="let menuItem of menuData" class="menu-list-item">
                  <ng-container *ngIf="menuItem.id !== 3">
                    <a
                      mat-menu-item
                      routerLink="{{ menuItem.url }}"
                      class="d-flex align-items-center gap-5"
                    >
                      <mat-icon
                        class="add-icon"
                        fontSet="material-icons-outlined"
                      >
                        {{ menuItem.icon }}
                      </mat-icon>
                      {{ menuItem.name }}
                    </a>
                  </ng-container>
                </div>
              </div>
            </mat-menu>
          </li>

          <li>
            <a
              href="javascript:void(0)"
              routerLinkActive="active"
              routerLink="logs"
              title="Activity"
            >
              <mat-icon fontSet="material-icons-outlined">history</mat-icon>
            </a>
          </li>         
          <li>
            <a
              href="javascript:void(0)"
              class="profile-setting-icon p-0"
              [matMenuTriggerFor]="settingSubMenus"
              title="Settings"
            >
              <mat-icon>settings_outline</mat-icon>
            </a>
            <mat-menu
              #settingSubMenus="matMenu"
              class="action-menu"
              xPosition="before"
            >
              <button
                mat-menu-item
                disableRipple
                [routerLink]="'email-templates'"
              >
                <mat-icon fontSet="material-icons-outlined">mail</mat-icon>
                Email Templates
              </button>
              <button
                mat-menu-item
                disableRipple
                [routerLink]="'document-templates'"
              >
                <mat-icon fontSet="material-icons-outlined"
                  >description</mat-icon
                >
                Document Templates
              </button>
              <button mat-menu-item disableRipple [routerLink]="'tax-document'">
                <mat-icon fontSet="material-icons-outlined"
                  >report_gmailerrorred</mat-icon
                >
                Logo on Tax Document
              </button>
            </mat-menu>
          </li>

          <li class="module-list separation pl-1">
            <a
              href="javascript:void(0)"
              [matMenuTriggerFor]="capiumMenu"
              title="Modules"
            >
              <div class="m-0">
                <img
                  src="assets/images/company-logo.svg"
                  width="22"
                  height="22"
                  class="capium-logo"
                  alt="Capium Logo"
                />
              </div>
            </a>
            <mat-menu
              #capiumMenu="matMenu"
              class="action-menu dropdown-module-list"
              xPosition="after"
            >
              <div class="justify-content-between">
                <ul>
                  <ng-container
                    *ngFor="let subscriptionModule of subscriptionModuleList"
                  >
                    <li
                      class=""
                      [ngClass]="
                        subscriptionModule.isDisabled ? 'disable-box' : ''
                      "
                      *ngIf="subscriptionModule.isDisplay"
                    >
                      <a
                        [href]="appUrl ? appUrl : '#'"
                        (click)="redirectToModule(subscriptionModule, $event)"
                      >
                        <div class="top-card d-flex gap-10">
                          <img [src]="subscriptionModule.iconSrc" alt="logo" />
                          <div>
                            <h6>{{ subscriptionModule.externalName }}</h6>
                            <p>{{ subscriptionModule.content }}</p>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ng-container>
                </ul>
                <div class="top-switch-button text-align-right">
                  <button
                    type="button"
                    class="mat-focus-indicator action-button primary-button mat-button mat-button-base"
                    (click)="redirectToHome()"
                  >
                    <span class="mat-button-wrapper">
                      <img
                        src="assets/images/capihome.svg"
                        alt="Icon"
                        class="pr-5"
                      />
                      <span _ngcontent-krt-c167="">Home </span>
                    </span>
                    <span class="mat-ripple mat-button-ripple"> </span>
                    <span class="mat-button-focus-overlay"></span>
                  </button>
                  <button
                    type="button"
                    class="mat-focus-indicator action-button secondary-button ml-1 mat-button mat-button-base"
                    (click)="myAdminClick()"
                  >
                    <span class="mat-button-wrapper">
                      <img
                        src="assets/images/myadmin.svg"
                        alt="Icon"
                        class="pr-5"
                      />
                      <span>My Admin</span>
                    </span>
                    <span
                      matripple=""
                      class="mat-ripple mat-button-ripple"
                    ></span>
                    <span class="mat-button-focus-overlay"></span>
                  </button>
                </div>
              </div>
            </mat-menu>
          </li>
          <li>
            <a href="javascript:void(0)" title="Annoucement">
              <mat-icon fontSet="material-icons-outlined">campaign</mat-icon>
            </a>
          </li>
          <li>
            <a
              [routerLink]="'/ticket'"
              routerLinkActive="active"
              title="Ticket"
            >
              <mat-icon class="rotate" fontSet="material-icons-outlined"
                >confirmation_number</mat-icon
              >
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              class="profile-setting-icon p-0"
              title="Help"
              (click)="onHelpClick()"
            >
              <mat-icon>help_outline</mat-icon>
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" [matMenuTriggerFor]="profileInfo">
              <div *ngIf="userPhoto !== null">
                <img
                  class="profile-image-thumb"
                  [src]="userPhoto"
                  alt="User photo"
                  title="User Image"
                />
              </div>
              <div
                *ngIf="userPhoto === null && userName"
                class="rounded-border m-0"
                title="User Image"
              >
                {{ userName | shortName: 2 }}
              </div>
            </a>
            <mat-menu
              #profileInfo="matMenu"
              class="action-menu profile-setting-menu"
              xPosition="after"
            >
              <div class="text-align-center profile-settings">
                <div class="mb-1" *ngIf="userPhoto !== null">
                  <img
                    class="profile-image"
                    [src]="userPhoto"
                    alt="User photo"
                  />
                </div>
                <div
                  *ngIf="userPhoto === null && userName"
                  class="rounded-border"
                >
                  {{ userName | shortName: 2 }}
                </div>

                <div>
                  <p
                    class="fw-bold text-transform-uppercase text-gray-100 font-size-16 m-0"
                  >
                    {{ userName }}
                  </p>
                </div>
                <div>
                  <p class="m-0">User ID: {{ userId }}</p>
                </div>
                <div>
                  <p class="mb-1 m-0">{{ userEmail }}</p>
                </div>
                <div class="hr-top pt-1">
                  <a href="javascript:void(0)" (click)="myAdminClick()">
                    <span class="text-gray-100 font-size-16">My Account</span>
                  </a>
                  <span class="pl-2 pr-2 text-gray-100">|</span>
                  <a href="javascript:void(0)" (click)="signOutUser()">
                    <span class="text-danger-100 font-size-16">Sign Out</span>
                  </a>
                </div>
                <div
                  class="pt-1 pb-5"
                  *ngIf="isSubscriptionPortalEnabled && isOwnerUser"
                >
                  <a
                    href="javascript:void(0)"
                    (click)="produdctSubscriptionClick()"
                    class="link"
                  >
                    <span class="text-primary font-size-16"
                      >Products & Subcriptions</span
                    >
                  </a>
                </div>
                <div class="theme-colors pt-1">
                  <mat-radio-group class="mt-5 display-inline">
                    <mat-radio-button
                      *ngFor="let color of colors"
                      [value]="color"
                      [(ngModel)]="colorSelected"
                      ngDefaultControl
                      (change)="onSelectionChange($event.value, true)"
                      [ngClass]="{
                        'mat-radio-checked': colorSelected === color
                      }"
                    >
                      <button
                        class="theme-selector"
                        [ngStyle]="{ 'background-color': color }"
                        type="button"
                        mat-button
                        disableRipple
                      >
                        <mat-icon>check</mat-icon>
                      </button>
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
            </mat-menu>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
