<div id="chart" *ngIf="flowChartOptions && noOfRecords > 0">
  <apx-chart
    *ngIf="isLoadingComplete && noOfRecords > 0"
    [series]="flowChartOptions?.series!"
    [chart]="flowChartOptions?.chart!"
    [dataLabels]="flowChartOptions?.dataLabels!"
    [plotOptions]="flowChartOptions?.plotOptions!"
    [yaxis]="flowChartOptions?.yaxis!"
    [legend]="flowChartOptions?.legend!"
    [fill]="flowChartOptions?.fill!"
    [stroke]="flowChartOptions?.stroke!"
    [tooltip]="flowChartOptions?.tooltip!"
    [xaxis]="flowChartOptions?.xaxis!"
    [noData]="flowChartOptions?.noData!"
  ></apx-chart>
</div>

<div *ngIf="isLoadingComplete && noOfRecords === 0" class="norecord-chart">
  <img src="assets/images/column-icon.svg" alt="Column Chart" />
  <p class="pt-10">No data available yet!</p>
</div>
