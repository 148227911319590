import { Injectable } from '@angular/core';

import { MainFormService } from '@app/core/services';
import { Action, State, StateContext } from '@ngxs/store';

import { tap } from 'rxjs';

import {
  DownloadCT600Form,
  GetMainFormsByReturnId,
  GetSupplamentoryFormNamesByReturnId,
  GetSupplamentoryFormsByReturnId,
  GetSupplamentoryFormsDetailsByReturnId,
  SaveMainFormData,
  SaveSupplamentoryFormsDetails,
} from './mainform.action';

export class MainFormStateInfo {
  id?: number;
  mainFormData?: any;
  message?: string;
  isSuccess: boolean;
  isError: boolean;
  supplamentaryFormData?: any;
  supplamentaryFormDetails?: any;
  supplamentaryFormNames?: any;
}

@State<MainFormStateInfo>({
  name: 'mainform',
  defaults: {
    isSuccess: false,
    isError: false,
  },
})
@Injectable()
export class MainFormState {
  constructor(private mainService: MainFormService) {}

  @Action(SaveMainFormData)
  saveMainformData(
    { patchState }: StateContext<MainFormStateInfo>,
    action: SaveMainFormData
  ) {
    return this.mainService.saveDataByField(action.mainFormData).pipe(
      tap((res) => {
        patchState({
          isError: res.isError,
          message: res.message,
        });
      })
    );
  }

  @Action(GetSupplamentoryFormsByReturnId)
  getSupplamentaryFormsDataByReturnFormId(
    { patchState }: StateContext<MainFormStateInfo>,
    action: GetSupplamentoryFormsByReturnId
  ) {
    return this.mainService
      .getSupplamentaryFormsDataByReturnFormId(action.returnFormId)
      .pipe(
        tap((res) => {
          patchState({
            supplamentaryFormData: res,
          });
        })
      );
  }

  @Action(GetMainFormsByReturnId)
  getMainFormsDataByReturnFormId(
    { patchState }: StateContext<MainFormStateInfo>,
    action: GetMainFormsByReturnId
  ) {
    return this.mainService
      .getMainFormsDataByReturnFormId(
        action.clientId,
        action.returnFormId,
        action.pageNo
      )
      .pipe(
        tap((res) => {
          patchState({
            mainFormData: res,
          });
        })
      );
  }

  @Action(GetSupplamentoryFormsDetailsByReturnId)
  getSupplamentaryFormsDetails(
    { patchState }: StateContext<MainFormStateInfo>,
    action: GetSupplamentoryFormsDetailsByReturnId
  ) {
    return this.mainService
      .getSupplamentaryFormsDetails(action.returnFormId, action.formName)
      .pipe(
        tap((res) => {
          patchState({
            supplamentaryFormDetails: res,
          });
        })
      );
  }

  @Action(SaveSupplamentoryFormsDetails)
  SupplamentoryFormsDetails(
    { patchState }: StateContext<MainFormStateInfo>,
    action: SaveSupplamentoryFormsDetails
  ) {
    return this.mainService
      .SaveSupplamentoryFormsDetails(action.spFormData)
      .pipe(
        tap((res) => {
          patchState({
            isError: res.isError,
            message: res.supFormMessage,
          });
        })
      );
  }

  @Action(GetSupplamentoryFormNamesByReturnId)
  getSupplamentaryFormNamesByReturnFormId(
    { patchState }: StateContext<MainFormStateInfo>,
    action: GetSupplamentoryFormNamesByReturnId
  ) {
    return this.mainService
      .getSupplamentaryFormsNamesByReturnFormId(action.returnFormId)
      .pipe(
        tap((res) => {
          patchState({
            supplamentaryFormNames: res,
          });
        })
      );
  }

  @Action(DownloadCT600Form)
  downloadCT600Form(
    { patchState }: StateContext<MainFormStateInfo>,
    action: DownloadCT600Form
  ) {
    return this.mainService
      .downloadCT600Form(action.formData)
      .pipe(tap((res) => {}));
  }
}
