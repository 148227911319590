import {
  RandDCreativeExp,
  RandDEnahanceExp,
  RandDExpCredit,
} from '@app/core/models';
import { Guid } from 'guid-typescript';

export class GetFilmsEnhanceExpenditureData {
  static readonly type =
    '[FilmsEnhanceExpenditure] Get Films Enhance Expenditure Data';
  constructor(public returnFormId: Guid) {}
}
export class SaveFilmsEnhanceExpenditure {
  static readonly type =
    '[FilmsEnhanceExpenditure] Save Films Enhance Expenditure';
  constructor(public filmsRandDEnahanceExpData: RandDEnahanceExp) {}
}
export class SaveCreativeEnhancedExpenditure {
  static readonly type =
    '[FilmsEnhanceExpenditure] Save Creative Enhanced Expenditure';
  constructor(public creativeEnhancedExpenditureData: RandDCreativeExp) {}
}
export class SaveResearchDevelopmentExpCredit {
  static readonly type =
    '[FilmsEnhanceExpenditure] Save Research Development Expenditure Credit';
  constructor(public researchDevelopmentExpCreditData: RandDExpCredit) {}
}
export class SaveSMERDenhancedExpenditure {
  static readonly type =
    '[RDenhancedExpenditure] Save SME R&D Enhaced Expenditure';
  constructor(public filmssmeRandDEnahanceExpData: any) {}
}
export class GetRandDExpenditureData {
  static readonly type = '[RandDExpenditureData] Get R&D Expenditure Data';
  constructor(public returnFormId: Guid, public typeId: number) {}
}
export class SaveSMERandDExpenditure {
  static readonly type = '[SMERandDExpenditure] Save SME R&D Expenditure';
  constructor(public smeRandDData: any) {}
}
export class SaveSMESubContractor {
  static readonly type = '[SMESubContractor] Save SME SubContractor';
  constructor(public smeRandDData: any) {}
}
export class GetTypeList {
  static readonly type = '[RANDD] Get Type List';
}
