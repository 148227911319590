import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable, of, switchMap } from 'rxjs';
import {
  CT600Form,
  MainFormModel,
  SupplamentoryFormsModel,
  TshqRequestOptions,
} from '@app/core/models';
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root',
})
export class MainFormService {
  constructor(private http: HttpClient) {}

  saveDataByField(mainform: MainFormModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}CT600Form/saveMainFormFieldValue`,
      JSON.stringify(mainform),
      headers
    );
  }

  getSupplamentaryFormsDataByReturnFormId(reTurnFormId: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}CT600Form/getSupplementaryFormPages/${reTurnFormId}`
    );
  }

  getSupplamentaryFormsNamesByReturnFormId(
    reTurnFormId: Guid
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}CT600Form/getReturnSupplementaryForms/${reTurnFormId}`
    );
  }

  getMainFormsDataByReturnFormId(
    clientId: Guid,
    reTurnFormId: Guid,
    pageNo: number
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}CT600Form/getMainFormDetails/${clientId}/${reTurnFormId}/${pageNo}`
    );
  }

  getSupplamentaryFormsDetails(
    reTurnFormId: Guid,
    formName: string
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}CT600Form/getSupplementaryFormDetails/${reTurnFormId}/${formName}`
    );
  }

  SaveSupplamentoryFormsDetails(
    spFormDetails: SupplamentoryFormsModel
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}CT600Form/SaveSupplementaryFormValues`,
      JSON.stringify(spFormDetails),
      headers
    );
  }

  downloadCT600Form(formData: CT600Form): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.postExportRequest(
      `${environment.apiVersionUrl}CT600Pdf/downloadCT600`,
      JSON.stringify(formData),
      headers
    ).pipe(
      switchMap((response: any) => {
        this.download(response);

        return of(true);
      })
    );
  }

  download(response: HttpResponse<Blob>): void {
    const fileName = response.headers
      .get('Content-Disposition')
      ?.split(';')
      .map((x) => (x ?? '').trimStart().split('='))
      .find((x) => x[0] === 'filename')
      ?.pop();

    const a = document.createElement('a');
    let navigator: any;
    navigator = window.navigator;

    if (navigator && navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(response, fileName);
    } else {
      a.href = URL.createObjectURL(response.body || new Blob());
      a.download = fileName ?? '';
      a.click();
    }
  }

  postExportRequest(
    endpoint: string,
    params?: any | null,
    options?: TshqRequestOptions
  ): Observable<HttpResponse<Blob>> {
    return this.http.post(endpoint, params, {
      ...options,
      responseType: 'blob',
      observe: 'response',
    });
  }
}
