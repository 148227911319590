import { CommonModel, Modules } from '@app/core/enums';
import {
  CompanyParameters,
  FileUploadRequestModel,
  MainListParameters,
  MultipleFileDownloadModel,
  QueryParams,
  SaveImportRequestModel,
} from '@app/core/models';
import { Guid } from 'guid-typescript';

export class GetSideList {
  static readonly type = '[SIDELIST] Get Side-List  List ';

  constructor(
    public queryParams: QueryParams,
    public commonModel: CommonModel
  ) {}
}

export class GetMainList {
  static readonly type = '[MAINLIST] Get Main-List List ';

  constructor(
    public moduleId: Modules,
    public queryParams: MainListParameters
  ) {}
}

export class GetTitleList {
  static readonly type = '[TITLE] Get Titles';

  constructor() {
    // do nothing.
  }
}

export class Export {
  static readonly type = '[EXPORT] Export ';

  constructor(public queryParams: any, public moduleId: Modules) {}
}

export class UpdateThemeColor {
  static readonly type = '[COLOR] Update Theme Color';

  constructor(public colorCode: string) {}
}

export class GetLastUsedClient {
  static readonly type = '[USER] Get Last Used Client';
}

export class SaveLastUsedClient {
  static readonly type = '[USER] Save Last Used Client';
  constructor(public clientId: Guid, public clientName?: string) {}
}

// export class ExportReceipt {
//   static readonly type = '[EXPORTRECEIPT] Export Receipt';

//   constructor(public moduleId: Modules, public id: Guid) {}
// }

export class GetAcountingPeriodAndCorporationPeriodList {
  static readonly type =
    '[CT600]Get Acounting Period and Corporation Period List';

  constructor(public returnFormDetailId: Guid) {}
}

export class FileUpload {
  static readonly type = '[FILE] File Upload';

  constructor(public fileUploadRequestModel: FileUploadRequestModel) {}
}

export class Copy {
  static readonly type = '[COPY] Copy';

  constructor(public moduleId: number, public ids: any) {}
}

export class FileDowload {
  static readonly type = '[FILE] File Dowload';

  constructor(public fileData: MultipleFileDownloadModel) {}
}

export class GetAllUsersList {
  static readonly type = '[Users] Get All users List';
}
export class GetPreValidationList {
  static readonly type = '[CT600]Get PreValidation errors List';

  constructor(public returnFormId: Guid, public clientId: Guid) {}
}
export class CheckErrors {
  static readonly type = '[Submission History]Checking Errors';

  constructor(public returnDetyailId: Guid, public clientId: Guid) {}
}
export class SubmissionSummery {
  static readonly type = '[DASHBOARD] Submission Summery';

  constructor(
    public days: number,
    public startdate: string,
    public enddate: string
  ) {}
}
export class SubmittedReturns {
  static readonly type = '[DASHBOARD] Submitted Returns';

  constructor(public year: number) {}
}
export class RefreshStatus {
  static readonly type = '[Submission History] Update Pending Status';

  constructor(public submitId: Guid) {}
}
export class StatusInfo {
  static readonly type = '[Submission History] Getting Status Info';

  constructor(public submitId: Guid, public statusId: number) {}
}
export class GetSMSTemplates {
  static readonly type = '[SMS] Getting SMS Templates';
}
export class SendSMS {
  static readonly type = '[SMS] Send SMS';

  constructor(public formData: any) {}
}

export class GetCompanyList {
  static readonly type = '[COMPANY] Get Company';

  constructor(public queryParams: CompanyParameters) {}
}

export class GetCurrencyList {
  static readonly type = '[CURRENCY] Get Currency';
}

export class GetFileAsByte {
  static readonly type = '[FILE] Get File As Byte';

  constructor(public fileUrl: string) {}
}

export class SaveModuleRequest {
  static readonly type = '[ALL MODULES] Save Module Request';

  constructor(public SaveImportRequestModel: SaveImportRequestModel) {}
}
