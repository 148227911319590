import { Component, ComponentFactoryResolver } from '@angular/core';
import { interval } from 'rxjs';
import { CommonService } from './core/services';
import { AuthResolverService } from './core/guards/auth.resolver';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'CTUI';
  constructor(
    public commonService: CommonService,
    public authResolverService: AuthResolverService,
    public oidcSecurityService: OidcSecurityService,
    private cookieService: CookieService,
    private resolver: ComponentFactoryResolver
  ) {}
  ngOnInit(): void {
    const refreshInterval = 2 * 60 * 1000;

    interval(refreshInterval).subscribe(() => {
      const tempToken = this.cookieService.get('newToken')
        ? this.cookieService.get('newToken')
        : this.oidcSecurityService.getToken();
      if (this.isTokenExpired(tempToken)) {
        this.authResolverService.resolve();
      }
    });
  }

  isTokenExpired(token: string): boolean {
    const decodedToken = this.decodeToken(token);
    if (!decodedToken || !decodedToken.exp) {
      return true; // Token is invalid or doesn't have an expiration
    }

    const currentTime = Math.floor(Date.now() / 1000); // Convert to seconds
    return decodedToken.exp < currentTime;
  }
  decodeToken(token: string): any {
    try {
      const payload = token.split('.')[1];
      const decodedPayload = atob(payload);
      return JSON.parse(decodedPayload);
    } catch (error) {
      return null; // Token decoding failed
    }
  }
}
