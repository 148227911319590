import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dynamic-tab-creation',
  templateUrl: './dynamic-tab-creation.component.html',
  styleUrls: ['./dynamic-tab-creation.component.scss'],
})
export class DynamicTabCreationComponent implements OnInit {
  list: any[] = [
    {
      value: '<html><head><head><body>sonali</body></html>',
      name: '01/03/2021 - 28/02/2022',
    },
    {
      value: '<html><head><head><body>sonali</body></html>',
      name: '01/03/2022 - 31/05/2023',
    },
    {
      value: '<html><head><head><body>sonali</body></html>',
      name: '01/03/2022 - 31/05/2023',
    },
  ];

  constructor(private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    this.spinner.hide();
  }
}
