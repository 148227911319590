import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonService } from '@app/core/services';
import { SubmittedReturns } from '@app/core/store';
import { Store } from '@ngxs/store';

import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip,
  ApexNoData,
} from 'ng-apexcharts';
import { NgxSpinnerService } from 'ngx-spinner';

export type FlowChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
  noData: ApexNoData;
};

@Component({
  selector: 'app-monthly-submitted-return',
  templateUrl: './monthly-submitted-return.component.html',
  styleUrls: ['./monthly-submitted-return.component.scss'],
})
export class MonthlySubmittedReturnComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  @Input() year: number;
  public flowChartOptions: Partial<FlowChartOptions>;
  constructor(
    private store: Store,
    private commonService: CommonService,
    private spinner: NgxSpinnerService
  ) {}
  ngOnInit(): void {
    //do nothing
  }

  getIncomeFlow(list: any): void {
    this.flowChartOptions = {
      series: [
        {
          name: 'Total Submission',
          data: list,
        },
      ],
      chart: {
        type: 'bar',
        height: 280,
        toolbar: {
          show: false,
        },
      },
      noData: {
        text: 'No Data to Display',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: '#139DF4',
          fontSize: '18px',
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      tooltip: {
        marker: {
          show: false,
        },
        enabled: true,
        fillSeriesColor: false,
      },
      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
      },
      fill: {
        opacity: 1,
        colors: ['#7B76D3'],
        gradient: {
          shade: 'light',
          type: 'vertical',
          shadeIntensity: 0.5,
          gradientToColors: ['#22E4A8'],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100, 100, 100],
        },
      },
    };
  }
  isLoadingComplete: boolean = false;
  noOfRecords: number = 0;
  getReturnsList(): void {
    this.isLoadingComplete = false;
    this.spinner.show();
    this.store.dispatch(new SubmittedReturns(this.year)).subscribe((res) => {
      let arr: any = [];
      arr = res.common.returns;
      this.noOfRecords = arr.reduce((a, b) => a + b, 0);

      this.getIncomeFlow(res.common.returns);
      this.isLoadingComplete = true;
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['year']) {
      this.getReturnsList();
    }
  }
}
