import { AttachmentsData, CMPFormData } from '@app/core/models';
import { Guid } from 'guid-typescript';

export class GetAccoumtListData {
  static readonly type = '[Attachemnts] Get Account List Data';
  constructor() {
    // do nothing.
  }
}
export class GetAccoumtCMPData {
  static readonly type = '[Attachemnts] Get Accounts Computation Data';
  constructor(public retirnFormId: Guid) {}
}
export class SaveAccountsCMPValues {
  static readonly type = '[Attachemnts] Save Accounts Computation Data';
  constructor(public accountsCMPData: CMPFormData) {}
}
export class SaveAttachmentsData {
  static readonly type = '[Attachemnts] Save Attachments';
  constructor(public attachments: AttachmentsData) {}
}
export class GetAttachments {
  static readonly type = '[Attachemnts] Get Attachments';
  constructor(public retirnFormId: Guid) {}
}
export class DownloadFile {
  static readonly type = '[Attachemnts] Download File';
  constructor(public retirnFormId: Guid, public recordId: Guid) {}
}
export class DeleteFile {
  static readonly type = '[Attachemnts] Delete File';
  constructor(public retirnFormId: Guid, public recordId: Guid) {}
}
