import { Injectable } from '@angular/core';
import {
  Client,
  ClientContactModel,
  ClientIdModel,
  ClientListModel,
  ClientModel,
  SideListModel,
} from '@app/core/models';
import { ClientService } from '@app/core/services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs';
import {
  CheckClientLimit,
  CreateCLient,
  CreateClientContact,
  DeleteClientContact,
  DeleteClients,
  GetAllClientList,
  GetClientDataByClientId,
  GetClientDetailList,
  GetClientNumber,
  GetContactList,
  GetCountryList,
  GetDataByClientId,
  GetDataByContactId,
  GetDueDaysList,
  GetVATSchemeList,
  GetVATSubmitTypeList,
  SaveClient,
  SetDefaultClientId,
} from './client.action';
import { ActionModel } from '@app/core/models/common/grid-action';

export class ClientStateInfo {
  id?: number;
  clientData?: ClientModel;
  contactData?: ClientContactModel;
  clientList: Array<ClientListModel>;
  exported?: boolean;
  contactList: Array<SideListModel>;
  countryList: Array<SideListModel>;
  dueDaysList: Array<SideListModel>;
  vATSubmitTypeList: Array<SideListModel>;
  vATSchemeList: Array<SideListModel>;
  allClientList: Array<SideListModel>;
  sideListModel?: Array<SideListModel>;
  isClientAdded?: boolean;
  totalRecord?: number;
  message: string;
  clientNo: ClientIdModel;
  clientStatus?: any;
  clientId?: Guid;
  clients?: Client;
  isSuccess?: boolean;
  isDataAvailable?: boolean;
  clientLists: Array<Client>;
  gridActions?: ActionModel[];
}

@State<ClientStateInfo>({
  name: 'client',
  defaults: {
    id: 0,
    clientNo: new ClientIdModel(),
    exported: false,
    contactList: [],
    countryList: [],
    isClientAdded: false,
    vATSubmitTypeList: [],
    vATSchemeList: [],
    message: '',
    clientList: [],
    allClientList: [],
    dueDaysList: [],
    clientLists: [],
  },
})
@Injectable()
export class ClientState {
  constructor(private clientService: ClientService) {}

  @Selector()
  static getVatSchemesList(state: ClientStateInfo) {
    return state.vATSchemeList ?? [];
  }

  @Selector()
  static getVatSubmitTypesList(state: ClientStateInfo) {
    return state.vATSubmitTypeList ?? [];
  }

  @Selector()
  static isDataAvailable(state: ClientStateInfo) {
    return state.isDataAvailable;
  }

  @Selector()
  static totalRecord(state: ClientStateInfo) {
    return state.totalRecord;
  }

  @Selector()
  static getClientDataByClientId(state: ClientStateInfo) {
    return state.clientData;
  }

  @Selector()
  static getClientId(state: ClientStateInfo) {
    return state.clientId;
  }

  @Action(CreateCLient)
  createInvoice(
    { patchState }: StateContext<ClientStateInfo>,
    action: CreateCLient
  ) {
    return this.clientService.createCLient(action.client).pipe(
      tap((res) => {
        patchState({
          id: res.universalId,
          message: res.clientMessage,
          isClientAdded: true,
        });
      })
    );
  }

  @Action(CreateClientContact)
  createClientContact(
    { patchState }: StateContext<ClientStateInfo>,
    action: CreateClientContact
  ) {
    return this.clientService
      .createClientContact(action.clientContactModel)
      .pipe(
        tap((res) => {
          patchState({
            id: res.universalId,
            message: res.clientContactMessage,
            isClientAdded: true,
          });
        })
      );
  }

  @Action(DeleteClientContact)
  deleteClientContact(
    { getState, patchState }: StateContext<ClientStateInfo>,
    action: DeleteClientContact
  ) {
    return this.clientService.deleteClientContact(action.ids);
  }

  @Action(DeleteClients)
  deleteClients(
    { getState, patchState }: StateContext<ClientStateInfo>,
    action: DeleteClients
  ) {
    return this.clientService.deleteClients(action.ids).pipe(
      tap((res) => {
        const state = getState();

        const filteredInvoice = state.clientLists.filter(
          (item) =>
            !action.ids?.includes(
              item.universalId ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        const filteredForSideList = state.sideListModel?.filter(
          (item) =>
            !action.ids?.includes(
              item.universalId ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        patchState({
          clientLists: filteredInvoice,
          sideListModel: filteredForSideList,
        });
      })
    );
  }

  @Action(GetDataByClientId)
  getDataByClientId(
    { patchState }: StateContext<ClientStateInfo>,
    action: GetDataByClientId
  ) {
    return this.clientService.getDataByClientId(action.clientId).pipe(
      tap((res) => {
        patchState({
          clientData: res,
        });
      })
    );
  }

  @Action(GetDataByContactId)
  getDataByContactId(
    { patchState }: StateContext<ClientStateInfo>,
    action: GetDataByContactId
  ) {
    return this.clientService.getDataByContactId(action.contactId).pipe(
      tap((res) => {
        patchState({
          contactData: res,
        });
      })
    );
  }

  @Action(GetClientNumber)
  getClientNumber({ patchState }: StateContext<ClientStateInfo>) {
    return this.clientService.getClientNumber().pipe(
      tap((res) => {
        patchState({
          clientNo: res,
        });
      })
    );
  }

  @Action(GetContactList)
  getContactList({ patchState }: StateContext<ClientStateInfo>) {
    return this.clientService.getContactList().pipe(
      tap((res) => {
        patchState({
          contactList: res,
        });
      })
    );
  }

  @Action(GetCountryList)
  getCountryList({ patchState }: StateContext<ClientStateInfo>) {
    return this.clientService.getCountryList().pipe(
      tap((res) => {
        patchState({
          countryList: res,
        });
      })
    );
  }

  @Action(GetDueDaysList)
  getDueDaysList({ patchState }: StateContext<ClientStateInfo>) {
    return this.clientService.getDueDaysList().pipe(
      tap((res) => {
        patchState({
          dueDaysList: res,
        });
      })
    );
  }

  @Action(GetVATSubmitTypeList)
  getVATSubmitTypeList({ patchState }: StateContext<ClientStateInfo>) {
    return this.clientService.getVATSubmitTypeList().pipe(
      tap((res) => {
        patchState({
          vATSubmitTypeList: res,
        });
      })
    );
  }

  @Action(GetVATSchemeList)
  getVATSchemeList({ patchState }: StateContext<ClientStateInfo>) {
    return this.clientService.getVATSchemeList().pipe(
      tap((res) => {
        patchState({
          vATSchemeList: res,
        });
      })
    );
  }

  @Action(GetAllClientList)
  getAllClientList({ patchState }: StateContext<ClientStateInfo>) {
    return this.clientService.getAllClientList().pipe(
      tap((res) => {
        patchState({
          allClientList: res,
        });
      })
    );
  }

  @Action(CheckClientLimit)
  checkClientLimit({ patchState }: StateContext<ClientStateInfo>) {
    return this.clientService.checkClientLimitByUser().pipe(
      tap((res) => {
        patchState({
          clientStatus: res,
        });
      })
    );
  }

  // new changes
  @Action(GetClientDataByClientId)
  getClientDataByClientId(
    { getState, setState }: StateContext<ClientStateInfo>,
    action: GetClientDataByClientId
  ) {
    return this.clientService.getClientById(action.clientId).pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          clientId: action.clientId,
          clients: res,
        });
      })
    );
  }

  @Action(SaveClient)
  saveClient(
    { getState, patchState }: StateContext<ClientStateInfo>,
    action: SaveClient
  ) {
    return this.clientService.saveClient(action.client).pipe(
      tap((res) => {
        patchState({
          isSuccess: res.isSuccess,
          clientId: res.universalId,
          message: res.clientMessage,
        });
      })
    );
  }

  @Action(GetClientDetailList, { cancelUncompleted: true })
  getClientDetailList(
    { getState, patchState }: StateContext<ClientStateInfo>,
    action: GetClientDetailList
  ) {
    return this.clientService.getClientDetailList(action.queryParams).pipe(
      tap((res) => {
        const state = getState();
        const headers = JSON.parse(res.headers.get('Pagination')!);

        const totalRecord = headers.TotalItemCount;
        const isDataAvailable = headers.IsBusinessDataFound;
        patchState({
          ...state,
          clientLists: res.body!.data,
          gridActions: res.body!.actions,
          totalRecord,
          isDataAvailable,
        });
      })
    );
  }

  @Action(SetDefaultClientId)
  setDefaultClientId({ patchState }: StateContext<ClientStateInfo>) {
    patchState({
      clientId: Guid.EMPTY as unknown as Guid,
    });
  }
}
