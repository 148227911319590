import { Injectable } from '@angular/core';

import { UserService } from '@app/core/services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs';
import {
  CreateUser,
  DeleteUsers,
  GetDataByUserId,
  GetUserPermissionsList,
  SendInvite,
} from './user.action';
import { UserPermission } from '@app/core/models';

export class UserStateInfo {
  id?: Guid;
  userData?: any;
  userList: Array<any>;
  exported?: boolean;
  isUserAdded?: boolean;
  totalRecord?: number;
  message: string;
  isError: boolean;
  isSucess: boolean;
  userPermissionList?: Array<UserPermission>;
}

@State<UserStateInfo>({
  name: 'user',
  defaults: {
    isSucess: false,
    exported: false,
    userList: [],
    isError: false,
    isUserAdded: false,

    message: '',
  },
})
@Injectable()
export class UserState {
  constructor(private userService: UserService) {}

  @Selector()
  static getUserPermissions(state: UserStateInfo) {
    return state.userPermissionList;
  }

  @Action(GetUserPermissionsList)
  getUserPermissionsList({ patchState }: StateContext<UserStateInfo>) {
    return this.userService.getUserPermissions().pipe(
      tap((res) => {
        patchState({
          userPermissionList: res,
        });
      })
    );
  }

  @Action(CreateUser)
  createInvoice(
    { patchState }: StateContext<UserStateInfo>,
    action: CreateUser
  ) {
    return this.userService.createUser(action.user).pipe(
      tap((res) => {
        patchState({
          id: res.universalId,
          message: res.statMsg,
          isError: res.isError,
        });
      })
    );
  }

  @Action(SendInvite)
  sendInvite({ patchState }: StateContext<UserStateInfo>, action: SendInvite) {
    return this.userService.inviteUser(action.userData).pipe(
      tap((res) => {
        patchState({
          message: res.statusMessage,
          isSucess: res.isSuccess,
        });
      })
    );
  }

  @Action(GetDataByUserId)
  getDataByClientId(
    { patchState }: StateContext<UserStateInfo>,
    action: GetDataByUserId
  ) {
    return this.userService.getDataByClientId(action.userId).pipe(
      tap((res) => {
        patchState({
          userData: res,
        });
      })
    );
  }

  @Action(DeleteUsers)
  deleteUsers(
    { getState, patchState }: StateContext<UserStateInfo>,
    action: DeleteUsers
  ) {
    return this.userService.deleteUsers(action.ids);
  }
}
