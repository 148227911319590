import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RandDCreativeExp, RandDExpCredit } from '@app/core/models';
import {
  FilmsEnhanceExpenditure,
  RandDEnahanceExp,
} from '@app/core/models/tasks/quick-entry/films-enhance-expenditure';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FilmsEnhanceExpenditureService {
  constructor(private http: HttpClient, public datePipe: DatePipe) {}

  getFilmsEnhanceExpenditureData(
    returnFormId: Guid
  ): Observable<FilmsEnhanceExpenditure> {
    return this.http.get<FilmsEnhanceExpenditure>(
      `${environment.apiVersionUrl}RandD/getRandDCalData?returnFormId=${returnFormId}`
    );
  }

  saveFilmsEnhanceExpenditure(
    filmsRandDEnahanceExpData: RandDEnahanceExp
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}RandD/saveRandDExpLarge`,
      JSON.stringify(filmsRandDEnahanceExpData),
      headers
    );
  }

  saveCreativeEnhancedExpenditure(
    filmsRandDEnahanceExpData: RandDCreativeExp
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}RandD/saveRandDCreativeExp`,
      JSON.stringify(filmsRandDEnahanceExpData),
      headers
    );
  }

  saveResearchDevelopmentExpCredit(
    filmsRandDEnahanceExpData: RandDExpCredit
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}RandD/SaveRandDExpCredit`,
      JSON.stringify(filmsRandDEnahanceExpData),
      headers
    );
  }

  saveSMErandExpenditure(filmssmeRandDEnahanceExpData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}RandD/saveRandDExpenditure`,
      JSON.stringify(filmssmeRandDEnahanceExpData),
      headers
    );
  }

  getRandDEnhanceExpenditureData(
    returnFormId: Guid,
    typeId: number
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}RandD/getRandDExpenditure?returnFormId=${returnFormId}&type=${typeId}`
    );
  }

  saveSMErandExpenditureDetails(
    filmssmeRandDdataDetails: any
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}RandD/saveSMERandDEnahanceExp`,
      JSON.stringify(filmssmeRandDdataDetails),
      headers
    );
  }

  saveSubcontractorDetails(filmssmeRandDdataDetails: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}RandD/SaveSMESubContractor`,
      JSON.stringify(filmssmeRandDdataDetails),
      headers
    );
  }

  getTypeList(): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${environment.apiVersionUrl}RandD/GetRDECPeriodOfClaim`
    );
  }
}
