import { Guid } from 'guid-typescript';

export class GetComputationData {
  static readonly type = '[Reports] Get CT600 Computation  Data';
  constructor(public retirnFormId: Guid, public clientId: Guid) {}
}

export class DownloadComputation {
  static readonly type = '[Reports] Download Computation';
  constructor(public returnFormId: Guid, public clientId: Guid) {}
}

export class DownloadHTMLComputation {
  static readonly type = '[Reports] Download Computation HTML';
  constructor(public htmlContnet: string, public filename:string, public clientId:Guid) {}
}

export class GetAPReportDetails {
  static readonly type = '[Reports] Get APReport Details';
  constructor(public returnDetailId: Guid, public returnFormId:Guid, public clientId: Guid) {}
}

export class DetachAPReportDetails {
  static readonly type = '[Reports] DETACH APReport Details';
  constructor(public returnFormId:Guid, public clientId: Guid) {}
}

