export enum AssetCategory {
  MachineryAndPlantSpecialRatePool = 1,
  MachineryAndPlantMainPool = 2,
  StructuresAndBuildingsAllowance = 3,
  BPRA = 4,
  OtherChargesAndAllowances = 5,
  ElectricVehicleChargePoints = 6,
  EnterpriseZones = 7,
  ZeroEmissionGoodsVehicle = 8,
  ZeroEmissionCars = 9,
  DesignatedEnhancedAllowance = 10,
  FirstYearAllowance = 10,
  ShortLifeAssets = 11,
}
