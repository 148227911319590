import { Injectable } from '@angular/core';
import {
  Ct600ReturnModel,
  SideListModel,
  ManualPeriodsResponse,
  APReportResponse,
} from '@app/core/models';
import { Ct600ReturnService } from '@app/core/services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs';
import {
  CreateCt600Return,
  DeleteCt600Returns,
  FinalSubmitReturns,
  GetCT600Number,
  GetData,
  GetDataByCt600ReturnId,
  GetDataByManualPeriods,
  GetAPReportPeriods,
  GetEmailData,
  GetReturnDetails,
  SaveDataSecurity,
  SendEmail,
  SubmitReturns,
  UpdateStatusByRecord,
  GetBKAccountingCodes,
  SaveReturnPayment,
  SaveAssociatedCompanies,
  GetAssociatedCompanies,
} from './ct600-return.action';

export class Ct600ReturnStateInfo {
  returnDetailId?: Guid;
  ct600ReturnData?: Ct600ReturnModel;
  ct600ReturnList: Array<Ct600ReturnModel>;
  exported?: boolean;
  sideListModel?: Array<SideListModel>;
  totalRecord?: number;
  message: string;
  SubmissionmessageList: Array<any>;
  reference: Ct600ReturnModel;
  periodList?: ManualPeriodsResponse;
  apPeriodList?: APReportResponse;
  emailData?: any;
  isSuccess: boolean;
  securityData?: any;
  isError: boolean;
  returnDetailsData?: any;
  isPropertyBusiness: boolean;
  bkAccountCodes?: any;
  associatedCompany?:any;
}

@State<Ct600ReturnStateInfo>({
  name: 'ct600Return',
  defaults: {
    exported: false,
    isSuccess: false,
    message: '',
    ct600ReturnList: [],
    reference: new Ct600ReturnModel(),
    isError: false,
    SubmissionmessageList: [],
    isPropertyBusiness: false,
  },
})
@Injectable()
export class Ct600ReturnState {
  constructor(private ct600ReturnService: Ct600ReturnService) {}

  @Selector()
  static getPropertyBusiness(state: Ct600ReturnStateInfo) {
    return state.isPropertyBusiness;
  }
  @Action(CreateCt600Return)
  createCt600Return(
    { patchState }: StateContext<Ct600ReturnStateInfo>,
    action: CreateCt600Return
  ) {
    return this.ct600ReturnService.createCt600Return(action.ct600Return).pipe(
      tap((res) => {
        patchState({
          returnDetailId: res.returnDetailUniversalId,
          message: res.cT600FormMessage,
        });
      })
    );
  }

  @Action(DeleteCt600Returns)
  deleteCt600Returns(
    { getState, patchState }: StateContext<Ct600ReturnStateInfo>,
    action: DeleteCt600Returns
  ) {
    return this.ct600ReturnService.deleteCt600Returns(action.ids).pipe(
      tap((res) => {
        const state = getState();

        const filteredCT600 = state.ct600ReturnList.filter(
          (item) =>
            !action.ids?.includes(
              item.universalId ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        const filteredForSideList = state.sideListModel?.filter(
          (item) =>
            !action.ids?.includes(
              item.universalId ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        patchState({
          ct600ReturnList: filteredCT600,
          sideListModel: filteredForSideList,
        });
      })
    );
  }

  @Action(GetDataByCt600ReturnId)
  getDataByCt600ReturnId(
    { patchState }: StateContext<Ct600ReturnStateInfo>,
    action: GetDataByCt600ReturnId
  ) {
    return this.ct600ReturnService
      .getDataByCt600ReturnId(action.returnDetailId)
      .pipe(
        tap((res) => {
          patchState({
            ct600ReturnData: res,
            isPropertyBusiness: res.isPropertyBusiness,
          });
        })
      );
  }

  @Action(GetDataByManualPeriods)
  getDataByManualPeriods(
    { patchState }: StateContext<Ct600ReturnStateInfo>,
    action: GetDataByManualPeriods
  ) {
    return this.ct600ReturnService.getCt600SelectPeriod(action.clientId).pipe(
      tap((res) => {
        patchState({
          periodList: res,
        });
      })
    );
  }

  @Action(GetAPReportPeriods)
  getAPReportPeriods(
    { patchState }: StateContext<Ct600ReturnStateInfo>,
    action: GetAPReportPeriods
  ) {
    return this.ct600ReturnService
      .getAPReportPeriods(action.apReportModel)
      .pipe(
        tap((res) => {
          patchState({
            apPeriodList: res,
          });
        })
      );
  }

  @Action(GetCT600Number)
  getCT600Number(
    { patchState }: StateContext<Ct600ReturnStateInfo>,
    action: GetCT600Number
  ) {
    return this.ct600ReturnService
      .getCT600Number(action.clientId, action.clientName)
      .pipe(
        tap((res) => {
          patchState({
            reference: res,
          });
        })
      );
  }

  @Action(GetEmailData)
  getEmailData(
    { patchState }: StateContext<Ct600ReturnStateInfo>,
    action: GetEmailData
  ) {
    return this.ct600ReturnService
      .getEmialData(action.clientId, action.returnDetailsId)
      .pipe(
        tap((res) => {
          patchState({
            emailData: res,
          });
        })
      );
  }

  @Action(SendEmail)
  sendEmail(
    { patchState }: StateContext<Ct600ReturnStateInfo>,
    action: SendEmail
  ) {
    return this.ct600ReturnService.sendEmail(action.emailData).pipe(
      tap((res) => {
        patchState({
          isSuccess: res,
        });
      })
    );
  }

  @Action(SaveDataSecurity)
  saveData(
    { patchState }: StateContext<Ct600ReturnStateInfo>,
    action: SaveDataSecurity
  ) {
    return this.ct600ReturnService.saveData(action.formData).pipe(
      tap((res) => {
        patchState({
          isError: res.isError,
          message: res.statusMsg,
        });
      })
    );
  }

  @Action(GetData)
  getData({ patchState }: StateContext<Ct600ReturnStateInfo>, action: GetData) {
    return this.ct600ReturnService.getData(action.clientId).pipe(
      tap((res) => {
        patchState({
          securityData: res,
        });
      })
    );
  }

  @Action(GetReturnDetails)
  getReturnDetails(
    { patchState }: StateContext<Ct600ReturnStateInfo>,
    action: GetReturnDetails
  ) {
    return this.ct600ReturnService.getReturnDetailsData(action.reqData).pipe(
      tap((res) => {
        patchState({
          returnDetailsData: res,
        });
      })
    );
  }

  @Action(SubmitReturns)
  submitReturnDetails(
    { patchState }: StateContext<Ct600ReturnStateInfo>,
    action: SubmitReturns
  ) {
    return this.ct600ReturnService.submitReturns(action.formData).pipe(
      tap((res) => {
        patchState({
          isError: res.isError,
          message: res.message,
          SubmissionmessageList: res?.listStatus,
        });
      })
    );
  }
  @Action(FinalSubmitReturns)
  finalSubmission(
    { patchState }: StateContext<Ct600ReturnStateInfo>,
    action: FinalSubmitReturns
  ) {
    return this.ct600ReturnService
      .finalSubmissionById(action.returnDetailId)
      .pipe(
        tap((res) => {
          patchState({
            isSuccess: res,
          });
        })
      );
  }

  @Action(UpdateStatusByRecord)
  UpdateStatus(
    { patchState }: StateContext<Ct600ReturnStateInfo>,
    action: UpdateStatusByRecord
  ) {
    return this.ct600ReturnService.updateStatus(action.formData).pipe(
      tap((res) => {
        patchState({
          isSuccess: res.status,
          message: res.message,
        });
      })
    );
  }

  @Action(GetBKAccountingCodes)
  getBKAccountingCodes(
    { patchState }: StateContext<Ct600ReturnStateInfo>,
    action: GetBKAccountingCodes
  ) {
    return this.ct600ReturnService
      .getBKAccountingCodes(
        action.clientName,
        action.returnDetailId,
        action.clientId
      )
      .pipe(
        tap((res) => {
          patchState({
            bkAccountCodes: res,
          });
        })
      );
  }

  @Action(SaveReturnPayment)
  saveReturnPayment(
    { patchState }: StateContext<Ct600ReturnStateInfo>,
    action: SaveReturnPayment
  ) {
    return this.ct600ReturnService.saveReturnPayment(action.formData).pipe(
      tap((res) => {
        patchState({
          isError: res.status,
          message: res.message,
        });
      })
    );
  }

  @Action(SaveAssociatedCompanies)
  saveAssociatedCompanies(
    { patchState }: StateContext<Ct600ReturnStateInfo>,
    action: SaveAssociatedCompanies
  ) {
    return this.ct600ReturnService.saveAssociatedCompanies(action.formData).pipe(
      tap((res) => {
        patchState({
          isSuccess: res.isSuccess,
          message: res.statusMessage,
        });
      })
    );
  }

  @Action(GetAssociatedCompanies)
  getAssociatedCompanies(
    { patchState }: StateContext<Ct600ReturnStateInfo>,
    action: GetAssociatedCompanies
  ) {
    return this.ct600ReturnService
      .getAssociatedCompanies(action.returnFormId)
      .pipe(
        tap((res) => {
          patchState({
            associatedCompany: res            
          });
        })
      );
  }

}
