import { Guid } from 'guid-typescript';
import { AddressModel, Contacts } from '../clients';
import { ActionModel } from '../common/grid-action';

export class UserGridModel {
  totalItemCount: number;
  data: Array<UserModel>;
  actions: Array<ActionModel>;
}

export class UserModel {
  universalId?: Guid;
  userId?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  fullName?: string;
  email?: string;
  capacity?: number;
  capacitySpent?: number;
  remainingCapacity?: number;
  billableHours?: number;
  permissionId?: number;
  designation?: string;
  password?: string;
  isArchived?: boolean;
  projectIds?: string;
  isSelected?: boolean = false;
  isManager?: boolean;
  isAssigned?: boolean;
  costRate?: number;
  billableRate?: number;
  permissions?: number;
  permissionName?: string;
  isEmployee?: boolean;
  isTimeSheetNotification?: boolean;
  photo?: string;
  canCreateProjectForAllClient?: boolean;
  canViewBillableRates?: boolean;
  canDelete?: boolean;
  status?: string;
  isInvitedUser?: boolean;
  userCode?: string;
  title?: string;
  addToAllFutureClients: boolean;
  addToAllExistingClients: boolean;
  addToAllFutureTasks: boolean;
  addToAllExistingTasks: boolean;
  identityUserId?: Guid;
  addresses: Array<AddressModel>;
  contacts: Array<Contacts>;
}

export class User {
  universalId?: number;
  name?: string;
}

export class UserTaskModel {
  userId: Guid;
  taskId: Guid;
  universalId: Guid;
  taskName: string;
  isAssigned: boolean;
}

export class UserOverview {
  labels: Array<string>;
  series: Array<number>;
}
