import { Guid } from 'guid-typescript';

export class NonTradingGainsLosses {
  returnFormId?: Guid;
  nonTrdGLIncomeArisingPrdBX195?: number;
  nonTrdGLIncomeLossPrdBX830?: number;
  nonTrdGLLossesUtilisedPrdBX265?: number;
  nonTrdGLSurrenderGrpRelief?: number;
  nonTrdGLLossBFW?: number;
  nonTrdGLLossCFW?: number;
  nonTrdGLLossUtilBFWLoss?: number;
  nonTrdGLLossUtilCPLoss?: number;
  nonTrdGLOtherIncomeLossBFW?: number;
  nonTrdGLOtherIncomeArisingPrdBX205?: number;
  nonTrdGLOtherLossArisingPrdBX820?: number;
  nonTrdGLOtherIncomeLossUtilisedPrd?: number;
  nonTrdGLOtherIncomeLossCFW?: number;
}
