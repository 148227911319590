export enum ExportFormat {
  Unknown = 0,
  CSV = 1,
  Excel = 2,
  PDF = 3,
}

export enum ExportType {
  UnKnown = 0,
  CSV = 1,
  Excel = 2,
  GoogleDrive = 3,
  PDF = 4,
}
