import { Component, ViewChild } from '@angular/core';
import {
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexLegend,
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexTooltip,
  ChartComponent,
} from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  fill: ApexFill;
  legend: ApexLegend;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
  labels: any;
};

@Component({
  selector: 'app-dashboard-return-days',
  templateUrl: './dashboard-return-days.component.html',
  styleUrls: ['./dashboard-return-days.component.scss'],
})
export class DashboardReturnDaysComponent {
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  constructor() {
    this.chartOptions = {
      series: [44, 55, 13, 43],
      chart: {
        height: 450,
        width: 450,
        type: 'donut',
      },
      labels: ['Submitted', 'Due', 'Overdue', 'Data not available'],
      fill: {
        colors: ['#7B76D3', '#7A5295', '#BA5397', '#7B7644'],
      },
      legend: {
        show: true,
        position: 'right',
        horizontalAlign: 'right',
        offsetX: -40,
        offsetY: 30,
        fontSize: '14px',
        fontFamily: 'Source Sans Pro, sans-serif',
        markers: {
          fillColors: ['#7B76D3', '#7A5295', '#BA5397', '#7B7644'],
          offsetY: 3,
          offsetX: -5,
        },
        itemMargin: {
          vertical: 2,
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        marker: {
          show: false,
        },
        enabled: true,
        fillSeriesColor: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    };
  }
}
