import { DetailRoutingType } from '@app/core/enums';
import {
  TradingPPCalculationModel,
  TradingProfitTagsModel,
} from '@app/core/models';
import { Guid } from 'guid-typescript';

export class CreateTradingPPCalculator {
  static readonly type = '[TradingPPCalculator] Create Trading PP Calculator';
  constructor(public tradingPPCalculationData: TradingPPCalculationModel) {}
}

export class CreateTradingPPCalculatorTags {
  static readonly type =
    '[TradingPPCalculator] Create Trading PP Calculator Tag';
  constructor(public tradingProfitTagsData: TradingProfitTagsModel) {}
}

export class GetDataForTradingPPCalculatorTags {
  static readonly type =
    '[TradingPPCalculator] Get Data For Trading PP Calculator Tags';
  constructor(public returnFormId: Guid, public type: DetailRoutingType) {}
}

export class GetDataForTradingPPCalculator {
  static readonly type =
    '[TradingPPCalculator] Get Data For Trading PP Calculator';
  constructor(public returnFormId: Guid) {}
}

export class GetTradingProfitsTagsList {
  static readonly type = '[TradingPPCalculator] GetTradingProfitsTagsList';
  constructor(public caltype: number) {
    // do nothing.
  }
}
