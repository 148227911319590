import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModuleName } from '@app/core/enums';
import { ExportFormat } from '@app/core/enums/export-type';

@Component({
  selector: 'app-card-footer',
  templateUrl: './card-footer.component.html',
  styleUrls: ['./card-footer.component.scss'],
})
export class CardFooterComponent {
  @Input()
  totalSelectedRecords: number;
  moduleName = ModuleName;
  exportType = ExportFormat;
  @Input()
  getModuleName?: string;

  @Input()
  isViewPermission: boolean;

  @Output()
  readonly deleteClick = new EventEmitter<any>();

  @Output()
  readonly exportClick = new EventEmitter<any>();

  @Output()
  readonly copyClick = new EventEmitter<any>();

  @Output()
  readonly cancelSelectionClick = new EventEmitter<any>();

  @Output()
  readonly printClick = new EventEmitter<any>();

  @Output()
  readonly archiveAndRestoreClick = new EventEmitter<any>();

  exportFormat = ExportFormat;

  export(format: number): void {
    this.exportClick.emit(format);
  }

  delete(): void {
    this.deleteClick.emit();
  }

  copy(): void {
    this.copyClick.emit();
  }

  cancelSelection(): void {
    this.cancelSelectionClick.emit();
  }

  print(): void {
    this.printClick.emit();
  }

  archiveAndRestore(isArchive: boolean): void {
    this.archiveAndRestoreClick.emit(isArchive);
  }
}
