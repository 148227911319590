<div class="company-preference content-body-wrapper">
  <div class="content-body">
    <div class="company-preference-info">
      <mat-tab-group
        mat-align-tabs="start"
        disableRipple
        animationDuration="0ms"
        animationDuration="0ms "
        [(selectedIndex)]="selectedTab"
        (selectedTabChange)="onTabClick($event.index)"
      >
        <mat-tab label="User Activity">
          <app-user-activity *ngIf="selectedTab === 0"> </app-user-activity>
        </mat-tab>
        <mat-tab label="Login Activity">
          <app-login-activity *ngIf="selectedTab === 1"> </app-login-activity>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
