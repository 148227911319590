import { Guid } from 'guid-typescript';

export class TradingProfitTagsModel {
  returnFormId: Guid;
  type: number;
  items: TPRequestItem[];
  deletedTags?: number[];
}

export class TPRequestItem {
  description: string;
  amount: string;
  tag: number;
  id: number;
}
