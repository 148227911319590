import { Injectable } from '@angular/core';
import { SideListModel, TPRequestItem } from '@app/core/models';
import { PropertyBusinessCalculatorService } from '@app/core/services';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
import {
  CreatePropertyBCalculator,
  GetDataForPropertBCalculator,
} from './property-business-calculator.action';

export class PropertyBCalculatorStateInfo {
  id?: number;
  tagData?: TPRequestItem[];
  propertyBData?: any;
  tagList?: SideListModel[];
  exported?: boolean;
  totalRecord?: number;
  message: string;
}

@State<PropertyBCalculatorStateInfo>({
  name: 'propertBusinessCalculator',
  defaults: {
    id: 0,
    exported: false,
    message: '',
  },
})
@Injectable()
export class PropertyBCalculatorState {
  constructor(
    private propertyBCalculatorService: PropertyBusinessCalculatorService
  ) {}

  @Action(CreatePropertyBCalculator)
  createTradingPPCalculator(
    { patchState }: StateContext<PropertyBCalculatorStateInfo>,
    action: CreatePropertyBCalculator
  ) {
    return this.propertyBCalculatorService
      .createPropertyBCalculator(action.propertyBCalculationData)
      .pipe(
        tap((res) => {
          patchState({
            id: res.tradingProfitId,
            message: res.tradingProfitMessage,
          });
        })
      );
  }

  // @Action(CreateTradingPPCalculatorTags)
  // createTradingPPCalculatorTags(
  //   { patchState }: StateContext<PropertyBCalculatorStateInfo>,
  //   action: CreateTradingPPCalculatorTags
  // ) {
  //   return this.tradingPPCalculatorService
  //     .createTradingPPCalculatorTags(action.tradingProfitTagsData)
  //     .pipe(
  //       tap((res) => {
  //         patchState({
  //           id: res.accountingPeriodId,
  //           message: res.accountingPeriodMessage,
  //         });
  //       })
  //     );
  // }

  // @Action(GetDataForTradingPPCalculatorTags)
  // getDataForTradingPPCalculatorTags(
  //   { patchState }: StateContext<PropertyBCalculatorStateInfo>,
  //   action: GetDataForTradingPPCalculatorTags
  // ) {
  //   return this.tradingPPCalculatorService
  //     .getDataForTradingPPCalculatorTags(action.returnFormId, action.type)
  //     .pipe(
  //       tap((res) => {
  //         patchState({
  //           tagData: res,
  //         });
  //       })
  //     );
  // }

  @Action(GetDataForPropertBCalculator)
  getDataForTradingPPCalculator(
    { patchState }: StateContext<PropertyBCalculatorStateInfo>,
    action: GetDataForPropertBCalculator
  ) {
    return this.propertyBCalculatorService
      .getDataForPropertyBCalculator(action.returnFormId)
      .pipe(
        tap((res) => {
          patchState({
            propertyBData: res,
          });
        })
      );
  }

  // @Action(GetTradingProfitsTagsList)
  // getTradingProfitsTagsList(
  //   { patchState }: StateContext<PropertyBCalculatorStateInfo>,
  //   action: GetTradingProfitsTagsList
  // ) {
  //   return this.tradingPPCalculatorService.getTradingProfitsTagsList().pipe(
  //     tap((res) => {
  //       patchState({
  //         tagList: res,
  //       });
  //     })
  //   );
  // }
}
