import { DatePipe } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonService } from '@app/core/services';
import { SubmissionSummery } from '@app/core/store';
import { Store } from '@ngxs/store';

import {
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexLegend,
  ApexTooltip,
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ApexResponsive,
  ChartComponent,
  ApexNoData,
} from 'ng-apexcharts';
import { NgxSpinnerService } from 'ngx-spinner';

export type ChartOption = {
  noData: ApexNoData;
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
  legend: ApexLegend;
  plotOptions: ApexPlotOptions;
  fill: ApexFill;
};

@Component({
  selector: 'app-submission-summary-chart',
  templateUrl: './submission-summary-chart.component.html',
  styleUrls: ['./submission-summary-chart.component.scss'],
})
export class SubmissionSummaryChartComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  @Input() inputObj: any;
  public chartOption: Partial<ChartOption>;
  constructor(
    private datepipe: DatePipe,
    private store: Store,
    private commonService: CommonService,
    private spinner: NgxSpinnerService
  ) {}
  ngOnInit(): void {
    //do nothing
  }

  getData(seriesData): void {
    this.chartOption = {
      series: seriesData,
      chart: {
        width: 450,
        type: 'donut',
      },
      noData: {
        text: 'No Data to Display',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: '#6d41a1',
          fontSize: '18px',
        },
      },
      labels: ['Data not available', 'Submitted', 'Due', 'Overdue'],
      legend: {
        show: true,
        position: 'right',
        horizontalAlign: 'right',
        offsetX: -30,
        offsetY: 60,
        markers: {
          fillColors: ['#BA5397', '#7761CD', '#F2816F', '#7A5295'],
        },
      },
      fill: {
        colors: ['#BA5397', '#7761CD', '#F2816F', '#7A5295'],
      },
      tooltip: {
        marker: {
          show: false,
        },
        enabled: true,
        fillSeriesColor: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
              markers: {
                fillColors: ['#BA5397', '#7761CD', '#F2816F'],
              },
            },
          },
        },
      ],
    };
  }
  isLoadingComplete: boolean = false;
  noOfRecords: number = 0;
  getSummeryList(): void {
    this.isLoadingComplete = false;
    this.spinner.show();
    this.store
      .dispatch(
        new SubmissionSummery(
          this.inputObj?.filter,
          this.datepipe
            .transform(this.inputObj?.startDate, 'yyyy-MM-dd')
            ?.toString() ?? '',
          this.datepipe
            .transform(this.inputObj?.endDate, 'yyyy-MM-dd')
            ?.toString() ?? ''
        )
      )
      .subscribe((res) => {
        let arr: any = [];
        arr = res.common.summery;
        this.noOfRecords = arr.reduce((a, b) => a + b, 0);
        this.getData(res.common.summery);
        this.isLoadingComplete = true;
      });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['inputObj']) {
      this.getSummeryList();
    }
  }
}
