import { Injectable } from '@angular/core';
import { AccoutingPeriodModel } from '@app/core/models';
import { AccoutingPeriodService } from '@app/core/services';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
import {
  CreateAccoutingPeriod,
  DeleteAccoutingPeriods,
  GetDataByAccoutingPeriodId,
  GetStartDate,
  OnAutoPeriodGeneration,
  OnCeased,
  OnMarkComparative,
  OnUnlockPeriod,
} from './accounting-period.action';

export class AccoutingPeriodStateInfo {
  id?: number;
  accoutingPeriodData?: AccoutingPeriodModel;
  exported?: boolean;
  totalRecord?: number;
  isLastPage?: boolean;
  message: string;
  status: boolean;
  fromDate: Date;
}

@State<AccoutingPeriodStateInfo>({
  name: 'accoutingPeriod',
  defaults: {
    id: 0,
    exported: false,
    message: '',
    status: false,
    fromDate: new Date(),
  },
})
@Injectable()
export class AccoutingPeriodState {
  constructor(private accoutingPeriodService: AccoutingPeriodService) {}

  @Action(CreateAccoutingPeriod)
  createAccoutingPeriod(
    { patchState }: StateContext<AccoutingPeriodStateInfo>,
    action: CreateAccoutingPeriod
  ) {
    return this.accoutingPeriodService
      .createAccoutingPeriod(action.accoutingPeriodModel)
      .pipe(
        tap((res) => {
          patchState({
            id: res.accountingPeriodId,
            message: res.accountingPeriodMessage,
          });
        })
      );
  }

  @Action(OnUnlockPeriod)
  onUnlockPeriod(
    { patchState }: StateContext<AccoutingPeriodStateInfo>,
    action: OnUnlockPeriod
  ) {
    return this.accoutingPeriodService.onUnlockPeriod(
      action.id,
      action.isUnlockPeriod
    );
  }

  @Action(OnCeased)
  onCeased(
    { patchState }: StateContext<AccoutingPeriodStateInfo>,
    action: OnCeased
  ) {
    return this.accoutingPeriodService.onCeased(action.id, action.isCeased);
  }

  @Action(OnMarkComparative)
  onMarkComparative(
    { patchState }: StateContext<AccoutingPeriodStateInfo>,
    action: OnMarkComparative
  ) {
    return this.accoutingPeriodService.onMarkComparative(
      action.id,
      action.isMarkComparative
    );
  }

  @Action(OnAutoPeriodGeneration)
  onAutoPeriodGeneration(
    { patchState }: StateContext<AccoutingPeriodStateInfo>,
    action: OnAutoPeriodGeneration
  ) {
    return this.accoutingPeriodService.onAutoPeriodGeneration(
      action.id,
      action.isAutoPeriod
    );
  }

  @Action(GetDataByAccoutingPeriodId)
  getDataByAccoutingPeriodId(
    { patchState }: StateContext<AccoutingPeriodStateInfo>,
    action: GetDataByAccoutingPeriodId
  ) {
    return this.accoutingPeriodService
      .getDataByAccoutingPeriodId(action.accountingPeriodId)
      .pipe(
        tap((res) => {
          patchState({
            accoutingPeriodData: res,
          });
        })
      );
  }

  @Action(DeleteAccoutingPeriods)
  deleteAccoutingPeriods(
    { getState, patchState }: StateContext<AccoutingPeriodStateInfo>,
    action: DeleteAccoutingPeriods
  ) {
    return this.accoutingPeriodService.deleteAccoutingPeriods(action.ids).pipe(
      tap((res) => {
        patchState({
          status: res.status,
          message: res.message,
        });
      })
    );
  }

  @Action(GetStartDate)
  getStartDateByClientid(
    { patchState }: StateContext<AccoutingPeriodStateInfo>,
    action: GetStartDate
  ) {
    return this.accoutingPeriodService
      .getStartDateByClientId(action.clientId)
      .pipe(
        tap((res) => {
          patchState({
            fromDate: res?.toDate,
          });
        })
      );
  }
}
