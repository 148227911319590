import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DueDaysEnum, ExportFormat, Modules } from '@app/core/enums';
import { MainListParameters, SideListModel } from '@app/core/models';
import { CommonService } from '@app/core/services';

@Component({
  selector: 'app-login-activity-header',
  templateUrl: './login-activity-header.component.html',
  styleUrls: ['./login-activity-header.component.scss'],
})
export class LoginActivityHeaderComponent implements OnInit {
  headerData: MainListParameters = new MainListParameters();
  exportType = ExportFormat;

  @Output()
  readonly triggerDataFromHeader = new EventEmitter<any>();

  @Input() listParameters: any;

  customDate = false;
  dueDaysList: SideListModel[] = [
    {
      id: DueDaysEnum['Due in 30 days'],
      name: DueDaysEnum[DueDaysEnum['Due in 30 days']],
    },
    {
      id: DueDaysEnum['Due in 60 days'],
      name: DueDaysEnum[DueDaysEnum['Due in 60 days']],
    },
    {
      id: DueDaysEnum['Due in 120 days'],
      name: DueDaysEnum[DueDaysEnum['Due in 120 days']],
    },
    {
      id: DueDaysEnum['Due in 180 days'],
      name: DueDaysEnum[DueDaysEnum['Due in 180 days']],
    },
    {
      id: DueDaysEnum['Due in 360 days'],
      name: DueDaysEnum[DueDaysEnum['Due in 360 days']],
    },
    {
      id: DueDaysEnum['Overdue'],
      name: DueDaysEnum[DueDaysEnum['Overdue']],
    },
  ];

  ct600StatusList: SideListModel[] = [
    {
      id: 1,
      name: 'Online',
    },
    {
      id: 2,
      name: 'Ofline',
    },
  ];

  dueDays = DueDaysEnum['Due in 30 days'];
  statusType = '-1';
  payStatus = '-1';

  constructor(public commonService: CommonService) {}

  ngOnInit(): void {
    // do nothing.
  }

  dataChangeFromHeader(): void {
    const paramter = {
      startDate: this.headerData.startDate,
      endDate: this.headerData.endDate,
      search: this.headerData.search,
      format: this.headerData.format,
      dueDays: this.dueDays,
      statusType: this.statusType,
      payStatus: this.payStatus,
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
    };
    this.triggerDataFromHeader.emit(paramter);
  }

  onSearch(event: any): void {
    this.headerData.search = event.target.value;
    this.dataChangeFromHeader();
  }

  triggerDateChange(data: any): void {
    this.headerData.startDate = data.startDate;
    this.headerData.endDate = data.endDate;
    this.dataChangeFromHeader();

    this.customDate = data.startDate === '';
  }

  export(format: number, isPrint: boolean): void {
    const paramter = {
      startDate: this.headerData.startDate,
      endDate: this.headerData.endDate,
      filter: this.listParameters.filter,
      search: this.headerData.search,
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      format: format,
      isPrint: isPrint,
    };

    this.triggerDataFromHeader.emit(paramter);
  }

  onDropdownChange(value: any): void {
    this.dataChangeFromHeader();
  }

  onAddClick(): void {
    this.commonService.onAddRouting(Modules.Clients);
  }
}
