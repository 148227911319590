import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { NotificationTextMessage } from '@app/core/enums';
import { CommonService } from '@app/core/services';
import { GetDocTemplate, SaveDocTemplate } from '@app/core/store';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-document-templates',
  templateUrl: './document-templates.component.html',
  styleUrls: ['./document-templates.component.scss'],
})
export class DocumentTemplatesComponent implements OnInit {
  docTempForm: FormGroup;

  isExpandAll = false;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  constructor(
    private store: Store,
    private dialog: MatDialog,
    private commonService: CommonService,
    private datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    private router: Router,
    private fb: FormBuilder
  ) {}
  ngOnInit(): void {
    this.setForm();
    this.getDocTemplateData();
  }

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    showToolbar: true,
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [['bold']],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  setForm() {
    this.docTempForm = this.fb.group({
      subject: ['', Validators.required],
      contents: ['', Validators.required],
    });
  }

  getDocTemplateData() {
    this.store.dispatch(new GetDocTemplate()).subscribe((res) => {
      this.docTempForm.patchValue({
        subject: res.setting.docData.subject,
        contents: res.setting.docData.contents,
      });
    });
  }

  saveDocTemplate() {
    let docData = {
      subject: this.docTempForm.controls.subject.value,
      contents: this.docTempForm.controls.contents.value,
    };

    this.store.dispatch(new SaveDocTemplate(docData)).subscribe((res) => {
      if (res.setting.isSucess) {
        this.commonService.onSucess('Document template saved successfully');
      } else {
        this.commonService.onFailure(NotificationTextMessage.errorMessage);
      }
    });
  }
}
