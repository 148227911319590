import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MenuModel, ProfileInfoModel } from '@app/core/models';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  constructor(private http: HttpClient) {}

  getMenus(): Observable<Array<MenuModel>> {
    return this.http.get<Array<MenuModel>>(
      `${environment.apiVersionUrl}Module/getModuleDetails`
    );
  }

  getProfileInfo(): Observable<ProfileInfoModel> {
    return this.http.get<ProfileInfoModel>(
      `${environment.apiVersionUrl}/User/getUserDetails`
    );
  }

  getAddMenu(): Observable<Array<MenuModel>> {
    return this.http.get<Array<MenuModel>>(
      `${environment.apiVersionUrl}ModuleRolePermission/addModules`
    );
  }
}
