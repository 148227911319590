export enum RoutingPath {
  Dashboard = 'dashboard',
  AddClient = '/client/addclient',
  AddContact = '/client/addContact',
  Ct600return = '/submissions/task/ct600return',
  // EditCT600Return = '/submissions/task/ct600return/edit',
  AddCT600Return = '/submissions/task/ct600return/add',
  AddBack = '/submissions/task/quickEntry/addDetails',
  AddCorporationTax = '/submissions/task/quickEntry/addCACalculator',
  AssetsSummary = '/submissions/task/quickEntry/assetsSummary',
  FilmsExpenditure = '/submissions/task/quickEntry/filmsExpenditure',
  Accountingperiod = '/submissions/task/accountingperiod',
  AddAccountingPeriod = '/submissions/task/accountingperiod/add',
  CT600Form = '/submissions/task/ct600Form/',
  Computation = '/submissions/task/computation/',
  Accounts = '/submissions/task/accounts',
  Attachments = '/submissions/task/attachments/',
  Email = '/submissions/task/email/',
  User = '/manage/users',
  AddUser = '/manage/addUser',
  SendSMS = '/submissions/task/addSendSMS/',
  Prevalidation = '/submissions/task/prevalidation/',
  submission = '/submissions/task/submissions/',
  Client = '/manage/clients',
  AddManageClient = '/manage/addClient',
  accessDenied = 'access-denied',
  TaskSubmit = '/submissions/task/submit',
}
