export enum ActionType {
  Create = 1,
  Edit = 2,
  Archive = 3,
  Restore = 4,
  Delete = 5,
  Export = 6,
  UnlockPeriod = 7,
  Ceased = 8,
  MarkComparative = 9,
  SendToCapsign = 10,
  Copy = 11,
  Submit = 12,
}

export enum GridActionsText {
  Create = 'Create',
  Edit = 'Edit',
  Delete = 'Delete',
  View = 'View',
  Archive = 'Archive',
  Restore = 'Restore',
  Copy = 'Copy',
  Excel = 'Excel',
  CSV = 'CSV',
  PDF = 'PDF',
  Print = 'Print',
  MarkAsCompleted = 'Mark As Completed',
  MarkAsYetToStart = 'Mark As Yet To Start',
  Comment = 'Comment',
  File = 'File',
  Preview = 'Preview',
  Email = 'Email',
}
