import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';

import { DummyService, NotificationService } from '@app/core/services';

import { AutoFocusDirective } from '@app/core/directives/auto-focus-directive';
import { OnlynumberDirective } from '@app/core/directives/onlynumber';
import { TwoDigitDecimalWithNegativeNumberDirective } from '@app/core/directives/two-digit-decima-number-with-negative.directive';
import { TwoDigitDecimaNumberDirective } from '@app/core/directives/two-digit-decima-number.directive';
import { SafeHtmlNewPipe } from '@app/core/pipes';
import { UseEnumValuePipe } from '@app/core/pipes/use-enum-value.pipe';
import {
  NgShortcutConfig,
  NgShortcutModule,
  NgShortcutService,
} from 'ng-shortcut';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import {
  AddClosePopupComponent,
  AttachmentPopupComponent,
  ButtonsComponent,
  CardFooterComponent,
  ConfirmationBoxComponent,
  DynamicTabCreationComponent,
  MoreGridActionsComponent,
  NoDataFoundComponent,
  PageNotFoundComponent,
  SideListActionsComponent,
  SideListComponent,
  SideMenuComponent,
} from './common';
import {
  CustomNotificationsComponent,
  CustomPaginatorComponent,
} from './custom';
import CustomDatePickerComponent from './custom/custom-date-picker/custom-date-picker.component';
import { CustomDayPickerComponent } from './custom/custom-day-picker/custom-day-picker.component';
import CustomYearPickerComponent from './custom/custom-year-picker/custom-year-picker.component';

const shortcutConfig: NgShortcutConfig[] = [
  {
    id: '@save',
    key: 's',
    preventDefault: true,
    ctrlKey: true,
  },
  {
    id: '@saveAndExits',
    key: 'e',
    preventDefault: true,
    ctrlKey: true,
  },
  {
    id: '@cancel',
    key: 'x',
    preventDefault: true,
    ctrlKey: true,
  },
];

@NgModule({
  declarations: [
    SideListComponent,
    AddClosePopupComponent,
    AttachmentPopupComponent,
    CardFooterComponent,
    ConfirmationBoxComponent,
    PageNotFoundComponent,
    SideListActionsComponent,
    CustomPaginatorComponent,
    CustomYearPickerComponent,
    CustomNotificationsComponent,
    ButtonsComponent,
    SideMenuComponent,
    DynamicTabCreationComponent,
    MoreGridActionsComponent,
    TwoDigitDecimaNumberDirective,
    TwoDigitDecimalWithNegativeNumberDirective,
    AutoFocusDirective,
    UseEnumValuePipe,
    NoDataFoundComponent,
    OnlynumberDirective,
    CustomDatePickerComponent,
    CustomDayPickerComponent,
    SafeHtmlNewPipe,
  ],

  imports: [
    CommonModule,
    AngularMaterialModule,
    NgxSpinnerModule.forRoot({ type: 'ball-spin-fade' }),
    NgShortcutModule.forRoot(shortcutConfig),
  ],
  providers: [NotificationService, DummyService, NgShortcutService],
  exports: [
    SafeHtmlNewPipe,
    SideListComponent,
    AddClosePopupComponent,
    AttachmentPopupComponent,
    CardFooterComponent,
    ConfirmationBoxComponent,
    PageNotFoundComponent,
    SideListActionsComponent,
    CustomPaginatorComponent,
    CustomYearPickerComponent,
    CustomNotificationsComponent,
    ButtonsComponent,
    SideMenuComponent,
    DynamicTabCreationComponent,
    MoreGridActionsComponent,
    NgxSpinnerModule,
    TwoDigitDecimaNumberDirective,
    TwoDigitDecimalWithNegativeNumberDirective,
    AutoFocusDirective,
    UseEnumValuePipe,
    NoDataFoundComponent,
    OnlynumberDirective,
    CustomDatePickerComponent,
    CustomDayPickerComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ComponentsModule {}
