import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';
import {
  CommonModel,
  ConfirmationType,
  Modules,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
  PermissionType,
  RoutingPath,
} from '@app/core/enums';
import { SortOrdering } from '@app/core/enums/sort-ordering';
import {
  AcountingPrdAndCorporationPrd,
  CompanyModel,
  CompanyParameters,
  Currency,
  FileUploadRequestModel,
  MainListParameters,
  MenuModel,
  MultipleFileDownloadModel,
  QueryParams,
  SaveImportRequestModel,
  SideListModel,
  TshqRequestOptions,
} from '@app/core/models';
import { HighlightRow, NotificationService } from '@app/core/services';
import { ConfirmationBoxComponent } from '@app/core/sharedmodules/components/common/confirmation-box/confirmation-box.component';
import { MenuState, SetSubMenuData } from '@app/core/store';
import { environment } from '@environments/environment';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

@Injectable()
export class CommonService {
  currentURl = 'dashboard';
  private tenantName = 'CT';
  private selectedSubject = new Subject<any>();
  public defaultGuidValue = Guid.EMPTY as unknown as Guid;
  private selectedAccountingPeriod = new Subject<any>();
  private supplementoryAction = new Subject<any>();
  public isEmailValid = true;
  public isPhoneValid = true;

  menuData: Array<MenuModel>;

  @Select(MenuState.getMenu)
  menuList$: Observable<Array<MenuModel>>;

  constructor(
    private http: HttpClient,
    private notifier: NotificationService,
    private router: Router,
    private highlightRow: HighlightRow,
    private dialog: MatDialog,
    private cookieService: CookieService,
    private store: Store
  ) {}

  setTenantName(name: string): void {
    this.tenantName = name;
  }

  getFileAsByte(fileUrl: string): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}User/getUserPhotBytes`,
      JSON.stringify(fileUrl),
      headers
    );
  }

  getCurrencyList(): Observable<Array<Currency>> {
    return this.http.get<Array<Currency>>(
      `${environment.apiVersionUrl}Currency/list`
    );
  }

  getCompanyList(
    queryParams: CompanyParameters
  ): Observable<Array<CompanyModel>> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Client/getCompany?companyName=${queryParams.companyName}&pageNumber=${queryParams.pageNumber}&pageSize=${queryParams.pageSize}`
    );
  }

  isEmpty(id: any): boolean {
    return id === this.defaultGuidValue;
  }

  getTitleList(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}api/Business/getTitles`
    );
  }

  postExportRequest(
    endpoint: string,
    params?: any | null,
    options?: TshqRequestOptions
  ): Observable<HttpResponse<Blob>> {
    return this.http.post(endpoint, params, {
      ...options,
      responseType: 'blob',
      observe: 'response',
    });
  }

  print(body: Blob): void {
    const file = new Blob([body], {
      type: 'application/pdf',
    });
    const blobUrl = URL.createObjectURL(file);
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = blobUrl;
    document.body.appendChild(iframe);
    iframe.contentWindow?.print();
  }

  download(response: HttpResponse<Blob>): void {
    const fileName = response.headers
      .get('Content-Disposition')
      ?.split(';')
      .map((x) => (x ?? '').trimStart().split('='))
      .find((x) => x[0] === 'filename')
      ?.pop();

    const a = document.createElement('a');
    let navigator: any;
    navigator = window.navigator;

    if (navigator && navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(response, fileName);
    } else {
      a.href = URL.createObjectURL(response.body || new Blob());
      a.download = fileName ?? '';
      a.click();
    }
  }

  fileUpload(fileUploadRequestModel: FileUploadRequestModel): Observable<any> {
    const formData = new FormData();

    fileUploadRequestModel.file.forEach((x) =>
      formData.append('files', x, x.name)
    );

    formData.append(
      'attachmentType',
      fileUploadRequestModel.attachmentType.toString()
    );

    return this.http.post<any>(
      `${environment.apiVersionUrl}user/uploadUserPhoto`,
      formData
    );
  }

  getSideList(
    queryParams: QueryParams,
    commonModel: CommonModel
  ): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Common/getSideModuleList?moduleId=${commonModel.moduleId}&clientId=${commonModel.clientId}`,
      JSON.stringify(queryParams),
      httpOptions
    );
  }

  getExportApiPath(moduleId): string {
    let exportApiPath;
    switch (moduleId) {
      case Modules.Clients:
        exportApiPath = 'Client/exportClients';
        break;

      case Modules.AccountingPeriod:
        exportApiPath = 'AccountingPeriod/exportAccountingPeriod';
        break;

      case Modules.CT600Return:
        exportApiPath = 'CT600Form/exportCT600Returns';
        break;

      case Modules.EmailLog:
        exportApiPath = 'User/exportEmailLog';
        break;
      case Modules.UserLog:
        exportApiPath = 'User/exportUserLog';
        break;
      case Modules.SubmissionHistory:
        exportApiPath = 'Submission/exportSubmissions';
        break;
      case Modules.Users:
        exportApiPath = 'User/exportUsers';
        break;
      case Modules.CapitalAllowancesCalculator:
        exportApiPath = 'CapitalAllowance/exportCapAllowance';
        break;
      case Modules.SubmissionHistorySubRecord:
        exportApiPath = 'Submission/downloadHMRCResponse';
        break;
    }
    return exportApiPath;
  }

  export(queryParams: any, moduleId: Modules): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.postExportRequest(
      `${environment.apiVersionUrl}${this.getExportApiPath(moduleId)}`,
      JSON.stringify(queryParams),
      headers
    ).pipe(
      switchMap((response: any) => {
        const body: Blob = response.body || new Blob();
        if (queryParams.isPrint) {
          this.print(body);
        } else {
          this.download(response);
        }
        return of(true);
      })
    );
  }

  addValidation(form: any): void {
    (Object as any).values(form.controls).forEach((c) => c.markAsTouched());
  }

  setClientId(clientId: Guid, clientName?: any): void {
    if (clientId) {
      this.cookieService.delete('clientId');
    }
    if (clientName) {
      this.cookieService.delete('clientName');
    }

    this.cookieService.set('clientId', clientId.toString());
    if (clientName !== null && clientName !== '' && clientName !== undefined) {
      this.cookieService.set('clientName', clientName.toString());
    }
  }
  setReturnFormd(returnForm: Guid): void {
    sessionStorage.setItem('returnFormId', returnForm.toString());
  }

  onSucess(message?: any): void {
    this.notifier.success(
      NotificationHeader.success,
      message === null ? NotificationTextMessage.successMessage : message
    );
  }

  deleteClick(moduleId: Modules, ids: any): boolean {
    if (ids.length > 0) {
      this.dialog
        .open(ConfirmationBoxComponent, {
          data: {
            ids: ids,
            type: ConfirmationType.Delete,
            moduleId: moduleId,
            totalNumberOfRecordSelected: ids.length,
            headerText: NotificationHeader.deleteConfirmation,
            detailText: NotificationDetails.deleteAllDetailText,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          return result; // doubt
        });
    }

    return false;
  }

  convertStringToGuid(guid: string): Guid {
    return guid as unknown as Guid; // maybe add validation that the parameter is an actual guid ?
  }

  setHighlightData(
    id: Guid,
    isExit: boolean,
    moduleId: number,
    routingPath: RoutingPath
  ): void {
    if (!isExit) {
      if (id === this.defaultGuidValue) {
        this.highlightRow.sideListHighlighted.isHighlighted = true;
        this.highlightRow.sideListHighlighted.sortBy = SortOrdering.createdOn;
      } else {
        this.highlightRow.sideListHighlighted.isHighlighted = false;
        this.highlightRow.sideListHighlighted.sortBy = '';

        this.router.navigate([routingPath], {
          queryParamsHandling: 'preserve',
        });
      }
    } else {
      this.highlightRow.mainListHighlighted.isHighlighted = true;
      this.highlightRow.mainListHighlighted.sortBy =
        id === this.defaultGuidValue
          ? SortOrdering.createdOn
          : SortOrdering.updatedOn;
      this.highlightRow.mainListHighlighted.moduleId = moduleId;
    }
  }

  onFailure(errorMessage: any): void {
    this.notifier.error(NotificationHeader.error, errorMessage);
  }

  downloadFile(fileData: MultipleFileDownloadModel): Observable<any> {
    return this.postExportRequest(
      `${environment.apiVersionUrl}FileUpload/multipledownload`,
      fileData
    ).pipe(
      switchMap((response) => {
        this.download(response);
        return of(true);
      })
    );
  }

  onEditRouting(
    moduleId: number,
    id: any,
    isEdit?: boolean,
    isFrom?: number,
    submitId?: Guid
  ): void {
    const params = { id: btoa(id) };

    switch (moduleId) {
      case Modules.Clients:
        this.router.navigate([RoutingPath.AddManageClient, params]);
        break;
      case Modules.Contact:
        this.router.navigate([RoutingPath.AddContact, params]);
        break;
      case Modules.CT600Return:
        const navigationExtras: NavigationExtras = {
          state: {
            isEditStatus: isEdit,
          },
        };
        switch (isFrom) {
          case Modules.Computation:
            this.router.navigate([RoutingPath.Computation + id]);
            break;

          case Modules.Attachments:
            this.router.navigate([RoutingPath.Attachments + id]);
            break;
          case Modules.CT600Form:
            this.router.navigate([RoutingPath.CT600Form + id]);
            break;

          case Modules.Email:
            this.router.navigate([RoutingPath.Email + id]);
            break;

          default:
            this.router.navigate(
              [RoutingPath.AddCT600Return, params],
              navigationExtras
            );
            break;
        }

        break;

      case Modules.PreValidation:
        this.router.navigate([RoutingPath.Prevalidation + id + '/' + submitId]);
        break;
      case Modules.Submission:
        this.router.navigate([RoutingPath.submission + id + '/' + submitId]);
        break;
      case Modules.AccountingPeriod:
        this.router.navigate([RoutingPath.AddAccountingPeriod, params]);
        break;
      case Modules.Users:
        this.router.navigate([RoutingPath.AddUser, params]);
        break;

      default:
        this.router.navigate([RoutingPath.Dashboard]);
        break;
    }
  }

  onAddRouting(moduleId: number): void {
    switch (moduleId) {
      case Modules.Clients:
        this.router.navigate([RoutingPath.AddManageClient]);
        break;

      case Modules.AccountingPeriod:
        this.router.navigate([RoutingPath.AddAccountingPeriod]);
        break;

      case Modules.Contact:
        this.router.navigate([RoutingPath.AddContact]);
        break;

      case Modules.CT600Return:
        this.router.navigate([RoutingPath.AddCT600Return]);
        break;

      case Modules.Users:
        this.router.navigate([RoutingPath.AddUser]);
        break;

      default:
        this.router.navigate([RoutingPath.Dashboard]);
        break;
    }
  }

  onCT600DetailsRouting(moduleId: number, returnDetailsId: Guid): void {
    switch (moduleId) {
      case Modules.CT600Form:
        this.router.navigate([RoutingPath.CT600Form + returnDetailsId]);
        break;

      case Modules.Computation:
        this.router.navigate([RoutingPath.Computation + returnDetailsId]);
        break;

      case Modules.Accounts:
        this.router.navigate([RoutingPath.Accounts]);
        break;

      case Modules.Attachments:
        this.router.navigate([RoutingPath.Attachments + returnDetailsId]);
        break;

      case Modules.Email:
        this.router.navigate([RoutingPath.Email + returnDetailsId]);
        break;
    }
  }

  updateThemeColor(themecolor: string): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}User/updateThemeColor`,
      JSON.stringify(themecolor),
      headers
    );
  }

  saveLastUsedClient(clientId: Guid): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}User/saveLastUsedClient`,
      JSON.stringify(clientId),
      headers
    );
  }

  getLastUsedClient(): Observable<SideListModel> {
    return this.http.get<SideListModel>(
      `${environment.apiVersionUrl}User/getLastUsedClient`
    );
  }

  getAcountingPeriodAndCorporationPeriodList(
    returnDetailGuid: Guid
  ): Observable<AcountingPrdAndCorporationPrd> {
    return this.http.get<AcountingPrdAndCorporationPrd>(
      `${environment.apiVersionUrl}CT600Form/getAPandCPList/${returnDetailGuid}`
    );
  }

  getEmailLogList(queryParams: MainListParameters): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}User/getEmailLog`,
      JSON.stringify(queryParams),
      headers
    );
  }

  getUserLogList(queryParams: MainListParameters): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}User/getUserLog`,
      JSON.stringify(queryParams),
      headers
    );
  }
  getSubmissionList(queryParams: MainListParameters): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Submission/getSubmissionData`,
      JSON.stringify(queryParams),
      headers
    );
  }

  getUsersList(queryParams: MainListParameters): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}User/getUserList`,
      JSON.stringify(queryParams),
      headers
    );
  }

  onThemeMessage(): Observable<any> {
    return this.selectedSubject.asObservable();
  }

  checkDateValidation(formControl: any): boolean {
    return formControl.hasError('invalidDate');
  }

  checkDatesRange(fDate: any, tDate: any, dateCheck: any): boolean {
    let d1 = fDate.split('/');
    let d2 = tDate.split('/');
    let c = dateCheck.split('/');

    let fromDate = new Date(d1[2], parseInt(d1[1]) - 1, d1[0]); // -1 because months are from 0 to 11
    let toDate = new Date(d2[2], parseInt(d2[1]) - 1, d2[0]);
    let checkDate = new Date(c[2], parseInt(c[1]) - 1, c[0]);

    return checkDate > fromDate && checkDate < toDate;
  }

  validateDate(event): void {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46, 109, 111, 191, 189];

    if (
      !(
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105) ||
        excludedKeys.includes(keyCode)
      )
    ) {
      event.preventDefault();
    }
  }
  sendMessage(periodId: Guid) {
    this.selectedAccountingPeriod.next(periodId);
  }

  clearMessages() {
    this.selectedAccountingPeriod.next(null);
  }

  onMessage(): Observable<any> {
    return this.selectedAccountingPeriod.asObservable();
  }

  sendAlert(action: boolean) {
    this.supplementoryAction.next(action);
  }

  clearAlerts() {
    this.supplementoryAction.next(null);
  }

  onAlert(): Observable<any> {
    return this.supplementoryAction.asObservable();
  }

  formatNumber(val: any) {
    return val == null ? '0.00' : parseFloat(val).toFixed(2);
  }

  getAllUsersList(): Observable<Array<SideListModel>> {
    return this.http.get<Array<any>>(
      `${environment.apiVersionUrl}User/getUsers`
    );
  }
  getErrorReport(returnDetailGuid: Guid, clientId: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Submission/checkPrevalidationErrors?returnFormId=${returnDetailGuid}&clientId=${clientId}`
    );
  }
  getCheckErrors(returnDetailGuid: Guid, clientId: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Submission/checkReturnErrors?returnDetailId=${returnDetailGuid}&clientId=${clientId}`
    );
  }

  getSubmissionSummery(
    days: number,
    startdate: string,
    enddate: string
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Dashboard/getDashboardPieChart?days=${days}&startDate=${startdate}&endDate=${enddate}`
    );
  }
  getSubmittedReturns(year: number): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Dashboard/getDashboardBarChart?year=${year}`
    );
  }
  updateStatus(submitId: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Submission/updateReturnStatus?submitId=${submitId}`
    );
  }
  getInfoByStatus(submitId: Guid, type: number): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Submission/getReturnStatus?submitId=${submitId}&type=${type}`
    );
  }

  getSMSTemplates(): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Submission/getSMSTemplate`
    );
  }

  sendSms(formData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Submission/sendSMS`,
      JSON.stringify(formData),
      headers
    );
  }

  saveModuleRequest(reqBody: SaveImportRequestModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<any>(
      `${environment.apiVersionUrl}/User/saveModuleRequest`,
      JSON.stringify(reqBody),
      headers
    );
  }

  changeOfRoutes(): void {
    this.menuList$?.subscribe((data) => {
      let urlIndex = '';
      if (this.router.url.indexOf(';') > 0) {
        urlIndex = ';';
      }
      if (this.router.url.indexOf('?') > 0) {
        urlIndex = '?';
      }
      const currentUrl =
        this.router.url.indexOf(urlIndex) > 0
          ? this.router.url.split(urlIndex)[0]
          : this.router.url;
      this.setData(currentUrl, data);
    });
  }

  setData(currentUrl: any, data: any): void {
    data?.forEach((x) => {
      if (x.url === currentUrl || x.addURL === currentUrl) {
        const filteredMenu = x.subMenu.filter(
          (x) => x.url === currentUrl || x.addURL === currentUrl
        )[0];
        this.store
          .dispatch(new SetSubMenuData(x.subMenu, filteredMenu))
          .subscribe();
      } else {
        x.subMenu.map((y) => {
          const checkSubMenu = (submenu: any[]) => {
            submenu.forEach((submenuItem) => {
              if (
                currentUrl.includes(submenuItem.url) ||
                currentUrl.includes(submenuItem.addURL)
              ) {
                this.store
                  .dispatch(new SetSubMenuData(x.subMenu, submenuItem))
                  .subscribe();
              } else if (submenuItem.subMenu) {
                checkSubMenu(submenuItem.subMenu); // Recursively check nested submenus
              }
            });
          };

          if (y.url === currentUrl || y.addURL === currentUrl) {
            this.store.dispatch(new SetSubMenuData(x.subMenu, y)).subscribe();
          } else {
            checkSubMenu(y.subMenu); // Check nested submenus
          }
        });
      }
    });
  }

  checkPermission(
    mainMenu: number,
    childMenu: number,
    isCheckViewOnly?: boolean
  ): boolean {
    let isChangePermission = true;
    this.menuList$.pipe(take(1)).subscribe((data) => {
      for (const menu of data) {
        if (menu.id === mainMenu && menu.subMenu) {
          const isSubMenuPresent = menu.subMenu.some(
            (submenu) => submenu.id === childMenu
          );

          if (!isSubMenuPresent) {
            isChangePermission = false;
            return;
          }

          for (const submenu of menu.subMenu) {
            if (isCheckViewOnly) {
              if (
                submenu.id === childMenu &&
                submenu.permissionId === PermissionType.None
              ) {
                isChangePermission = false;
                return;
              }
            } else {
              if (
                submenu.id === childMenu &&
                submenu.permissionId !== PermissionType.Full
              ) {
                isChangePermission = false;
                return;
              }
            }
          }
        }
      }
    });

    return isChangePermission;
  }
}
