import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AssestCalculation,
  AssestDetail,
  AssetSummaryModel,
  CaCalculationModel,
  CalCapitalAllowanceModel,
  CaSummaryModel,
  MainListParameters,
  SideListModel,
} from '@app/core/models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CACalculatorService {
  constructor(private http: HttpClient, public datePipe: DatePipe) {}

  getCACalculatorList(parameters: MainListParameters): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}CapitalAllowance/getCapitalAssetList`,
      JSON.stringify(parameters),
      headers
    );
  }

  getCapitalAllowanceSummary(Ct600ReturnId: Guid): Observable<CaSummaryModel> {
    return this.http.get<CaSummaryModel>(
      `${environment.apiVersionUrl}CapitalAllowance/getCapitalAllowanceSummary/${Ct600ReturnId}`
    );
  }

  deleteCapitalAllowance(id: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}CapitalAllowance/deleteCapitalAsset`,
      JSON.stringify(id),
      headers
    );
  }

  getAssetCategoryList(Ct600ReturnId: Guid): Observable<SideListModel[]> {
    return this.http.get<SideListModel[]>(
      `${environment.apiVersionUrl}CapitalAllowance/getAssetCategories/${Ct600ReturnId}`
    );
  }

  getAssetCategoryDetails(): Observable<AssestDetail[]> {
    return this.http.get<AssestDetail[]>(
      `${environment.apiVersionUrl}CapitalAllowance/getAssetDetailLabelList`
    );
  }

  getCapitalAllowanceData(Ct600ReturnId: Guid): Observable<CaCalculationModel> {
    return this.http.get<CaCalculationModel>(
      `${environment.apiVersionUrl}CapitalAllowance/getAssetDetail/${Ct600ReturnId}`
    );
  }

  getAssetCategoryCalculation(
    ct600ReturnId: Guid,
    assetCategoryId: Guid,
    assetAcqDate: string
  ): Observable<AssestCalculation> {
    return this.http.get<AssestCalculation>(
      `${
        environment.apiVersionUrl
      }CapitalAllowance/getAssetCategoryCalc/${assetCategoryId}/${ct600ReturnId}/${this.datePipe.transform(
        assetAcqDate,
        'yyyy-MM-dd'
      )}`
    );
  }

  saveCACalculationData(
    CaCalculationdData: CaCalculationModel
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}CapitalAllowance/saveCapitalAsset`,
      JSON.stringify(CaCalculationdData),
      headers
    );
  }

  calCapitalAllowanceData(
    calCapitalAllowance: CalCapitalAllowanceModel
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}CapitalAllowance/calCapitalAllowance`,
      JSON.stringify(calCapitalAllowance),
      headers
    );
  }

  saveAssetSummary(assetsummarList: AssetSummaryModel[]): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<any>(
      `${environment.apiVersionUrl}CapitalAllowance/saveCASummaryData`,
      JSON.stringify(assetsummarList),
      headers
    );
  }
}
