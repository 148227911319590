<div class="side-list-actions" *ngIf="numberOfRecords > 0">
  <div class="content-body">
    <div class="mb-2">
      <div class="mb-5 d-flex align-items-center justify-space-between">
        <p class="text-muted fw-bold m-0">Selected Items</p>
        <button
          mat-button
          class="text-gray-300"
          disableRipple
          (click)="cancelSelection()"
        >
          <mat-icon>highlight_off</mat-icon>
        </button>
      </div>
      <div>
        <p class="font-size-18 fw-bold">
          <span class="text-muted font-size-20">Records:</span>
          {{ numberOfRecords }}
        </p>
        <a
          class="hyperlink"
          href="javascript:void(0)"
          (click)="cancelSelection()"
          >Cancel Selection</a
        >
      </div>
    </div>

    <div>
      <div>
        <div
          *ngIf="getModuleId === moduleEnum.Clients"
          class="d-flex align-items-center gap-20 mb-20"
        >
          <span>Due Days </span>
          <div class="form-field mb-0">
            <mat-form-field class="w-150">
              <mat-select
                panelClass="mat-select-position"
                [disableOptionCentering]="true"
                [(ngModel)]="dueDays"
              >
                <mat-option *ngFor="let cl of dueDaysList" [value]="cl.id">
                  <span title="{{ cl.name }}">{{ cl.name }}</span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <hr class="hr" />

        <div class="mt-1">
          <p class="font-size-18 fw-bold mb-2">Edit</p>
          <div class="d-flex align-items-center">
            <button
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              (click)="delete()"
              [disabled]="isViewPermission"
            >
              <mat-icon>delete_outline</mat-icon>
              <p>Delete</p>
            </button>
            <button
              style="display: none"
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              (click)="copy()"
              [disabled]="isViewPermission"
            >
              <mat-icon>content_copy</mat-icon>
              <p>Copy</p>
            </button>
          </div>
        </div>
        <div class="mt-2">
          <p class="font-size-18 fw-bold mb-2">Export</p>
          <div class="d-flex align-items-center">
            <button
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              (click)="export(exportFormat.Excel)"
              [disabled]="isViewPermission"
            >
              <mat-icon>border_all</mat-icon>
              <p>Excel</p>
            </button>
            <button
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              (click)="export(exportFormat.CSV)"
              [disabled]="isViewPermission"
            >
              <mat-icon fontSet="material-icons-outlined">description</mat-icon>
              <p>CSV</p>
            </button>
            <button
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              (click)="export(exportFormat.PDF)"
              [disabled]="isViewPermission"
            >
              <mat-icon fontSet="material-icons-outlined"
                >picture_as_pdf</mat-icon
              >
              <p>PDF</p>
            </button>
          </div>
        </div>
      </div>

      <div>
        <div class="mt-2 mb-2">
          <p class="font-size-18 fw-bold mb-2">Options</p>
          <div class="d-flex align-items-center">
            <button
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              (click)="print()"
              [disabled]="isViewPermission"
            >
              <mat-icon fontSet="material-icons-outlined">print</mat-icon>
              <p>Print</p>
            </button>
            <button
              style="display: none"
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              (click)="archiveAndRestore(true)"
              [disabled]="isViewPermission"
            >
              <mat-icon>archive</mat-icon>
              <p>Archive</p>
            </button>
            <button
              style="display: none"
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              (click)="archiveAndRestore(false)"
              [disabled]="isViewPermission"
            >
              <mat-icon>unarchive</mat-icon>
              <p>Restore</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
