import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
} from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalComponent } from '../models';

import { CommonService } from '../services';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  service_count = 0;

  constructor(
    private spinner: NgxSpinnerService,
    private globalComponent: GlobalComponent,
    private commonService: CommonService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    this.service_count++;

    return next.handle(request).pipe(
      finalize(() => {
        this.service_count--;
        if (
          this.service_count === 0
          // && this.globalComponent.getLoggedInUserId() !==
          // (Guid.EMPTY as unknown as Guid)
        ) {
          this.spinner.hide();
        }
      })
    );
  }
}
