import { AccoutingPeriodModel } from '@app/core/models';
import { Guid } from 'guid-typescript';

export class CreateAccoutingPeriod {
  static readonly type = '[AccoutingPeriod] Create Accouting Period';
  constructor(public accoutingPeriodModel: AccoutingPeriodModel) {}
}

export class GetDataByAccoutingPeriodId {
  static readonly type = '[AccoutingPeriod] Get Data By Accouting Period Id';
  constructor(public accountingPeriodId: Guid) {}
}

export class OnUnlockPeriod {
  static readonly type = '[AccoutingPeriod] On Unlock Period';
  constructor(public id: Guid, public isUnlockPeriod: boolean) {}
}

export class OnAutoPeriodGeneration {
  static readonly type = '[AccoutingPeriod] On Auto Period Generation';
  constructor(public id: Guid, public isAutoPeriod: boolean) {}
}

export class OnCeased {
  static readonly type = '[AccoutingPeriod] On Ceased';
  constructor(public id: Guid, public isCeased: boolean) {}
}

export class OnMarkComparative {
  static readonly type = '[AccoutingPeriod] On Mark Comparative';
  constructor(public id: Guid, public isMarkComparative: boolean) {}
}

export class DeleteAccoutingPeriods {
  static readonly type = '[AccoutingPeriod] Delete Selected Accouting Periods';

  constructor(public ids: Array<Guid>) {}
}

export class GetStartDate {
  static readonly type = '[AccoutingPeriod] Get Start Data By Client Id';
  constructor(public clientId: Guid) {}
}
